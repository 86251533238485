import { PropsWithChildren } from "react";
import { css } from "@emotion/react";
import Clickable, { ClickableProps } from "./Clickable";
import T from "./text/Text";

type CardProps = PropsWithChildren<{
  title?: string;
}> &
  ClickableProps;

export default function Card(props: CardProps) {
  const { title } = props;
  const containerCss = css({
    background: "white",
    padding: 10,
    borderRadius: 6,
  });
  return (
    <Clickable {...props}>
      <div css={containerCss}>
        {title ? <T>{title}</T> : null}
        {props.children}
      </div>
    </Clickable>
  );
}
