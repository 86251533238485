import Exception from "./Exception";
import stringifyError from "./stringifyError";

export default function jsonifyError(error: unknown): Record<string, unknown> {
  const message = stringifyError(error);
  const tags = error instanceof Exception ? error.tags : {};
  const stack = error instanceof Error ? error.stack || null : null;
  const stackLines = stack ? stack.split("\n") : [];
  return { message, tags, stack: stackLines };
}
