import { useServerSdk } from "../RootNavigator/services/ServerSdk";
import PaymentAnalyse from "./PaymentAnalyse";
import PaymentLocked from "./PaymentLocked";
import PaymentEnded from "./PaymentEnded";
import PaymentForm from "./PaymentForm";
import useData from "@hpo/client/utilities/useData";
import { useRouteParam } from "@hpo/client/utilities/Navigation";
import Await from "@hpo/client/utilities/Await";
import { myPaymentRoute } from "@hpo/client/utilities/routes";

export type PaymentFileData = {
  project: string;
  receipt: string;
  file: Array<string>;
};

export default function MyPaymentScreen() {
  const server = useServerSdk();
  const paymentId = useRouteParam(myPaymentRoute, "paymentId");
  const [payment, data] = useData(
    async () => server.getMyPayment(paymentId),
    [paymentId],
  );

  return (
    <Await promise={payment}>
      {(p) => {
        if (p.status === "available")
          return <PaymentForm payment={p} onEnded={data.reload} />;
        else if (p.status === "under-analyse")
          return <PaymentAnalyse payment={p} />;
        else if (p.status === "locked") return <PaymentLocked payment={p} />;
        else if (p.status === "ended") return <PaymentEnded payment={p} />;
        else return null;
      }}
    </Await>
  );
}
