import { Infer, enums, number, object, string } from "superstruct";
import { ActionTypeValues } from "@hpo/client/utilities/enums/ActionType";

// Action

export const ActionSchema = object({
  id: string(),
  type: enums(ActionTypeValues),
  label: string(),
  goal: string(),
  cost: number(),
  means: string(),
  planning: string(),
});

export type Action = Infer<typeof ActionSchema>;

export const ActionCreationSchema = object({
  type: enums(ActionTypeValues),
  label: string(),
  goal: string(),
  cost: number(),
  means: string(),
  planning: string(),
});

export type ActionCreation = Infer<typeof ActionCreationSchema>;
