import { HelpIcon } from "../../RootNavigator/assistance/Help/HelpIcon";
import Table from "./Table";
import T from "@hpo/client/components/text/Text";
import Amounts from "@hpo/client/utilities/Amounts";
import Units from "@hpo/client/utilities/Units";
import { InstructorAchievement } from "@hpo/client/models/Achievement";

type AchievementTableProps = {
  achievement: InstructorAchievement;
};

export default function AchievementTable(props: AchievementTableProps) {
  const { achievement } = props;
  return (
    <Table frame>
      <thead>
        <tr>
          <th></th>
          <th>Montant</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <T>
              {Amounts.granted.label}{" "}
              <HelpIcon title={Amounts.granted.label}>
                {Amounts.granted.info}
              </HelpIcon>
            </T>
          </td>
          <td>{Units.euro.display(achievement.grantedAmount)}</td>
        </tr>
        <tr>
          <td>
            <T>
              {Amounts.stock.label}{" "}
              <HelpIcon title={Amounts.stock.label}>
                {Amounts.stock.info}
              </HelpIcon>
            </T>
          </td>
          <td>{Units.euro.display(achievement.stockAmount)}</td>
        </tr>
        <tr className="total">
          <td>
            <T>
              {Amounts.available.label}{" "}
              <HelpIcon title={Amounts.available.label}>
                {Amounts.available.info}
              </HelpIcon>
            </T>
          </td>
          <td>{Units.euro.display(achievement.availableAmount)}</td>
        </tr>
        <tr className="divider">
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td>
            <T>
              {Amounts.requested.label}{" "}
              <HelpIcon title={Amounts.requested.label}>
                {Amounts.requested.info}
              </HelpIcon>
            </T>
          </td>
          <td>{Units.euro.display(achievement.requestedAmount)}</td>
        </tr>
        <tr>
          <td>
            <T>
              {Amounts.instructed.label}{" "}
              <HelpIcon title={Amounts.instructed.label}>
                {Amounts.instructed.info}
              </HelpIcon>
            </T>
          </td>
          <td>{Units.euro.display(achievement.instructedAmount)}</td>
        </tr>
        <tr>
          <td>
            <T>
              {Amounts.validated.label}{" "}
              <HelpIcon title={Amounts.validated.label}>
                {Amounts.validated.info}
              </HelpIcon>
            </T>
          </td>
          <td>{Units.euro.display(achievement.validatedAmount)}</td>
        </tr>
        <tr className="total">
          <td>
            <T>
              {Amounts.certified.label}{" "}
              <HelpIcon title={Amounts.certified.label}>
                {Amounts.certified.info}
              </HelpIcon>
            </T>
          </td>
          <td>{Units.euro.display(achievement.certifiedAmount)}</td>
        </tr>
        {achievement.stockableAmount ? (
          <tr>
            <td>
              <T>
                {Amounts.stockable.label}{" "}
                <HelpIcon title={Amounts.stockable.label}>
                  {Amounts.stockable.info}
                </HelpIcon>
              </T>
            </td>
            <td>{Units.euro.display(achievement.stockableAmount)}</td>
          </tr>
        ) : null}
      </tbody>
    </Table>
  );
}
