import { useCallback, useState } from "react";
import { css } from "@emotion/react";
import FieldProps from "./FieldProps";
import FieldWrapper from "./components/FieldWrapper";
import { useFieldId, useFieldValidity } from "./utilities";
import FieldInput, { FieldTextArea } from "./components/FieldInput";
import Button from "@hpo/client/components/Button";

type TextFieldProps = FieldProps<string | null> & {
  password?: boolean;
  textArea?: boolean;
  maxLength?: number;
};

export default function TextField(props: TextFieldProps) {
  const {
    value,
    onChange,
    password,
    textArea,
    placeholder,
    disabled = false,
    readonly = false,
    required = false,
    maxLength,
    errorMessage,
  } = props;

  const id = useFieldId(props.id);
  const validity = useFieldValidity(
    value,
    required,
    () => value === "" || value === null || value === undefined,
    (v) => {
      if (v !== null && maxLength !== undefined && v.length > maxLength)
        return `${maxLength} caractères max`;
      if (errorMessage) return errorMessage(v);
      return null;
    },
  );

  const onInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (onChange) onChange(e.target.value || null);
    },
    [onChange],
  );

  const [obfuscated, setObfuscated] = useState<boolean>(password || false);

  const wrapperCss = css({
    display: "flex",
  });

  const inputCss = css({
    flex: 1,
  });

  return (
    <FieldWrapper {...props} id={id} validity={validity}>
      <div css={wrapperCss}>
        <div css={inputCss}>
          {textArea ? (
            <FieldTextArea
              value={value || ""}
              onChange={onInputChange}
              placeholder={placeholder}
              disabled={disabled}
              readOnly={readonly}
              maxLength={props.maxLength}
            />
          ) : (
            <FieldInput
              type={obfuscated ? "password" : "text"}
              value={value || ""}
              onChange={onInputChange}
              placeholder={placeholder}
              disabled={disabled}
              readOnly={readonly}
              maxLength={props.maxLength}
            />
          )}
        </div>
        {password === true ? (
          <Button
            icon={obfuscated ? "eye" : "eye-off"}
            onClick={() => setObfuscated((c) => !c)}
            style="discreet"
          />
        ) : null}
      </div>
    </FieldWrapper>
  );
}
