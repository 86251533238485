import { Fragment, useCallback, useState } from "react";
import { cloneDeep } from "lodash";
import { IndicatorPayload } from "@hpo/client/models/Indicator";
import ErrorToast from "@hpo/client/utilities/ErrorToast";
import Spacer from "@hpo/client/utilities/Spacer";
import IndicatorType, {
  IndicatorTypeLabels,
  IndicatorTypeValues,
} from "@hpo/client/utilities/enums/IndicatorType";
import TextField from "@hpo/client/utilities/fields/TextField";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import NumberField from "@hpo/client/utilities/fields/NumberField";
import Units, { UnitName, UnitNamesValues } from "@hpo/client/utilities/Units";
import RadioField from "@hpo/client/utilities/fields/RadioField";
import Table from "@hpo/client/navigators/ConventionNavigator/PaymentScreen/Table";
import T from "@hpo/client/components/text/Text";
import FieldsForm from "@hpo/client/utilities/fields/FieldsForm";
import MessageException from "@hpo/client/utilities/errors/MessageException";
import Buttons from "@hpo/client/components/Buttons";
import Button from "@hpo/client/components/Button";
import Dialog from "@hpo/client/components/Dialog";
import { MarkerPayload } from "@hpo/client/models/Marker";
import MarkerType from "@hpo/client/utilities/enums/MarkerType";

type IndicatorEditorProps = {
  indicator: IndicatorPayload | null;
  periods: Array<string>;
  hideGoals?: boolean;
  onDone: (indicator: IndicatorPayload) => unknown;
  onDelete?: () => unknown;
};

export default function IndicatorEditor(props: IndicatorEditorProps) {
  const { indicator, periods, hideGoals, onDone, onDelete } = props;

  const [label, setLabel] = useState<string | null>(
    indicator ? indicator.label : null,
  );

  const [type, setType] = useState<IndicatorType | null>(
    indicator ? indicator.type : null,
  );

  const [unit, setUnit] = useState<UnitName | null>(
    indicator ? indicator.unit : null,
  );

  const [goals, setGoals] = useState<Array<MarkerPayload>>(() =>
    indicator ? cloneDeep(indicator.goals) : [],
  );

  const getGoal = useCallback(
    (period: string) => {
      return goals.find((g) => g.period === period);
    },
    [goals],
  );

  const setGoal = useCallback((period: string, value: number | null) => {
    setGoals((goals) => {
      const output = goals.filter((g) => g.period !== period);
      if (value) output.push({ period, value, type: MarkerType.Goal });
      return output;
    });
  }, []);

  const [onSubmit, submission] = useSubmitCallback(() => {
    if (!label || !type || !unit)
      throw new MessageException(
        "Veuillez remplir tous les champs obligatoires",
        null,
      );
    onDone({ label, type, unit, goals });
  }, [label, type, unit, goals, onDone]);

  const onPressDelete = useCallback(async () => {
    if (!onDelete) return;
    const confirmation = await Dialog.confirm({
      message: "Voulez-vous vraiment supprimer cet indicateur ?",
    });
    if (!confirmation) return;
    await onDelete();
  }, [onDelete]);

  return (
    <Fragment>
      <FieldsForm onSubmit={onSubmit}>
        <ErrorToast error={submission.error} />
        <TextField
          required
          id="label"
          label="Indicateur"
          value={label}
          onChange={setLabel}
        />
        <Spacer />
        <RadioField
          id="type"
          label="Type d'indicateur"
          value={type}
          renderOption={(type) => ({
            label: IndicatorTypeLabels[type],
          })}
          keyExtractor={(type) => type}
          options={[...IndicatorTypeValues]}
          onChange={setType}
          required
        />
        <Spacer />
        <RadioField
          id="type"
          label="Indicateur exprimé en..."
          value={unit}
          renderOption={(unit) => ({
            label: Units[unit].label,
          })}
          keyExtractor={(unit) => unit}
          options={[...UnitNamesValues]}
          onChange={setUnit}
          required
        />
        <Spacer />
        {!hideGoals ? (
          <Fragment>
            <T style="section">Objectifs</T>
            <Spacer />
            <Table frame>
              <thead>
                <tr>
                  <th></th>
                  <th>Objectif</th>
                </tr>
              </thead>
              <tbody>
                {periods.map((period) => {
                  const goal = getGoal(period);
                  return (
                    <tr key={period}>
                      <th>{period}</th>
                      <td>
                        <NumberField
                          unit={unit || undefined}
                          value={goal ? goal.value : null}
                          onChange={(v) => setGoal(period, v)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Fragment>
        ) : null}
        <Spacer />
        <Buttons expand>
          {onDelete ? (
            <Button onClick={onPressDelete} label="Supprimer" style="danger" />
          ) : null}
          <Button submit label="Valider" />
        </Buttons>
        <Spacer />
      </FieldsForm>
    </Fragment>
  );
}
