import { Fragment, useCallback } from "react";
import { useServerSdk } from "../../../RootNavigator/services/ServerSdk";
import ProjectInquiryStatus from "../inquiry/ProjectInquiryStatus";
import DataTable, { DataTableColumn } from "@hpo/client/components/DataTable";
import { Analysis } from "@hpo/client/models/Analysis";
import { ComplianceInstructor } from "@hpo/client/models/Compliance";
import { ProjectInstructor } from "@hpo/client/models/Project";
import { canProjectHaveInquiry } from "@hpo/client/utilities/helpers/ProjectHelper";
import { useAssistance } from "@hpo/client/navigators/RootNavigator/services/Assistance";

type ProjectTabFilesProps = {
  project: ProjectInstructor;
  onReload: () => unknown;
};

export default function ProjectTabCompliances(props: ProjectTabFilesProps) {
  const { project, onReload } = props;

  const serverSdk = useServerSdk();
  const assistance = useAssistance();

  assistance.useVisibilityDecision(true);

  const saveAnalysis = useCallback(
    async (resourceId: string, fields: Analysis) => {
      await serverSdk.saveAnalysis(resourceId, fields);
      await onReload();
    },
    [onReload],
  );

  const columns: DataTableColumn<ComplianceInstructor>[] = [
    { field: "label", header: "Libellé" },
    {
      body: (d) => (
        <ProjectInquiryStatus
          analysis={d.analysis}
          onChange={(f) => saveAnalysis(d.id, f)}
          readonly={!canProjectHaveInquiry(project)}
        />
      ),
    },
  ];

  return (
    <Fragment>
      <DataTable value={project.compliances} columns={columns} />
    </Fragment>
  );
}
