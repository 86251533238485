import { Struct, mask, Infer, number, object, string } from "superstruct";
import Exception from "@hpo/server/utilities/errors/Exception";

export const JwtMetadataSchema = object({ exp: number(), jti: string() });
export type JwtMetadata = Infer<typeof JwtMetadataSchema>;

export default class JwtManager<P> {
  constructor(private struct: Struct<P>) {}

  fromContent(payload: P, metadata: JwtMetadata) {
    return new Jwt<P>(payload, metadata);
  }

  decode(token: string) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );
    const data = JSON.parse(jsonPayload);
    const payload = mask(data, this.struct);
    const metadata = mask(data, JwtMetadataSchema);
    return new Jwt<P>(payload, metadata);
  }
}

export class Jwt<P> {
  constructor(
    private payload: P,
    private metadata: JwtMetadata,
  ) {}

  getId() {
    return new Date(this.metadata.jti);
  }

  getExpiration() {
    return new Date(this.metadata.exp);
  }

  getPayload() {
    return this.payload;
  }
}

export class MissingPrivateKey extends Exception {}
