import dayjs from "dayjs";
import Role, { RoleLabels } from "../enums/Role";
import { User, UserAccess } from "@hpo/client/models/User";

export function getUserName(user: User): string {
  return user.firstName + " " + user.lastName;
}

export function getRoleLabel(userRole: Role): string {
  return RoleLabels[userRole];
}

export function isUserAuthorized(user: UserAccess) {
  const expiration = user.passwordExpiartion;
  if (!expiration) return true;
  return dayjs(expiration).isAfter(new Date());
}

const UserHelper = {
  getUserName,
  getRoleLabel,
};

export default UserHelper;
