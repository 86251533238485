import { Fragment, useCallback } from "react";
import { useServerSdk } from "../../../RootNavigator/services/ServerSdk";
import ProjectInquiryStatus from "../inquiry/ProjectInquiryStatus";
import Panel from "@hpo/client/components/Panel";
import TextBody from "@hpo/client/components/text/TextBody";
import { Analysis } from "@hpo/client/models/Analysis";
import { ProjectInstructor } from "@hpo/client/models/Project";
import Spacer from "@hpo/client/utilities/Spacer";
import { canProjectHaveInquiry } from "@hpo/client/utilities/helpers/ProjectHelper";
import { useAssistance } from "@hpo/client/navigators/RootNavigator/services/Assistance";

type ProjectTabDescriptionProps = {
  project: ProjectInstructor;
  onReload: () => unknown;
};

export default function ProjectTabDescription(
  props: ProjectTabDescriptionProps,
) {
  const { project, onReload } = props;

  const serverSdk = useServerSdk();
  const assistance = useAssistance();
  assistance.useVisibilityDecision(true);

  const saveAnalysis = useCallback(
    async (resourceId: string, fields: Analysis) => {
      await serverSdk.saveAnalysis(resourceId, fields);
      await onReload();
    },
    [onReload],
  );

  return (
    <Fragment>
      {project.descriptions
        // .sort((descA, descB) => descA.rank - descB.rank)
        .map((description) => {
          const commentButton = (
            <ProjectInquiryStatus
              analysis={description.analysis}
              onChange={(a) => saveAnalysis(description.id, a)}
              readonly={!canProjectHaveInquiry(project)}
            />
          );

          return (
            <Fragment key={description.id}>
              <Panel header={description.label} headerRight={commentButton}>
                <TextBody>{description.value}</TextBody>
              </Panel>
              <Spacer size={2} />
            </Fragment>
          );
        })}
    </Fragment>
  );
}
