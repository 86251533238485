import { Fragment, useEffect } from "react";
import { useAssistance } from "../services/Assistance";
import ChatPanel from "./Chat/ChatPanel";

export default function AssistancePanel() {
  const isAssistanceVisble = useAssistance().useIsVisible();

  useEffect(() => {
    document.body.style.paddingRight = isAssistanceVisble ? "33vw" : "0vw";
  }, [isAssistanceVisble]);

  return (
    <Fragment>
      <ChatPanel />
    </Fragment>
  );
}
