import {
  Infer,
  array,
  nullable,
  number,
  object,
  partial,
  record,
  string,
} from "superstruct";

import { isoDateSchema } from "../utilities/models/Date";
import { OnPeriodSchema } from "../utilities/enums/PaymentFragment";
import { OrganizationSchema, OrganizationSummarySchema } from "./Organization";
import { FundingDetailsSchema } from "./Funding";
import { InstructorPaymentAmountsSchema } from "./InstructorPayment";

// ConventionSummary

export const ConventionSummarySchema = object({
  id: string(),
  reference: string(),
  organizations: array(OrganizationSummarySchema),
  date: string(),
});

export type ConventionSummary = Infer<typeof ConventionSummarySchema>;

// ConventionFoundation

export const ConventionFoundationSchema = object({
  granted: OnPeriodSchema(nullable(number())),
  stock: OnPeriodSchema(nullable(number())),
  available: OnPeriodSchema(nullable(number())),
  requested: OnPeriodSchema(nullable(number())),
  instructed: OnPeriodSchema(nullable(number())),
  validated: OnPeriodSchema(nullable(number())),
  certified: OnPeriodSchema(nullable(number())),
  stockable: OnPeriodSchema(nullable(number())),
  maximalAdvance: OnPeriodSchema(nullable(number())),
  transfer: OnPeriodSchema(nullable(number())),
  remainingAdvance: OnPeriodSchema(nullable(number())),
});

export type ConventionFoundation = Infer<typeof ConventionFoundationSchema>;

// ConventionDetails

export const ConventionDetailsSchema = object({
  id: string(),
  reference: string(),
  organizations: array(OrganizationSchema),
  date: string(),
  files: array(string()),
  amendments: nullable(array(string())),
  iban: nullable(array(string())),
  fundings: array(FundingDetailsSchema),
  payments: array(InstructorPaymentAmountsSchema),
  foundations: record(string(), ConventionFoundationSchema),
});

export type ConventionDetails = Infer<typeof ConventionDetailsSchema>;

// ConventionCreation

export const ConventionCreationSchema = object({
  date: isoDateSchema,
  reference: string(),
  files: array(string()),
  organizations: array(string()),
  projectsAmounts: record(string(), number()),
  periods: array(string()),
});

export type ConventionCreation = Infer<typeof ConventionCreationSchema>;

// ConventionEdition

export const ConventionEditionSchema = partial(
  object({
    amendments: nullable(array(string())),
    iban: nullable(array(string())),
  }),
);

export type ConventionEdition = Infer<typeof ConventionEditionSchema>;
