import jsCookies from "js-cookie";

import { UserAccess } from "@hpo/client/models/User";
import JwtManager, { Jwt } from "@hpo/client/utilities/JwtManager";
import R from "@hpo/client/utilities/R";
import Exception from "@hpo/client/utilities/errors/Exception";
import createInjectable from "@hpo/client/utilities/createInjectable";

export default class WhoAmI {
  private r = new R();
  private state: Jwt<UserAccess> | null = null;

  constructor(private jwt: JwtManager<UserAccess>) {
    this.readCookies();
  }

  hasCookie() {
    const content = jsCookies.get("hpo_access_token");
    return !!content;
  }

  readCookies() {
    const content = jsCookies.get("hpo_access_token");
    if (!content) {
      this.setState(null);
      return;
    }
    try {
      const jwt = this.jwt.decode(content);
      this.setState(jwt);
    } catch (err) {
      this.setState(null);
    }
  }

  use() {
    return this.r.useSelector(() => this.state);
  }

  searchUser() {
    if (!this.state) return null;
    const user = this.state.getPayload();
    return user;
  }

  findUser() {
    const user = this.searchUser();
    if (!user) throw new NoUser({ cause: null });
    return user;
  }

  private isLoggedIn() {
    return !!this.searchUser();
  }

  useIsLoggedIn() {
    return this.r.useSelector(() => this.isLoggedIn());
  }

  useLoggedInUser() {
    return this.r.useSelector(() => this.findUser());
  }

  useUser() {
    return this.r.useSelector(() => this.findUser());
  }

  setState(state: Jwt<UserAccess> | null) {
    if (this.state === null && state === null) return;
    if (this.state && state && this.state.getId() === state.getId()) return;
    this.state = state;
    this.r.notify();
  }
}

class NoUser extends Exception {}

export const [WhoAmIProvider, useWhoAmI] = createInjectable<WhoAmI>("WhoAmI");
export function useMe() {
  return useWhoAmI().useUser();
}
