import { useMemo, useState } from "react";
import Button from "@hpo/client/components/Button";
import Divider from "@hpo/client/components/Divider";
import Message from "@hpo/client/components/Message";
import SideBar from "@hpo/client/components/SideBar";
import TextTitleMinor from "@hpo/client/components/text/TextTitleMinor";
import { Analysis } from "@hpo/client/models/Analysis";
import ErrorView from "@hpo/client/utilities/ErrorView";
import Spacer from "@hpo/client/utilities/Spacer";
import DropDownField from "@hpo/client/utilities/fields/DropDownField";
import FieldsForm from "@hpo/client/utilities/fields/FieldsForm";
import TextField from "@hpo/client/utilities/fields/TextField";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import Theme from "@hpo/client/utilities/Theme";
import { useMe } from "@hpo/client/navigators/RootNavigator/services/WhoAmI";

export type ProjectInquirySideMenuProps = {
  visible: boolean;
  onHide: () => void;
  analysis: Analysis;
  onChange: (fields: Analysis) => unknown;
  readonly: boolean;
};

const DecisionFieldOptions = [true, false, "skippable"] as const;
type DecisionFieldValue = (typeof DecisionFieldOptions)[number];

export default function ProjectInquirySideMenu(
  props: ProjectInquirySideMenuProps,
) {
  const { visible, onHide, analysis, onChange, readonly } = props;
  const me = useMe();

  const showInternalNote = useMemo(
    () => me.roles.includes("instructor"),
    [me.roles],
  );

  const [internalNote, setInternalNote] = useState(() => analysis.internalNote);
  const [comment, setComment] = useState(() => analysis.comment);
  const [decision, setDecision] = useState<DecisionFieldValue | null>(() =>
    analysis.skippable ? "skippable" : analysis.decision,
  );

  const [onSubmit, submission] = useSubmitCallback(async () => {
    await onChange({
      comment,
      internalNote,
      skippable: decision === "skippable",
      decision: decision === "skippable" ? null : decision,
    });
  }, [internalNote, comment, decision, decision, onChange]);

  return (
    <SideBar
      visible={visible}
      onHide={onHide}
      header={<TextTitleMinor>Informations d'instruction</TextTitleMinor>}
    >
      {readonly && (
        <Message
          severity="info"
          content="L'instruction est validée, les modifications ne sont plus autorisées"
        />
      )}
      <Spacer size={2} />
      <FieldsForm onSubmit={onSubmit}>
        <DropDownField
          value={decision}
          onChange={setDecision}
          options={[...DecisionFieldOptions]}
          label="Avis d'instruction"
          renderOption={(r) => {
            if (r === true)
              return {
                icon: { name: "thumbs-up", color: Theme.color },
                label: "Conforme",
              };
            else if (r === false)
              return {
                icon: { name: "thumbs-down", color: Theme.error },
                label: "Non conforme",
              };
            else return { icon: "fast-forward", label: "Non attendu" };
          }}
          renderNullOption={() => ({ icon: "hammer", label: "A déterminer" })}
          keyExtractor={(r) => String(r)}
          disabled={readonly}
        />
        <Spacer />
        <Divider />
        <Spacer />
        <TextField
          label="Commentaire d'instruction"
          value={comment}
          onChange={setComment}
          textArea={true}
          disabled={readonly}
        />
        <Spacer />
        <Divider />
        <Spacer />
        {showInternalNote ? (
          <TextField
            label="Commentaire confidentiel de l'instructeur"
            value={internalNote || null}
            onChange={setInternalNote}
            textArea={true}
            disabled={readonly}
          />
        ) : null}
        <Spacer />
        <Button submit label="Valider" disabled={readonly} />
        {submission.error ? <ErrorView error={submission.error} /> : null}
      </FieldsForm>
      <Spacer />
    </SideBar>
  );
}
