import { useCallback, useEffect, useMemo, useState } from "react";
import { is, number, record, string } from "superstruct";
import { flatten, pick } from "lodash";
import { useLocation } from "wouter";
import { useServerSdk } from "../RootNavigator/services/ServerSdk";
import ContentLayout from "@hpo/client/components/layout/ContentLayout";
import Spacer from "@hpo/client/utilities/Spacer";
import MultiSelectField from "@hpo/client/utilities/fields/MultiSelectField";
import { getProjectLabel } from "@hpo/client/utilities/helpers/ProjectHelper";
import T from "@hpo/client/components/text/Text";
import { OrganizationSummary } from "@hpo/client/models/Organization";
import { ProjectSummary } from "@hpo/client/models/Project";
import Units from "@hpo/client/utilities/Units";
import TextField from "@hpo/client/utilities/fields/TextField";
import DateField from "@hpo/client/utilities/fields/DateField";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import ErrorToast from "@hpo/client/utilities/ErrorToast";
import MessageException from "@hpo/client/utilities/errors/MessageException";
import FilesField from "@hpo/client/utilities/fields/FilesField";
import NumberField from "@hpo/client/utilities/fields/NumberField";
import Intersperse from "@hpo/client/components/Intersperse";
import FieldsForm from "@hpo/client/utilities/fields/FieldsForm";
import Button from "@hpo/client/components/Button";
import { conventionRoute } from "@hpo/client/utilities/routes";
import ProjectStatus from "@hpo/client/utilities/enums/ProjectStatus";

export default function NewConventionScreen() {
  const server = useServerSdk();

  const [reference, setReference] = useState<string | null>(null);
  const [date, setDate] = useState<string | null>(null);
  const [files, setFiles] = useState<Array<string> | null>(null);
  const [orgs, setOrgs] = useState<Array<OrganizationSummary>>([]);
  const [projects, setProjects] = useState<Array<ProjectSummary>>([]);
  const allPeriods = useMemo(
    () => ["2023", "2024", "2025", "2026", "2027"],
    [],
  );
  const [periods, setPeriods] = useState<Array<string>>(allPeriods);

  const allOrgs = useMemo(async () => server.getOrganizations(), []);

  const allProjects = useMemo(async () => {
    const projects = await Promise.all(
      orgs.map((o) => server.getOrganizationProjects(o.id)),
    );
    return flatten(projects).filter(
      (project) => project.status === ProjectStatus.Done,
    );
  }, [orgs]);

  useEffect(() => {
    projects.forEach((r) => onChangeAmount(r.id, r.amount || 0));
  }, [projects]);

  const [projectsAmounts, setProjectsAmounts] = useState<
    Record<string, number | null>
  >({});

  const onChangeAmount = useCallback((id: string, amount: number | null) => {
    setProjectsAmounts((c) => ({ ...c, [id]: amount }));
  }, []);

  const validateInformation = useCallback(() => {
    if (!reference)
      throw new MessageException("Renseignez le numéro de la convention", null);
    if (!date)
      throw new MessageException("Renseignez la date de la convention", null);
    if (!files)
      throw new MessageException("Chargez la convention signée", null);
    if (!orgs.length)
      throw new MessageException("Renseignez au moins un bénéficiaire", null);
    if (!projects.length)
      throw new MessageException("Choisissez au moins 1 action", null);
    return { reference, date, files, orgs, projects };
  }, [orgs, reference, date, files, projects]);

  const validateAmounts = useCallback(() => {
    const struct = record(string(), number());
    const amounts = pick(
      projectsAmounts,
      projects.map((p) => p.id),
    );
    if (!is(amounts, struct))
      throw new MessageException(
        "Renseignez un montant pour chaque action",
        null,
      );
    return amounts;
  }, [projectsAmounts, projects]);

  const [, navigate] = useLocation();

  const [onSubmit, submission] = useSubmitCallback(async () => {
    const infos = validateInformation();
    const amounts = validateAmounts();
    if (!periods.length)
      throw new MessageException("Sélectionnez au moins une période", null);
    const id = await server.createConvention({
      organizations: infos.orgs.map((o) => o.id),
      reference: infos.reference,
      date: infos.date,
      files: infos.files,
      projectsAmounts: amounts,
      periods: periods,
    });
    navigate(conventionRoute.getUrl({ conventionId: id }), { replace: true });
  }, [validateInformation, validateAmounts, periods]);
  return (
    <ContentLayout title="Enregistrer une convention">
      <ErrorToast error={submission.error} />
      <FieldsForm onSubmit={onSubmit}>
        <TextField
          value={reference}
          onChange={setReference}
          label="Numéro de la convention"
          required
        />
        <Spacer />
        <DateField
          value={date}
          onChange={setDate}
          label="Date de la convention"
          required
        />
        <Spacer />
        <FilesField
          value={files}
          onChange={setFiles}
          label="Convention signée"
          required
        />
        <Spacer />
        <MultiSelectField
          options={allOrgs}
          keyExtractor={(o) => o?.id}
          value={orgs}
          onChange={setOrgs}
          label="Bénéficiaires"
          renderOption={(i) => ({ label: i.businessName })}
          required
        />
        <Spacer />
        <MultiSelectField
          options={allProjects}
          label="Projets inclus dans la convention"
          value={projects}
          onChange={setProjects}
          keyExtractor={(i) => i.id}
          renderOption={(i) => ({
            label: getProjectLabel(i),
            right: <T>{i.amount ? Units.euro.display(i.amount) : null}</T>,
          })}
          required
        />
        <Spacer />
        <Intersperse between={() => <Spacer />}>
          {projects.map((p) => (
            <NumberField
              key={p.id}
              value={projectsAmounts[p.id]}
              onChange={(a) => onChangeAmount(p.id, a)}
              unit="euro"
              label={`Montant accordé pour le projet "${getProjectLabel(p)}"`}
              required
            />
          ))}
        </Intersperse>
        <Spacer />
        <MultiSelectField
          label="Périodes"
          value={periods}
          onChange={setPeriods}
          options={allPeriods}
          renderOption={(p) => ({ label: p })}
          keyExtractor={(p) => p}
          required
        />
        <Spacer />
        <Button label="Créer la convention" submit />
      </FieldsForm>
    </ContentLayout>
  );
}
