import Config from "./navigators/RootNavigator/services/Config";
import createInjectable from "./utilities/createInjectable";
import Exception from "./utilities/errors/Exception";
import Logger from "./utilities/Logger";
import ConsoleLogger from "./utilities/Logger/ConsoleLogger";
import LogtailLogger from "./utilities/Logger/LogtailLogger";

let logger: Logger;

if (Config.logtail_source_token) {
  logger = new LogtailLogger(Config.logtail_source_token, { from: "client" });
} else {
  logger = new ConsoleLogger();
}

window.addEventListener("error", function (e) {
  logger.error(new UncaughtError({ cause: e.error }));
});

class UncaughtError extends Exception {}

window.addEventListener("unhandledrejection", function (e) {
  logger.error(new UnhandledRejection({ cause: e.reason }));
});

class UnhandledRejection extends Exception {}

export const [LoggerProvider, useLogger] = createInjectable<Logger>("Logger");

export default logger;
