import { Infer, enums } from "superstruct";

export const RoleValues = [
  "benficiary",
  "instructor",
  "controller",
  "financier",
  "payer",
  "iam-manager",
] as const;

export const RoleLabels: Record<Role, string> = {
  benficiary: "Bénéficiaire",
  instructor: "Instructeur",
  controller: "Validation Département niveau 1",
  financier: "Validation Département niveau 2",
  payer: "Chargé de mise en paiement",
  "iam-manager": "Gestionnaire des accès",
};

export const RoleSchema = enums(RoleValues);

type Role = Infer<typeof RoleSchema>;

export default Role;
