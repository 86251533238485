import { Infer, object, string } from "superstruct";
import { isoDateSchema } from "../utilities/models/Date";

// SignableDocument

export const SignableDocumentSchema = object({
  url: string(),
  contentHash: string(),
});
export type SignableDocument = Infer<typeof SignableDocumentSchema>;

// Signature

export const SignatureSchema = object({
  id: string(),
  author: string(),
  date: isoDateSchema,
  contentHash: string(),
  inputFile: SignableDocumentSchema,
});
export type Signature = Infer<typeof SignatureSchema>;
