import { Route, Switch } from "wouter";
import { useAssistance } from "../RootNavigator/services/Assistance";
import MyPaymentsScreen from "./MyPaymentsScreen";
import MyPaymentScreen from "./MyPaymentScreen";
import { myPaymentRoute } from "@hpo/client/utilities/routes";

export default function MyPaymentsNavigator() {
  const assitance = useAssistance();
  assitance.useChatWithInstructor();
  return (
    <Switch>
      <Route path="/" component={MyPaymentsScreen} />
      <Route path={myPaymentRoute.getPath()} component={MyPaymentScreen} />
    </Switch>
  );
}
