import { Route, Switch } from "wouter";
import PaymentsScreen from "./PaymentsScreen";

export default function PaymentsNavigator() {
  return (
    <Switch>
      <Route path="/" component={PaymentsScreen} />
    </Switch>
  );
}
