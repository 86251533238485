import { difference } from "lodash";
import AttachmentTemplate from "./enums/AttachmentTemplate";
import PaymentFragment from "./enums/PaymentFragment";
import Exception from "./errors/Exception";
import ProgramKey from "./enums/ProgramKey";

export type ReceiptDescriptor = {
  key: string; // Clé unique du receipt (comme un id, mais vu que c'est codé en dur, je préfère nommer ca "key")
  template: AttachmentTemplate | null; // Pas utilisé actuellement (pour détection auto de doc, à voir + tard)
  label: string; // Champ descriptif à afficher à l'écran
  requestType: ProgramKey; // Project.type
  paymentFragment: PaymentFragment; // Payment.fragment
  fillouts: Array<string> | null;
};

export function getReceiptsForRequestTypeAndPaymentFragment(
  type: ProgramKey,
  fragment: PaymentFragment,
) {
  return Receipts.filter((receipt) => {
    return receipt.requestType === type && receipt.paymentFragment === fragment;
  });
}

export function assertProjectHasAllReceipts(
  fragment: PaymentFragment,
  projectType: ProgramKey,
  receiptsKeys: Array<string>,
) {
  let left = [...receiptsKeys];
  const wantedReceipts = getReceiptsForRequestTypeAndPaymentFragment(
    projectType,
    fragment,
  );
  for (const receipt of wantedReceipts) {
    const match = left.filter((key) => key === receipt.key);
    if (match.length === 0) throw new MissingReceipts({ cause: null });
    if (match.length > 1) throw new TooManyReceipts({ cause: null });
    left = difference(left, match);
  }
  if (left.length > 0) {
    throw new TooManyReceipts({ cause: null });
  }
}

export class MissingReceipts extends Exception {}
export class TooManyReceipts extends Exception {}

const Receipts: Array<ReceiptDescriptor> = [
  {
    key: "953aeb72",
    template: null,
    fillouts: null,
    label: "Avis de décision du CROPSAV",
    requestType: ProgramKey.LutteCollective,
    paymentFragment: PaymentFragment.Deposit1,
  },
  {
    key: "ad4a78dd",
    template: null,
    fillouts: null,
    label:
      "Conventions de partenariats signées avec les partenaires identifiés dans l’appel à projets",
    requestType: ProgramKey.LutteCollective,
    paymentFragment: PaymentFragment.Deposit1,
  },
  {
    key: "5aec4323",
    template: null,
    fillouts: null,
    label:
      "Conventions de partenariats signées avec les partenaires identifiés dans l’appel à projets",
    requestType: ProgramKey.PromotionProduits,
    paymentFragment: PaymentFragment.Deposit1,
  },
  {
    key: "bf435b8e",
    template: null,
    fillouts: null,
    label: "Contrat de travail signé",
    requestType: ProgramKey.EchangeConnaissance,
    paymentFragment: PaymentFragment.Deposit1,
  },
  {
    key: "a54b662a",
    template: null,
    fillouts: null,
    label:
      "CV et fiche de poste nominative avec le détail des missions directement liées aux actions techniques",
    requestType: ProgramKey.EchangeConnaissance,
    paymentFragment: PaymentFragment.Deposit1,
  },
  {
    key: "04e849b9",
    template: null,
    fillouts: null,
    label:
      "Conventions de partenariats signées avec les partenaires identifiés dans l’appel à projet.",
    requestType: ProgramKey.EchangeConnaissance,
    paymentFragment: PaymentFragment.Deposit1,
  },
  {
    key: "ae99fc33",
    template: null,
    fillouts: null,
    label: "Contrat (s) de travail signé (s)",
    requestType: ProgramKey.Animation,
    paymentFragment: PaymentFragment.Deposit1,
  },
  {
    key: "634883e7",
    template: null,
    fillouts: null,
    label:
      "CV et fiche (s) de poste (s) nominative (s) avec le détail des missions directement liées aux actions techniques ",
    requestType: ProgramKey.Animation,
    paymentFragment: PaymentFragment.Deposit1,
  },
  {
    key: "ea614fc0",
    template: null,
    fillouts: null,
    label:
      "La convention signée réunissant au moins 3 autres acteurs, représentant différents maillons de la filière et opérant ou non dans le secteur agricole, mais sous réserve que la coopération soit avantageuse principalement pour le secteur agricole, sera à fournir avant le premier paiement (Pour axe 1)",
    requestType: ProgramKey.Animation,
    paymentFragment: PaymentFragment.Deposit1,
  },
  {
    key: "b6e2a7df",
    template: null,
    fillouts: null,
    label:
      "La convention signée réunissant au moins une collectivité intercommunale et au moins un autre acteur opérant ou non dans le secteur agricole, mais sous réserve que la coopération soit avantageuse principalement pour le secteur agricole, sera à fournir avant le premier paiement (pour l’axe 2)",
    requestType: ProgramKey.Animation,
    paymentFragment: PaymentFragment.Deposit1,
  },
  {
    key: "b77a7641",
    template: null,
    fillouts: null,
    label: "Contrat (s) de travail signé (s) ",
    requestType: ProgramKey.AnimationChambre,
    paymentFragment: PaymentFragment.Deposit1,
  },
  {
    key: "ed6e37f1",
    template: null,
    fillouts: null,
    label:
      "CV et fiche(s) de poste(s) nominative(s) avec le détail des missions directement liées aux actions techniques ",
    requestType: ProgramKey.AnimationChambre,
    paymentFragment: PaymentFragment.Deposit1,
  },
  {
    key: "9d87907e",
    template: null,
    fillouts: null,
    label:
      "Conventions de partenariats signées avec les partenaires identifiés dans l’appel à projets.",
    requestType: ProgramKey.AnimationChambre,
    paymentFragment: PaymentFragment.Deposit1,
  },
  {
    key: "97f4e851",
    template: null,
    fillouts: null,
    label:
      "Bilan financier et technique intermédiaire du programme d’actions permettant de mettre en évidence le taux de réalisation atteint ",
    requestType: ProgramKey.LutteCollective,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "8a2b8fe0",
    template: null,
    fillouts: ["Tableau recapitulatif des depenses - Lutte collective.xlsx"],
    label: "Tableau récapitulatif signé et cacheté des dépenses réalisées",
    requestType: ProgramKey.LutteCollective,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "e95b45de",
    template: null,
    fillouts: null,
    label: "Copie des factures acquittées",
    requestType: ProgramKey.LutteCollective,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "97138cf4",
    template: null,
    fillouts: null,
    label: "Relevés de compte prouvant l’acquittement des factures",
    requestType: ProgramKey.LutteCollective,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "80cc05b2",
    template: null,
    fillouts: null,
    label:
      "Bilan financier et technique intermédiaire du programme d’actions permettant de mettre en évidence le taux de réalisation atteint ",
    requestType: ProgramKey.PromotionProduits,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "ac85bc5d",
    template: null,
    fillouts: ["Tableau recapitulatif des depenses - Promotion.xlsx"],
    label: "Tableau récapitulatif signé et cacheté des dépenses réalisées ",
    requestType: ProgramKey.PromotionProduits,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "07304de2",
    template: null,
    fillouts: null,
    label: "Copie des factures acquittées ",
    requestType: ProgramKey.PromotionProduits,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "ae22e654",
    template: null,
    fillouts: null,
    label: "Relevés de compte prouvant l’acquittement des factures",
    requestType: ProgramKey.PromotionProduits,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "ba03aa77",
    template: null,
    fillouts: null,
    label:
      "Bilan financier et technique intermédiaire du programme d’actions permettant de mettre en évidence le taux de réalisation atteint ",
    requestType: ProgramKey.EchangeConnaissance,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "eac4236c",
    template: null,
    fillouts: [
      "Tableau recapitulatif des depenses - Echanges de connaissances.xlsx",
    ],
    label: "Tableau récapitulatif signé et cacheté des dépenses réalisées",
    requestType: ProgramKey.EchangeConnaissance,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "b19b47eb",
    template: null,
    fillouts: null,
    label: "Copie des bulletins de paie",
    requestType: ProgramKey.EchangeConnaissance,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "942f3ce4",
    template: null,
    fillouts: null,
    label: "Relevés de compte prouvant l’acquittement des salaires.",
    requestType: ProgramKey.EchangeConnaissance,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "cced7c5f",
    template: null,
    fillouts: null,
    label: "Attestation de régularité sociale, datant de moins de 3 mois",
    requestType: ProgramKey.EchangeConnaissance,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "b5b0020d",
    template: null,
    fillouts: null,
    label:
      "Bilan financier et technique intermédiaire du programme d’actions permettant de mettre en évidence le taux de réalisation atteint ",
    requestType: ProgramKey.Animation,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "ccc230d8",
    template: null,
    fillouts: [
      "Tableau recapitulatif des depenses - Animation 1.xlsx",
      "Tableau recapitulatif des depenses - Animation 2.xlsx",
    ],
    label: "Tableau récapitulatif signé et cacheté des dépenses réalisées",
    requestType: ProgramKey.Animation,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "6dec4015",
    template: null,
    fillouts: null,
    label: "Copie des bulletins de paie",
    requestType: ProgramKey.Animation,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "aebb89a5",
    template: null,
    fillouts: null,
    label: "Relevés de compte prouvant l’acquittement des salaires.",
    requestType: ProgramKey.Animation,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "95db9e89",
    template: null,
    fillouts: null,
    label: "Attestation de régularité sociale, datant de moins de 3 mois",
    requestType: ProgramKey.Animation,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "ec35d08c",
    template: null,
    fillouts: null,
    label:
      "Bilan financier et technique intermédiaire du programme d’actions permettant de mettre en évidence le taux de réalisation atteint ",
    requestType: ProgramKey.AnimationChambre,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "912d4f3b",
    template: null,
    fillouts: [
      "Tableau recapitulatif des depenses - Animation 1.xlsx",
      "Tableau recapitulatif des depenses - Animation 2.xlsx",
    ],
    label: "Tableau récapitulatif signé et cacheté des dépenses réalisées",
    requestType: ProgramKey.AnimationChambre,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "ff134de7",
    template: null,
    fillouts: null,
    label: "Copie des bulletins de paie ",
    requestType: ProgramKey.AnimationChambre,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "87ecdc7f",
    template: null,
    fillouts: null,
    label: "Copie des factures acquittées",
    requestType: ProgramKey.AnimationChambre,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "c75869e3",
    template: null,
    fillouts: null,
    label:
      "Relevés de compte prouvant l’acquittement des salaires et des factures.",
    requestType: ProgramKey.AnimationChambre,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "8ab1509e",
    template: null,
    fillouts: null,
    label: "Attestation de régularité sociale, datant de moins de 3 mois",
    requestType: ProgramKey.AnimationChambre,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "d6ef0b55",
    template: null,
    fillouts: null,
    label:
      "Exemplaire des publications par voie papier et les liens vers les publications sur support numérique ",
    requestType: ProgramKey.PromotionProduits,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "f0db40a0",
    template: null,
    fillouts: null,
    label:
      "Tableau des indicateurs retenus pour l’évaluation, accompagné d’une analyse par ligne des réalisations et explication des écarts éventuels ",
    requestType: ProgramKey.PromotionProduits,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "81941ae9",
    template: null,
    fillouts: null,
    label:
      "Bilan technique définitif du programme d’actions mettant en valeur la réalisation les actions de promotion et les retombées sur les publics cibles ",
    requestType: ProgramKey.PromotionProduits,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "87297d47",
    template: null,
    fillouts: null,
    label:
      "Réalisation d’au moins une action de communication mettant en évidence l’accompagnement du Département (vidéo, publication sur les réseaux sociaux, publication presse, conférence de presse, etc) ",
    requestType: ProgramKey.PromotionProduits,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "a1553ad4",
    template: null,
    fillouts: ["Tableau recapitulatif des depenses - Promotion.xlsx"],
    label: "Tableau récapitulatif signé et cacheté des dépenses réalisées ",
    requestType: ProgramKey.PromotionProduits,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "57c14d4a",
    template: null,
    fillouts: null,
    label: "Copie des factures acquittées ",
    requestType: ProgramKey.PromotionProduits,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "a67f9f75",
    template: null,
    fillouts: null,
    label: "Relevés de compte prouvant l’acquittement des factures",
    requestType: ProgramKey.PromotionProduits,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "6a320146",
    template: null,
    fillouts: null,
    label:
      "Tableau des indicateurs retenus pour l’évaluation, accompagné d’une analyse par ligne des réalisations et explication des écarts éventuels ",
    requestType: ProgramKey.LutteCollective,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "a2444327",
    template: null,
    fillouts: null,
    label:
      "Bilan technique définitif du programme d’actions présentant les retombées sur les niveaux d’infestation voire d’éradication ",
    requestType: ProgramKey.LutteCollective,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "9b8efa7e",
    template: null,
    fillouts: null,
    label:
      "Réalisation d’au moins une action de communication mettant en évidence l’accompagnement du Département (vidéo, publication sur les réseaux sociaux, publication presse, conférence de presse, etc) ",
    requestType: ProgramKey.LutteCollective,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "3bf74e99",
    template: null,
    fillouts: ["Tableau recapitulatif des depenses - Lutte collective.xlsx"],
    label: "Tableau récapitulatif signé et cacheté des dépenses réalisées ",
    requestType: ProgramKey.LutteCollective,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "3453eb53",
    template: null,
    fillouts: null,
    label: "Copie des factures acquittées ",
    requestType: ProgramKey.LutteCollective,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "8817e582",
    template: null,
    fillouts: null,
    label: "Relevés de compte prouvant l’acquittement des factures ",
    requestType: ProgramKey.LutteCollective,
    paymentFragment: PaymentFragment.Balance,
  },

  {
    key: "add8e00a",
    template: null,
    fillouts: null,
    label:
      "Tableau des indicateurs retenus pour l’évaluation, accompagné d’une analyse par ligne des réalisations et explication des écarts éventuels ",
    requestType: ProgramKey.EchangeConnaissance,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "7a69b757",
    template: null,
    fillouts: null,
    label:
      "Bilan technique définitif du programme d’actions mettant en valeur le transfert de connaissance aux agriculteurs ",
    requestType: ProgramKey.EchangeConnaissance,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "5acb892f",
    template: null,
    fillouts: null,
    label:
      "Réalisation d’au moins une action de communication mettant en évidence l’accompagnement du Département (vidéo, publication sur les réseaux sociaux, publication presse, conférence de presse, etc)",
    requestType: ProgramKey.EchangeConnaissance,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "bce210de",
    template: null,
    fillouts: [
      "Tableau recapitulatif des depenses - Echanges de connaissances.xlsx",
    ],
    label: "Tableau récapitulatif signé et cacheté des dépenses réalisées",
    requestType: ProgramKey.EchangeConnaissance,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "b54b9483",
    template: null,
    fillouts: null,
    label: "Copie des bulletins de paie",
    requestType: ProgramKey.EchangeConnaissance,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "781e481b",
    template: null,
    fillouts: null,
    label: "Relevés de compte prouvant l’acquittement des salaires",
    requestType: ProgramKey.EchangeConnaissance,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "d7af4fdc",
    template: null,
    fillouts: [
      "Fiche de temps passe par agent finance - Echanges de connaissances.xls",
    ],
    label:
      "Contrôle du temps passé par l’agent financé - Pour chaque ETP affecté à une action, une fiche justifiant du temps passé par l’agent sur la mission. Cette fiche devra être signée par l’agent et un de ses responsables hiérarchiques",
    requestType: ProgramKey.EchangeConnaissance,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "2fbfa97e",
    template: null,
    fillouts: null,
    label: "Attestation de régularité sociale, datant de moins de 3 mois",
    requestType: ProgramKey.EchangeConnaissance,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "55eaa6ab",
    template: null,
    fillouts: [
      "Attestation sur l'honneur controle mobilisation des aides departementales VF.docx",
    ],
    label:
      "Contrôle du bon usage de l’aide accordée corrélativement aux objectifs visés - Une attestation sur l’honneur du représentant légal de la structure, indiquant que les moyens humains et les équivalents temps plein inscrits dans la convention ont bien été affectés sur les missions pour lesquelles ils sont financés par des fonds publics",
    requestType: ProgramKey.EchangeConnaissance,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "5a599061",
    template: null,
    fillouts: null,
    label:
      "Tableau des indicateurs retenus pour l’évaluation, accompagné d’une analyse par ligne des réalisations et explication des écarts éventuels ",
    requestType: ProgramKey.Animation,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "d76e422b",
    template: null,
    fillouts: null,
    label:
      "Bilan technique définitif du programme d’actions mettant en valeur les actions d’animation territoriale ",
    requestType: ProgramKey.Animation,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "8671386f",
    template: null,
    fillouts: null,
    label:
      "Réalisation d’au moins une action de communication mettant en évidence l’accompagnement du Département (vidéo, publication sur les réseaux sociaux, publication presse, conférence de presse, etc) ",
    requestType: ProgramKey.Animation,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "3e040424",
    template: null,
    fillouts: [
      "Tableau recapitulatif des depenses - Animation 1.xlsx",
      "Tableau recapitulatif des depenses - Animation 2.xlsx",
    ],
    label: "Tableau récapitulatif signé et cacheté des dépenses réalisées ",
    requestType: ProgramKey.Animation,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "2a875881",
    template: null,
    fillouts: null,
    label: "Copie des bulletins de paie",
    requestType: ProgramKey.Animation,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "1607498a",
    template: null,
    fillouts: null,
    label: "Relevés de compte prouvant l’acquittement des salaires",
    requestType: ProgramKey.Animation,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "94b192c3",
    template: null,
    fillouts: [
      "Fiche de temps passe par agent finance - Animation territoriale.xls",
    ],
    label:
      "Contrôle du temps passé par l’agent financé - Pour chaque ETP affecté à une action, une fiche justifiant du temps passé par l’agent sur la mission. Cette fiche devra être signée par l’agent et un de ses responsables hiérarchiques",
    requestType: ProgramKey.Animation,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "5e28bcf9",
    template: null,
    fillouts: null,
    label: "Attestation de régularité sociale, datant de moins de 3 mois",
    requestType: ProgramKey.Animation,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "ed94b7f2",
    template: null,
    fillouts: [
      "Attestation sur l'honneur controle mobilisation des aides departementales VF.docx",
    ],
    label:
      "Contrôle du bon usage de l’aide accordée corrélativement aux objectifs visés - Une attestation sur l’honneur du représentant légal de la structure, indiquant que les moyens humains et les équivalents temps plein inscrits dans la convention ont bien été affectés sur les missions pour lesquelles ils sont financés par des fonds publics",
    requestType: ProgramKey.Animation,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "b8b77a39",
    template: null,
    fillouts: null,
    label:
      "Tableau des indicateurs retenus pour l’évaluation, accompagné d’une analyse par ligne des réalisations et explication des écarts éventuels ",
    requestType: ProgramKey.AnimationChambre,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "dde943db",
    template: null,
    fillouts: null,
    label:
      "Bilan technique définitif du programme d’actions mettant en valeur la réalisation des actions d’animation territoriale ",
    requestType: ProgramKey.AnimationChambre,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "910741d7",
    template: null,
    fillouts: null,
    label:
      "Réalisation d’au moins une action de communication mettant en évidence l’accompagnement du Département (vidéo, publication sur les réseaux sociaux, publication presse, conférence de presse, etc)",
    requestType: ProgramKey.AnimationChambre,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "974a029e",
    template: null,
    fillouts: [
      "Tableau recapitulatif des depenses - Animation 1.xlsx",
      "Tableau recapitulatif des depenses - Animation 2.xlsx",
    ],
    label: "Tableau récapitulatif signé et cacheté des dépenses réalisées",
    requestType: ProgramKey.AnimationChambre,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "9f088e99",
    template: null,
    fillouts: null,
    label: "Copie des bulletins de paie ",
    requestType: ProgramKey.AnimationChambre,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "ae20e1d5",
    template: null,
    fillouts: null,
    label: "Copie des factures acquittées ",
    requestType: ProgramKey.AnimationChambre,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "39ad47dd",
    template: null,
    fillouts: null,
    label:
      "Relevés de compte prouvant l’acquittement des salaires et des factures ",
    requestType: ProgramKey.AnimationChambre,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "ad0386fb",
    template: null,
    fillouts: [
      "Fiche de temps passe par agent finance - Animation territoriale.xls",
    ],
    label:
      "Contrôle du temps réel passé par l’agent technique financé - Pour chaque ETP affecté à une action, une fiche justifiant du temps passé par l’agent sur la mission. Cette fiche devra être signée par l’agent et le représentant légal de la structure ",
    requestType: ProgramKey.AnimationChambre,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "ffe7c17c",
    template: null,
    fillouts: [
      "Attestation sur l'honneur controle mobilisation des aides departementales VF.docx",
    ],
    label:
      "Contrôle du bon usage de l’aide accordée corrélativement aux objectifs visés - Une attestation sur l’honneur du représentant légal de la structure, indiquant que les moyens humains et les équivalents temps plein inscrits dans la convention ont bien été affectés sur les missions pour lesquelles ils sont financés par des fonds publics",
    requestType: ProgramKey.AnimationChambre,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "50e335f9",
    template: null,
    fillouts: null,
    label: "Attestation de régularité sociale, datant de moins de 3 mois",
    requestType: ProgramKey.AnimationChambre,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "b1b9e0e2",
    template: null,
    fillouts: null,
    label: "Avis de décision du CROPSAV",
    requestType: ProgramKey.LutteCollectiveAgroeco,
    paymentFragment: PaymentFragment.Deposit1,
  },
  {
    key: "747607cf",
    template: null,
    fillouts: null,
    label:
      "Conventions de partenariats signées avec les partenaires identifiés dans l'appel à projets",
    requestType: ProgramKey.LutteCollectiveAgroeco,
    paymentFragment: PaymentFragment.Deposit1,
  },
  {
    key: "04d7f117",
    template: null,
    fillouts: null,
    label:
      "Bilan financier et technique intermédiaire du programme d'actions permettant de mettre en évidence le taux de réalisation atteint",
    requestType: ProgramKey.LutteCollectiveAgroeco,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "39ecae8e",
    template: null,
    fillouts: [
      "Tableau recapitulatif des depenses - Lutte collective agroecologique.xlsx",
    ],
    label: "Tableau récapitulatif signé et cacheté des dépenses réalisées",
    requestType: ProgramKey.LutteCollectiveAgroeco,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "ed58222a",
    template: null,
    fillouts: null,
    label: "Copie des factures acquittées",
    requestType: ProgramKey.LutteCollectiveAgroeco,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "01a3dafa",
    template: null,
    fillouts: null,
    label: "Relevés de compte prouvant l'acquittement des factures",
    requestType: ProgramKey.LutteCollectiveAgroeco,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "9391144a",
    template: null,
    fillouts: null,
    label:
      "Tableau des indicateurs retenus pour l'évaluation, accompagné d'une analyse par ligne des réalisations et explication des écarts éventuels",
    requestType: ProgramKey.LutteCollectiveAgroeco,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "38125b8c",
    template: null,
    fillouts: null,
    label:
      "Bilan technique définitif du programme d'actions présentant les résultats obtenus",
    requestType: ProgramKey.LutteCollectiveAgroeco,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "ea737468",
    template: null,
    fillouts: null,
    label:
      "Réalisation d'au moins une action de communication mettant en évidence l'accompagnement du Département (vidéo, publication sur les réseaux sociaux, publication presse, conférence de presse, etc)",
    requestType: ProgramKey.LutteCollectiveAgroeco,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "efd48993",
    template: null,
    fillouts: [
      "Tableau recapitulatif des depenses - Lutte collective agroecologique.xlsx",
    ],
    label: "Tableau récapitulatif signé et cacheté des dépenses réalisées",
    requestType: ProgramKey.LutteCollectiveAgroeco,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "2db7514f",
    template: null,
    fillouts: null,
    label: "Copie des factures acquittées",
    requestType: ProgramKey.LutteCollectiveAgroeco,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "03af4736",
    template: null,
    fillouts: null,
    label: "Relevés de compte prouvant l'acquittement des factures",
    requestType: ProgramKey.LutteCollectiveAgroeco,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "e6a47801",
    template: null,
    fillouts: null,
    label: "Attestation de régularité fiscale",
    requestType: ProgramKey.SystemesQualite,
    paymentFragment: PaymentFragment.Deposit1,
  },
  {
    key: "8125771d",
    template: null,
    fillouts: null,
    label: "Contrat avec Bureau Veritas signé",
    requestType: ProgramKey.SystemesQualite,
    paymentFragment: PaymentFragment.Deposit1,
  },
  {
    key: "1bfd6a7a",
    template: null,
    fillouts: null,
    label:
      "Convention de partenariat signée avec les partenaires identifiés dans l'appel à projets",
    requestType: ProgramKey.SystemesQualite,
    paymentFragment: PaymentFragment.Deposit1,
  },
  {
    key: "14a708a0",
    template: null,
    fillouts: null,
    label:
      "Bilan financier et technique intermédiaire du programme d'actions permettant de mettre en évidence le taux de réalisation atteint",
    requestType: ProgramKey.SystemesQualite,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "beb06207",
    template: null,
    fillouts: ["Tableau recapitulatif des depenses - Systeme de qualite.xlsx"],
    label: "Tableau récapitulatif signé et cacheté des dépenses réalisées",
    requestType: ProgramKey.SystemesQualite,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "fcd57ec0",
    template: null,
    fillouts: null,
    label: "Copie des factures acquittées",
    requestType: ProgramKey.SystemesQualite,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "9aa7eb44",
    template: null,
    fillouts: null,
    label: "Relevés de compte prouvant l'acquittement des factures",
    requestType: ProgramKey.SystemesQualite,
    paymentFragment: PaymentFragment.Deposit2,
  },
  {
    key: "ae770249",
    template: null,
    fillouts: null,
    label:
      "Tableau des indicateurs retenus pour l'évaluation, accompagné d'une analyse par ligne des réalisations et explication des écarts éventuels",
    requestType: ProgramKey.SystemesQualite,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "a1d38db1",
    template: null,
    fillouts: null,
    label:
      "Bilan technique définitif du programme d'actions présentant les résultats obtenus",
    requestType: ProgramKey.SystemesQualite,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "e06f071c",
    template: null,
    fillouts: null,
    label:
      "Réalisation d'au moins une action de communication mettant en évidence l'accompagnement du Département (vidéo, publication sur les réseaux sociaux, publication presse, conférence de presse, etc)",
    requestType: ProgramKey.SystemesQualite,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "87a3ca21",
    template: null,
    fillouts: ["Tableau recapitulatif des depenses - Systeme de qualite.xlsx"],
    label: "Tableau récapitulatif signé et cacheté des dépenses réalisées",
    requestType: ProgramKey.SystemesQualite,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "a70cf681",
    template: null,
    fillouts: null,
    label: "Copie des factures acquittées",
    requestType: ProgramKey.SystemesQualite,
    paymentFragment: PaymentFragment.Balance,
  },
  {
    key: "2318dd18",
    template: null,
    fillouts: null,
    label: "Relevés de compte prouvant l'acquittement des factures",
    requestType: ProgramKey.SystemesQualite,
    paymentFragment: PaymentFragment.Balance,
  },
];

export default Receipts;
