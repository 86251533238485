import { Fragment, useMemo } from "react";
import ProjectAnnexHeader from "../ProjectAnnexHeader";
import DataTable, { DataTableColumn } from "@hpo/client/components/DataTable";
import { ProductInstructor } from "@hpo/client/models/Expense";
import { Annex, ProjectInstructor } from "@hpo/client/models/Project";
import Spacer from "@hpo/client/utilities/Spacer";
import Units from "@hpo/client/utilities/Units";
import { getServerDateAsString } from "@hpo/client/utilities/helpers/DateHelper";
import { distpatchExpenses } from "@hpo/client/utilities/helpers/ExpenseHelper";

type ProjectTabProductsProps = {
  project: ProjectInstructor;
  annex: Annex;
  onReload: () => unknown;
};

export default function ProjectTabProducts(props: ProjectTabProductsProps) {
  const { annex, onReload, project } = props;

  return (
    <Fragment>
      <ProjectAnnexHeader
        title="Récapitulatif des dépenses prévisionnelles - PRESTATIONS"
        annex={annex}
        onReload={onReload}
        project={project}
      />
      <Spacer />
      <ProductsList {...props} />
    </Fragment>
  );
}

function ProductsList(props: ProjectTabProductsProps) {
  const { project } = props;

  const products = useMemo(
    () => distpatchExpenses(project.expenses).product,
    [project],
  );

  const columns: DataTableColumn<ProductInstructor>[] = [
    {
      field: "label",
      header: "Intitulé",
    },
    {
      field: "provider",
      header: "Nom du prestataire",
    },
    {
      field: "quotationReference",
      header: "N° du devis",
    },
    {
      header: "Date du devis",
      body: (e) => {
        return getServerDateAsString(e.quotationDate);
      },
    },
    {
      field: "quantity",
      header:
        "Nombre d'unités de vaccins, de médicaments, de substances prévu dans le devis",
    },
    {
      header: "Cout total prévisionnel du devis / an",
      body: (e) => {
        return Units.euro.display(e.annualCost);
      },
    },
    {
      header: "Coût total prévisionnel du devis sur 3 ans",
      body: (e) => {
        return Units.euro.display(e.quotationTotalCost);
      },
    },
    {
      header:
        "Montant de l'aide éligilibe = coût du devis plafonné le cas échéant / an",
      body: (e) => {
        return Units.euro.display(e.helpAmountPerYear);
      },
    },
    {
      header: "Montant de l'aide éligible sur 3 ans",
      body: (e) => {
        return Units.euro.display(e.helpAmountThreeYears);
      },
    },
  ];

  return <DataTable value={products} columns={columns} />;
}
