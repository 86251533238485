import {
  DependencyList,
  useRef,
  useState,
  useEffect,
  useCallback,
} from "react";

/**
 * @experimental
 */
export default function useData<D>(fn: () => Promise<D>, deps: DependencyList) {
  const ref = useRef(false);
  const mounted = useRef<boolean>(false);
  const [promise, setPromise] = useState<Promise<D>>(fn);
  const value = useRef<D | undefined>(undefined);
  const [at, setAt] = useState<number>(() => new Date().valueOf());
  const [noisyReloadAt, setNoisyReloadAt] = useState<number | null>(null);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }
    setPromise(fn());
  }, deps);

  const reload = useCallback((silent?: boolean) => {
    const noisyReloadAt = new Date().valueOf();
    if (!silent) {
      setNoisyReloadAt(noisyReloadAt);
    }
    const promise = fn();
    if (!silent) {
      void promise.finally(() => {
        setNoisyReloadAt((current) =>
          current === noisyReloadAt ? null : current,
        );
      });
    }
    setPromise(promise);
  }, deps);

  const reloadSilent = useCallback(() => {
    reload(true);
  }, [reload]);

  useEffect(() => {
    promise.then(
      (v) => {
        value.current = v;
        setAt(new Date().valueOf());
      },
      () => (value.current = undefined),
    );
  }, [promise]);

  const merge = useCallback(
    async (updater: (current: D) => D) => {
      if (value.current === undefined) {
        return;
      }
      const newValue = updater(value.current);
      setPromise(Promise.resolve(newValue));
    },
    [promise],
  );

  const set = useCallback((value: D) => {
    setPromise(Promise.resolve(value));
  }, []);

  return [
    promise,
    {
      reload,
      reloadSilent,
      merge,
      set,
      isLoudlyReloading: noisyReloadAt !== null,
    },
  ] as const;
}
