import { Fragment, useMemo, useState } from "react";
import { useServerSdk } from "../../RootNavigator/services/ServerSdk";
import TagEditor from "./TagEditor";
import useData from "@hpo/client/utilities/useData";
import Await from "@hpo/client/utilities/Await";
import Button from "@hpo/client/components/Button";
import List, { ListItem } from "@hpo/client/utilities/List";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import { Tag, TagCreation } from "@hpo/client/models/Tag";
import MessageException from "@hpo/client/utilities/errors/MessageException";
import SideBar from "@hpo/client/components/SideBar";
import PromiseToast from "@hpo/client/utilities/PromiseToast";
import PageHeader from "@hpo/client/utilities/PageHeader";
import Spacer from "@hpo/client/utilities/Spacer";

export default function TagsScreen() {
  const server = useServerSdk();
  const [tags, tagsData] = useData(() => server.getTags(), []);

  return (
    <Await promise={tags}>
      {(tags) => <TagsView tags={tags} onRefresh={tagsData.reloadSilent} />}
    </Await>
  );
}

type TagsViewProps = {
  tags: Array<Tag>;
  onRefresh: () => unknown;
};

function TagsView({ tags, onRefresh }: TagsViewProps) {
  const server = useServerSdk();
  const [selected, setSelected] = useState<string | null | true>(null);

  const [onCreation, creation] = useSubmitCallback(
    async (tag: TagCreation) => {
      if (!tag.label)
        throw new MessageException(
          "Veuillez définir un libellé à l'étiquette",
          null,
        );

      await server.createTag(tag);

      onRefresh();
      setSelected(null);
    },
    [onRefresh],
  );

  const [onEdit, edition] = useSubmitCallback(
    async (id: string, tag: TagCreation) => {
      if (!tag.label)
        throw new MessageException(
          "Veuillez définir un libellé à l'étiquette",
          null,
        );

      await server.updateTag(id, {
        label: tag.label,
        description: tag.description,
      });

      onRefresh();
      setSelected(null);
    },
    [onRefresh],
  );

  const sortedTags = useMemo(
    () => tags.sort((a, b) => a.label.localeCompare(b.label)),
    [tags],
  );

  return (
    <Fragment>
      <PageHeader
        title="Etiquettes d'indicateurs"
        titleStyle="subtitle"
        right={[
          <Button
            key="new"
            label="Nouvelle étiquette"
            onClick={() => setSelected(true)}
            icon="plus"
          />,
        ]}
      />
      <Spacer />
      <List
        data={sortedTags}
        renderItem={(tag) => (
          <>
            <ListItem
              label={tag.label}
              help={tag.description || ""}
              onClick={() => setSelected(tag.id)}
            />
            <SideBar
              visible={selected === tag.id}
              onHide={() => setSelected(null)}
            >
              <TagEditor
                key={tag.id}
                tag={tag}
                onDone={(newTag) => onEdit(tag.id, newTag)}
              />
            </SideBar>
          </>
        )}
      />
      <SideBar visible={selected === true} onHide={() => setSelected(null)}>
        <TagEditor tag={null} onDone={onCreation} />
      </SideBar>
      <PromiseToast promise={creation.promise} message={"Tag ajouté"} />
      <PromiseToast promise={edition.promise} message={"Tag modifié"} />
    </Fragment>
  );
}
