import Badge from "./Badge";
import {
  InstructorPaymentStatus,
  InstructorPaymentStatusColors,
  InstructorPaymentStatusLabels,
} from "./enums/InstructorPaymentStatus";

type InstructorPaymentStatusBadgeProps = {
  status: InstructorPaymentStatus;
};

export default function InstructorPaymentStatusBadge(
  props: InstructorPaymentStatusBadgeProps,
) {
  const { status } = props;
  const label = InstructorPaymentStatusLabels[status];
  const color = InstructorPaymentStatusColors[status];
  return <Badge color={color}>{label}</Badge>;
}
