import { Route, Switch } from "wouter";
import { useAssistance } from "../RootNavigator/services/Assistance";
import ProjectScreen from "./ProjectScreen";
import ProjectsScreen from "./ProjectsScreen";
import { projectRoute } from "@hpo/client/utilities/routes";

export default function ProjectNavigator() {
  const assistance = useAssistance();
  assistance.useVisibilityDecision(false);

  return (
    <Switch>
      <Route path={"/"} component={ProjectsScreen} />
      <Route path={projectRoute.getPath()} component={ProjectScreen} />
    </Switch>
  );
}
