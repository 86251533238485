import { coerce, date, define } from "superstruct";

type IsoDate = string;

export const isoDateSchema = define<IsoDate>("IsoDate", (value: unknown) => {
  if (typeof value !== "string") {
    return false;
  }
  return !isNaN(Date.parse(value));
});

export const isoDateCoercer = coerce(date(), isoDateSchema, (v) => new Date(v));

export default IsoDate;
