import { Fragment, useMemo, useState } from "react";
import { useServerSdk } from "../RootNavigator/services/ServerSdk";
import ContentLayout from "@hpo/client/components/layout/ContentLayout";
import Await from "@hpo/client/utilities/Await";
import Badge from "@hpo/client/utilities/Badge";
import List, { ListItem } from "@hpo/client/utilities/List";
import Spacer from "@hpo/client/utilities/Spacer";
import { getProjectLabel } from "@hpo/client/utilities/helpers/ProjectHelper";
import { projectRoute } from "@hpo/client/utilities/routes";
import useData from "@hpo/client/utilities/useData";
import DropDownField from "@hpo/client/utilities/fields/DropDownField";
import { ProjectSummary } from "@hpo/client/models/Project";
import { OrganizationSummary } from "@hpo/client/models/Organization";
import T from "@hpo/client/components/text/Text";
import Intersperse from "@hpo/client/components/Intersperse";
import {
  ProjectStatusColors,
  ProjectStatusLabels,
} from "@hpo/client/utilities/enums/ProjectStatus";
import ProgramKey, {
  ProgramKeyValues,
} from "@hpo/client/utilities/enums/ProgramKey";
import Programs from "@hpo/client/utilities/Programs";

export default function ProjectsScreen() {
  const server = useServerSdk();
  const [projects] = useData(async () => server.getProjects(), []);
  const [organizations] = useData(async () => server.getOrganizations(), []);

  return (
    <ContentLayout title="Projets">
      <Await promise={projects}>
        {(projects) => (
          <Await promise={organizations}>
            {(organizations) => (
              <ProjectsScreenLoaded
                projects={projects}
                organizations={organizations}
              />
            )}
          </Await>
        )}
      </Await>
      <Spacer />
    </ContentLayout>
  );
}

type ProjectsScreenLoadedProps = {
  projects: ProjectSummary[];
  organizations: OrganizationSummary[];
};
function ProjectsScreenLoaded({
  projects,
  organizations,
}: ProjectsScreenLoadedProps) {
  const [organization, setOrganization] = useState<OrganizationSummary | null>(
    null,
  );

  const filteredProjects = useMemo(() => {
    if (!organization) return projects;
    return projects.filter(
      (project) => project.organization.id === organization.id,
    );
  }, [projects, organization]);

  const byProjectType = useMemo(() => {
    const output: Array<{
      projectType: ProgramKey;
      projects: Array<ProjectSummary>;
    }> = [];
    ProgramKeyValues.forEach((projectType) => {
      const projects = filteredProjects.filter((p) => p.type === projectType);
      if (projects.length === 0) return;
      output.push({ projectType, projects });
    });
    return output;
  }, [filteredProjects]);

  return (
    <Fragment>
      <DropDownField
        id="org"
        label="Filtrer par bénéficiaire"
        value={organization}
        options={organizations}
        keyExtractor={(o) => o.id}
        renderOption={(o) => ({ label: o.businessName })}
        renderNullOption={() => ({ label: "Tous les bénéficiaires" })}
        onChange={setOrganization}
      />
      <Spacer />
      <Intersperse between={() => <Spacer />}>
        {byProjectType.map((item) => {
          return (
            <Fragment>
              <T style="section">{Programs[item.projectType].label}</T>
              <List
                data={item.projects}
                renderItem={(project) => {
                  return (
                    <ListItem
                      label={getProjectLabel(project)}
                      help={project.organization.businessName}
                      right={
                        <Badge color={ProjectStatusColors[project.status]}>
                          {ProjectStatusLabels[project.status]}
                        </Badge>
                      }
                      to={projectRoute.getRootUrl({ id: project.id })}
                    />
                  );
                }}
              />
            </Fragment>
          );
        })}
      </Intersperse>
    </Fragment>
  );
}
