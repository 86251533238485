import { Fragment } from "react";
import { useServerSdk } from "../RootNavigator/services/ServerSdk";
import WithHelp from "../RootNavigator/assistance/Help/WithHelp";
import Await from "@hpo/client/utilities/Await";
import Spacer from "@hpo/client/utilities/Spacer";
import useData from "@hpo/client/utilities/useData";
import EmptyView from "@hpo/client/utilities/EmptyView";
import T from "@hpo/client/components/text/Text";
import Intersperse from "@hpo/client/components/Intersperse";
import List, { ListItem } from "@hpo/client/utilities/List";
import {
  BeneficiaryPaymentSummary,
  BeneficiaryPaymentsByConvention,
  getPaymentLabel,
} from "@hpo/client/models/BeneficiaryPayment";
import { getUrl, myPaymentRoute } from "@hpo/client/utilities/routes";
import BeneficiaryPaymentStatusBadge from "@hpo/client/utilities/BeneficiaryPaymentStatusBadge";
import Page from "@hpo/client/utilities/Page";
import PageHeader from "@hpo/client/utilities/PageHeader";

export default function MyPaymentsScreen() {
  const server = useServerSdk();
  const [conventions] = useData(async () => server.getMyPayments(), []);

  return (
    <Await promise={conventions}>
      {(conventions) => <ConventionsDispatcher conventions={conventions} />}
    </Await>
  );
}

// Conventions

type ConventionsDispatcherProps = {
  conventions: BeneficiaryPaymentsByConvention;
};

function ConventionsDispatcher(props: ConventionsDispatcherProps) {
  const { conventions } = props;
  if (conventions.length === 0) {
    return <EmptyView message="Vous n'avez aucun paiement pour le moment" />;
  } else if (conventions.length === 1) {
    return <OneConvention payments={conventions[0].payments} />;
  } else {
    return <SeveralConventions conventions={conventions} />;
  }
}

type OneConventionProps = {
  payments: Array<BeneficiaryPaymentSummary>;
};

function OneConvention(props: OneConventionProps) {
  return (
    <Page>
      <WithHelp
        title="A quoi correspondent ces paiements ?"
        text={
          <Fragment>
            <Spacer />
            <T>
              Suite à l'instruction de vos demandes de financement, le
              Département à mis en place une convention. Elle régit la manière
              dont vos projets seront subventionnés dans les années à venir.
            </T>
            <Spacer />
            <T>
              Chaque année, votre convention peut déclencher jusqu'à 3 paiements
              : un premier acompte, un second acompte et le solde en fin
              d'année. Vous devez explicitement demander chacun de ces paiements
              et motiver votre demande par différents justificatifs.
            </T>
            <Spacer />
            <T>
              Après instruction et accord du Département, vous recevrez un seul
              virement bancaire par demande de paiement, couvrant l'ensemble des
              projets inclus dans votre convention.
            </T>
          </Fragment>
        }
      >
        <PageHeader title="Mes paiements" />
        <Spacer />
      </WithHelp>
      <PaymentsList payments={props.payments} />
    </Page>
  );
}

type SeveralConventionsProps = {
  conventions: BeneficiaryPaymentsByConvention;
};

function SeveralConventions(props: SeveralConventionsProps) {
  const { conventions } = props;
  return (
    <Page>
      <WithHelp
        title="A quoi correspondent ces  différentes conventions et ces paiements ?"
        text={
          <Fragment>
            <Spacer />
            <T>
              Suite à l'instruction de vos demandes de financement, le
              Département à mis en place plusieurs conventions. Chaque
              convention régit la manière dont 1 ou plusieurs de vos projets
              seront subventionnés dans les années à venir.
            </T>
            <Spacer />
            <T>
              Vos projets ont été groupés dans différentes conventions de
              manière à ce qu'une seule convention ne soit établie par type
              d'appel à projet.
            </T>
            <Spacer />
            <T>
              Chaque année, chaque convention peut déclencher jusqu'à 3
              paiements : un premier acompte, un second acompte et le solde en
              fin d'année. Vous devez explicitement demander chacun de ces
              paiements et motiver votre demande par différents justificatifs.
            </T>
            <Spacer />
            <T>
              Après instruction et accord du Département, vous recevrez un seul
              virement bancaire par demande de paiement, couvrant l'ensemble des
              projets inclus dans la convention.
            </T>
          </Fragment>
        }
      >
        <PageHeader title="Mes paiements" />
        <Spacer />
      </WithHelp>
      <Intersperse between={() => <Spacer scale={3} />}>
        {conventions.map((c) => {
          return (
            <Fragment key={c.convention.id}>
              <T style="subtitle">{`Dans le cadre de la convention n°${c.convention.reference}`}</T>
              <PaymentsList payments={c.payments} />
            </Fragment>
          );
        })}
      </Intersperse>
    </Page>
  );
}

// Payments

type PaymentsListProps = {
  payments: Array<BeneficiaryPaymentSummary>;
};

function PaymentsList(props: PaymentsListProps) {
  const { payments } = props;
  return (
    <List
      data={payments}
      renderItem={(p) => (
        <ListItem
          label={getPaymentLabel(p)}
          to={getUrl(myPaymentRoute, { paymentId: p.id })}
          right={<BeneficiaryPaymentStatusBadge status={p.status} />}
        />
      )}
    />
  );
}
