import { Fragment, useState } from "react";
import dayjs from "dayjs";
import { useServerSdk } from "../../RootNavigator/services/ServerSdk";
import useData from "@hpo/client/utilities/useData";
import Await from "@hpo/client/utilities/Await";
import ProgramKey from "@hpo/client/utilities/enums/ProgramKey";
import List, { ListItem } from "@hpo/client/utilities/List";
import Badge from "@hpo/client/utilities/Badge";
import Theme from "@hpo/client/utilities/Theme";
import SideBar from "@hpo/client/components/SideBar";
import SwitchField from "@hpo/client/utilities/fields/SwitchField";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import DateField from "@hpo/client/utilities/fields/DateField";
import Spacer from "@hpo/client/utilities/Spacer";
import FieldsForm from "@hpo/client/utilities/fields/FieldsForm";
import ErrorToast from "@hpo/client/utilities/ErrorToast";
import Button from "@hpo/client/components/Button";
import {
  ProgramAvailability,
  ProgramAvailabilityPayload,
} from "@hpo/client/models/Program";
import PageHeader from "@hpo/client/utilities/PageHeader";

export default function ProjectTypesView() {
  const server = useServerSdk();
  const [projectTypes, data] = useData(() => server.getProjectTypes(), []);

  const [selection, setSelection] = useState<ProgramKey | null>(null);

  const [onDone, submission] = useSubmitCallback(
    async (payload: ProgramAvailabilityPayload) => {
      if (!selection) return;
      await server.updateProjectType(selection, payload);
      data.reload();
      setSelection(null);
    },
    [selection],
  );

  return (
    <Fragment>
      <PageHeader title="Appels à projet" titleStyle="subtitle" />
      <Spacer />
      <Await promise={projectTypes}>
        {(projectTypes) => {
          return (
            <Fragment>
              <List
                data={projectTypes}
                renderItem={(type) => (
                  <ListItem
                    label={type.label}
                    onClick={() => setSelection(type.key)}
                    right={
                      type.active ? (
                        type.visible ? (
                          <Badge color={Theme.color}>Visible</Badge>
                        ) : (
                          <Badge color={Theme.warning}>Masqué</Badge>
                        )
                      ) : (
                        <Badge color={Theme.error}>Désactivé</Badge>
                      )
                    }
                    help={
                      type.active
                        ? type.from
                          ? type.to
                            ? `Visible du ${dayjs(type.from).format("LL")} au ${dayjs(type.to).format("LL")}`
                            : `Visible à partir du ${dayjs(type.from).format("LL")}`
                          : type.to
                            ? `Visible jusqu'au ${dayjs(type.to).format("LL")}`
                            : ""
                        : ""
                    }
                  />
                )}
              ></List>
              {projectTypes.map((type) => (
                <ProgramSideBar
                  key={type.key}
                  onDone={onDone}
                  visible={type.key === selection}
                  projectType={type}
                  onClose={() => setSelection(null)}
                />
              ))}
            </Fragment>
          );
        }}
      </Await>
      <ErrorToast error={submission.error} />
    </Fragment>
  );
}

type ProgramSideBarProps = {
  visible: boolean;
  onClose: () => unknown;
  projectType: ProgramAvailability;
  onDone: (output: ProgramAvailabilityPayload) => unknown;
};

function ProgramSideBar(props: ProgramSideBarProps) {
  const { visible, onClose, projectType, onDone } = props;

  const [active, setActive] = useState<boolean>(projectType.active);
  const [from, setFrom] = useState<string | null>(
    projectType.from ? dayjs(projectType.from).format("YYYY-MM-DD") : null,
  );
  const [to, setTo] = useState<string | null>(
    projectType.to ? dayjs(projectType.to).format("YYYY-MM-DD") : null,
  );

  const [onSubmit, submission] = useSubmitCallback(() => {
    if (active) {
      onDone({
        active: true,
        from: from ? dayjs(from).startOf("day").toISOString() : null,
        to: to ? dayjs(to).endOf("day").toISOString() : null,
      });
    } else {
      onDone({ active: false, from: null, to: null });
    }
  }, [active, from, to]);

  return (
    <SideBar visible={visible} onHide={onClose}>
      <ErrorToast error={submission.error} />
      <FieldsForm onSubmit={onSubmit}>
        <SwitchField
          label="Activer l'appel à projet"
          value={active}
          onChange={setActive}
        />
        {active ? (
          <Fragment>
            <Spacer />
            <DateField
              label="Afficher à partir du..."
              value={from}
              onChange={setFrom}
            />
            <Spacer />
            <DateField
              label="Afficher jusqu'au..."
              value={to}
              onChange={setTo}
            />
          </Fragment>
        ) : null}
        <Spacer />
        <Button submit label="Valider" />
      </FieldsForm>
    </SideBar>
  );
}
