import Badge from "./Badge";
import {
  BeneficiaryPaymentStatus,
  BeneficiaryPaymentStatusColors,
  BeneficiaryPaymentStatusLabels,
} from "./enums/BeneficiaryPaymentStatus";

type BeneficiaryPaymentStatusBadgeProps = {
  status: BeneficiaryPaymentStatus;
};

export default function BeneficiaryPaymentStatusBadge(
  props: BeneficiaryPaymentStatusBadgeProps,
) {
  const { status } = props;
  const label = BeneficiaryPaymentStatusLabels[status];
  const color = BeneficiaryPaymentStatusColors[status];
  return <Badge color={color}>{label}</Badge>;
}
