import { Fragment, useMemo, useState } from "react";
import { MyProjectBaseProps } from "..";
import ServiceSideBar from "./ServiceSideBar";
import DataTable, { DataTableColumn } from "@hpo/client/components/DataTable";
import { ServiceBeneficiary } from "@hpo/client/models/Expense";
import Button from "@hpo/client/components/Button";
import Spacer from "@hpo/client/utilities/Spacer";
import Units from "@hpo/client/utilities/Units";
import PageHeader from "@hpo/client/utilities/PageHeader";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import Dialog from "@hpo/client/components/Dialog";
import { useServerSdk } from "@hpo/client/navigators/RootNavigator/services/ServerSdk";
import Toasting from "@hpo/client/components/Toasting";
import NumberField from "@hpo/client/utilities/fields/NumberField";
import { distpatchExpenses } from "@hpo/client/utilities/helpers/ExpenseHelper";

type MyProjectServicesProps = MyProjectBaseProps;

export default function MyProjectServices(props: MyProjectServicesProps) {
  const { project, onRefresh } = props;

  const server = useServerSdk();

  const services = useMemo(
    () => distpatchExpenses(project.expenses).service,
    [project.expenses],
  );

  const [open, setOpen] = useState<string | "new" | null>(null);

  const columns: Array<DataTableColumn<ServiceBeneficiary>> = [
    {
      field: "label",
      header: "Libellé",
    },
    {
      field: "provider",
      header: "Fournisseur",
    },
    {
      field: "quotationReference",
      header: "N° de devis",
    },
    {
      field: "quotationDate",
      header: "Date du devis",
    },
    {
      field: "quotationDetail",
      header: "Détail du devis",
    },
    {
      header: "Coût annuel",
      body: (service: ServiceBeneficiary) => {
        return Units.euro.display(service.annualCost);
      },
    },
  ];

  const onDone = () => {
    setOpen(null);
    onRefresh();
  };

  const [deleteService, deletingStatus] = useSubmitCallback(
    async (service: ServiceBeneficiary) => {
      const confirm = await Dialog.confirm({
        message: "Ce devis de service sera supprimé ce manière définitive",
      });

      if (!confirm) return;

      await server.deleteExpense(project.id, service.id);

      Toasting.success("Succès", "Devis de service supprimé.");

      onRefresh();
    },
    [],
  );

  const totalAnnualCosts = useMemo(() => {
    return services.reduce((acc, service) => acc + service.annualCost, 0);
  }, [services]);

  return (
    <Fragment>
      <PageHeader
        title="Récapitulatif des devis de service"
        titleStyle="subtitle"
        right={
          <Button icon="plus" label="Ajouter" onClick={() => setOpen("new")} />
        }
      />
      <Spacer />
      <DataTable
        columns={columns}
        value={services}
        actions={{
          onEditClick: (service: ServiceBeneficiary) => setOpen(service.id),
          onDeleteClick: deleteService,
          deleteLoading: deletingStatus.running,
          editDisabled: deletingStatus.running,
        }}
      />
      {services.map((service) => (
        <ServiceSideBar
          key={service.id}
          project={project}
          service={service}
          visible={open === service.id}
          onDone={onDone}
        />
      ))}
      <ServiceSideBar
        project={project}
        service={null}
        visible={open === "new"}
        onDone={onDone}
      />
      <Spacer />
      <NumberField
        label="Total des coûts annuels"
        value={totalAnnualCosts}
        readonly
        unit="euro"
      />
    </Fragment>
  );
}
