import { useCallback, useEffect, useMemo, useState } from "react";
import { Toast, ToastMessage } from "primereact/toast";
import R from "../utilities/R";
import MessageException from "../utilities/errors/MessageException";

const r = new R();

let toasts: Array<ToastMessage> = [];

function success(title: string, message: string) {
  toasts = [...toasts, { severity: "info", summary: title, detail: message }];
  r.notify();
}

function error(title: string, message: any) {
  const formattedMessage =
    message instanceof MessageException ? message.message : message;

  toasts = [
    ...toasts,
    { severity: "error", summary: title, detail: formattedMessage },
  ];
  r.notify();
}

export function Toasts() {
  const all = r.useSelector(() => toasts);
  return all.map((t, i) => <PrimeReactToast key={i} {...t} />);
}

function PrimeReactToast(props: ToastMessage) {
  const [controller, setController] = useState<Toast | null>(null);

  const onToastReady = useCallback((c: Toast) => {
    setController((current) => current || c);
  }, []);

  useEffect(() => {
    if (!controller) return;
    setTimeout(() => controller.show(props), 100);
  }, [controller]);
  return <Toast ref={onToastReady} />;
}

const Toasting = { success, error };

export default Toasting;
