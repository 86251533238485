import { number, object, string } from "superstruct";
import Config from "../navigators/RootNavigator/services/Config";
import getFileCategory, { FileCategory, getMimeCategory } from "./FileCategory";
import {
  FilePointer,
  LocalFilePointer,
  UploadedFilePointer,
} from "./FilePointer";
import JwtManager from "./JwtManager";

export type FileDescriptor = {
  key: string;
  url: string;
  category: FileCategory;
  bytes: number;
  name: string;
};

const cache: Record<string, Promise<FileDescriptor>> = {};

function getFilePointerCacheId(p: FilePointer) {
  if (p.type === "local-file") return p.hash;
  else if (p.type === "uploaded-file") return p.jwt;
  else return new URL(p.url).pathname;
}

export function cacheUploadedFilePointer(
  uploaded: UploadedFilePointer,
  local: LocalFilePointer,
) {
  cache[getFilePointerCacheId(uploaded)] = downloadPointer(local);
}

export function filePointerToFileDescriptor(
  pointer: FilePointer,
): Promise<FileDescriptor> {
  const id = getFilePointerCacheId(pointer);
  const inCache = cache[id] as Promise<FileDescriptor> | undefined;
  if (inCache) {
    return cache[id];
  }
  const promise = downloadPointer(pointer);
  cache[id] = promise;
  return promise;
}

async function downloadPointer(pointer: FilePointer) {
  if (pointer.type === "local-file") {
    return {
      key: `file-${pointer.hash}`,
      name: pointer.file.name,
      bytes: pointer.file.size,
      url: URL.createObjectURL(pointer.file),
      category: getFileCategory(pointer.file),
    };
  } else if (pointer.type === "confirmed-file") {
    const download = await fetch(pointer.url);
    const disposition = download.headers.get("Content-Disposition");
    const length = download.headers.get("Content-Length");
    let name: string = "Fichier";
    let bytes: number = 0;
    if (disposition) {
      const regExpFilename = /filename="(?<filename>.*)"/;
      const exec = regExpFilename.exec(disposition);
      if (exec && exec[1]) name = exec[1];
    }
    if (length && !isNaN(parseInt(length))) {
      bytes = parseInt(length);
    }
    const type = download.headers.get("Content-type");
    return {
      key: pointer.url,
      name,
      bytes,
      url: pointer.url,
      category: type ? getMimeCategory(type) : ("other" as FileCategory),
    };
  } else {
    const manager = new JwtManager(
      object({ id: string(), mime: string(), size: number(), name: string() }),
    );
    const decoded = manager.decode(pointer.jwt);
    const output = {
      key: `uploaded-file-${pointer.jwt}`,
      name: decoded.getPayload().name,
      bytes: decoded.getPayload().size,
      url: Config.server.url + `/uploads/${pointer.jwt}`,
      category: getMimeCategory(decoded.getPayload().mime),
    };
    return output;
  }
}
