const Amounts = {
  granted: {
    label: "Montant acordé",
    info: "Montant prévu dans le cadre de convention, éventuellement modifié par des avenants",
  },
  stock: {
    label: "Montant antérieur reporté",
    info: "Montant qui n'a pas été validé lors des paiements précédents",
  },
  available: {
    label: "Montant disponible",
    info: "Montant maximal auquel le bénéficiaire peut prétendre (montant prévu + report)",
  },
  requested: {
    label: "Montant demandé par le bénéficiaire",
    info: "Montant que le bénéficiaire souhaite obtenir, au regard des justificatifs et des motivations apportées",
  },
  instructed: {
    label: "Montant proposé par l'instructeur",
    info: "Montant que le service instructeur suggère de verser",
  },
  validated: {
    label: "Montant validé niveau 1",
    info: "Montant validé par le Département (niveau 1)",
  },
  certified: {
    label: "Montant validé niveau 2",
    info: "Montant attribué au bénéficiaire définitivement, validé par le Département (niveau 2)",
  },
  stockable: {
    label: "Reliquat restant à accorder",
    info: "Montant qui n'a pas été attribué au bénéficiaire (montant disponible - montant définitif). Le cas échéant, il sera reporté au prochain paiement.",
  },
  maximalAdvance: {
    label: "Montant déjà versé avant la convention à déduire",
    info: "Montant de l'avance disponible, versée au bénéficiaire préalablement à sa demande. Ce montant viendra en déduction du montant accordé",
  },
  transfer: {
    label: "Montant du versement",
    info: "Montant du virement à effectuer pour cloturer ce paiement (montant disponible - avance, ou 0 si négatif)",
  },
  remainingAdvance: {
    label: "Encours",
    info: "Part de l'avance (déjà versée au bénéficiaire) qui n'a pas encore été attribuée (avance - montant disponible, si positif)",
  },
};

export default Amounts;
