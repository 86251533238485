import { Infer, enums } from "superstruct";
import Theme from "../Theme";

export const BeneficiaryPaymentStatusValues = [
  "locked", // Paiement à venir (le bénéficiaire ne peut pas encore fournir les documents)
  "available", // Paiement disponible à la saisie (en attente de docs du bénéficiaire) ==> En théorie, il n'y en a qu'1 seul à l'instant T (prévoir X, mais pas 8000 non plus)
  "under-analyse", // Paiement en cours d'analyse, décision à venir
  "ended", // Paiement terminé (virement émi)
] as const;

export const BeneficiaryPaymentStatusSchema = enums(
  BeneficiaryPaymentStatusValues,
);

export const BeneficiaryPaymentStatusLabels: Record<
  BeneficiaryPaymentStatus,
  string
> = {
  locked: "A venir",
  available: "En attente des documents",
  "under-analyse": "En cours d'instruction",
  ended: "Effectué",
};

export const BeneficiaryPaymentStatusColors: Record<
  BeneficiaryPaymentStatus,
  string
> = {
  locked: "grey",
  available: "#c89400",
  "under-analyse": Theme.color,
  ended: "grey",
};

export type BeneficiaryPaymentStatus = Infer<
  typeof BeneficiaryPaymentStatusSchema
>;
