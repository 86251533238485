import { Fragment } from "react";
import { ProjectInstructor } from "@hpo/client/models/Project";
import Spacer from "@hpo/client/utilities/Spacer";
import { getProjectLabel } from "@hpo/client/utilities/helpers/ProjectHelper";
import List, { ListItem } from "@hpo/client/utilities/List";
import T from "@hpo/client/components/text/Text";
import { useAssistance } from "@hpo/client/navigators/RootNavigator/services/Assistance";

type ProjectTabMainProps = {
  project: ProjectInstructor;
  onReload: () => unknown;
};

export default function ProjectTabMain(props: ProjectTabMainProps) {
  const { project } = props;
  const assistance = useAssistance();

  assistance.useVisibilityDecision(true);

  const identityData = [
    {
      label: "Appel à projets",
      value: getProjectLabel(project),
    },
    {
      label: "Raison sociale de la structure demandeuse",
      value: project.organization.businessName,
    },
    {
      label: "Statut juridique",
      value: project.organization.type,
    },
    {
      label: "NOM/Prénoms du représentant légal",
      value: project.organization.legalRepresentativeName,
    },
    {
      label: "Fonction du représentant légal",
      value: project.organization.legalRepresentativePosition,
    },
    {
      label: "N° SIRET",
      value: project.organization.legalId,
    },
    {
      label: "Adresse postale",
      value: project.organization.address.street,
    },
    {
      label: "Code postal",
      value: project.organization.address.postalCode,
    },
    {
      label: "Commune",
      value: project.organization.address.town,
    },
    {
      label: "Nom / Prénoms du référent dossier HPO",
      value: project.contactName,
    },
    {
      label: "Téléphone fixe",
      value: project.contactPhoneNumber,
    },
    {
      label: "Téléphone mobile",
      value: project.contactMobilePhoneNumber,
    },
    {
      label: "Mots-clés (5 au maximum)",
      value: project.keywords.join(", "),
    },
    {
      label: "Résumé succinct du projet (5 lignes maximum)",
      value: project.summary,
    },
  ];

  return (
    <Fragment>
      <h2>Identification du porteur de projet</h2>
      <Spacer />
      <List
        data={identityData}
        renderItem={(c) => (
          <ListItem label={c.label} right={<T>{c.value}</T>} />
        )}
      />
      <Spacer />
      <h2>Engagements et attestations du porteur de projet</h2>
      <Spacer />
      <List
        data={project.commitments}
        renderItem={(c) => <ListItem label={c.label} />}
      />
    </Fragment>
  );
}
