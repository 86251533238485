import { round } from "lodash";

class Unit {
  constructor(
    readonly label: string,
    private _display: (v: number) => string,
    private _parse: (s: string) => number,
    readonly factor: number = 1,
    readonly decimals: number = 0,
  ) {}

  display(v: number | null, ifNull: string = "") {
    return v === null ? ifNull : this._display(v);
  }

  parse(v: string) {
    return this._parse(v);
  }
}

const Units = {
  euro: new Unit(
    "Euro",
    (v) => {
      return new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: v === round(v) ? 0 : 2,
        maximumFractionDigits: 2,
      }).format(v);
    },

    (v) => {
      const nb = parseFloat(v);
      if (isNaN(nb)) throw new Error("Bad euro value");
      return round(nb, 2);
    },
    1,
    2,
  ),
  percentage: new Unit(
    "Pourcentage",
    (v) => round(v * 100, 2) + "%",
    (v) => {
      const nb = parseFloat(v);
      if (isNaN(nb)) throw new Error("Bad percentage");
      return round(nb / 100, 4);
    },
    100,
    4,
  ),
  other: new Unit(
    "Autre",
    (v) => {
      return new Intl.NumberFormat("fr-FR", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 3,
      }).format(v);
    },
    (v) => {
      const nb = parseFloat(v);
      if (isNaN(nb)) throw new Error("Bad value (other unit)");
      return round(nb, 3);
    },
    1,
    3,
  ),
};

export type UnitName = keyof typeof Units;
export const UnitNamesValues = Object.keys(Units) as Array<UnitName>;

export default Units;
