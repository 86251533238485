import { Fragment, useState } from "react";
import { useServerSdk } from "../../RootNavigator/services/ServerSdk";
import { MyProjectBaseProps } from ".";
import TextField from "@hpo/client/utilities/fields/TextField";
import Spacer from "@hpo/client/utilities/Spacer";
import { DescriptionBeneficiary } from "@hpo/client/models/Description";
import Button from "@hpo/client/components/Button";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import Toasting from "@hpo/client/components/Toasting";
import ErrorToast from "@hpo/client/utilities/ErrorToast";

type MyProjectDescriptionsProps = MyProjectBaseProps;

export default function MyProjectDescriptions(
  props: MyProjectDescriptionsProps,
) {
  return (
    <Fragment>
      {props.project.descriptions.map((description) => {
        return (
          <Fragment key={description.id}>
            <DescriptionForm description={description} {...props} />
            <Spacer />
          </Fragment>
        );
      })}
    </Fragment>
  );
}

type DescriptionFormProps = MyProjectBaseProps & {
  description: DescriptionBeneficiary;
};

function DescriptionForm(props: DescriptionFormProps) {
  const server = useServerSdk();

  const { description, project, onRefresh } = props;

  const [value, setValue] = useState<string | null>(description.value);

  // TODO key isn't saved in database for now
  // const descriptionDescriptor = useMemo(() => {
  //   return getDescriptionByKey(props.description)
  // },[])

  const [saveDescription, savingStatus] = useSubmitCallback(async () => {
    await server.updateDescription(project.id, description.id, value);
    Toasting.success("Succès", "Description mise à jour");
    onRefresh();
  }, [value, project.id, description.id]);

  return (
    <Fragment>
      <ErrorToast error={savingStatus.error} />
      <TextField
        textArea
        label={description.label}
        value={value || ""}
        onChange={(value) => setValue(value)}
        // help={description.help}
        required
      />
      <Spacer scale={0.5} />
      <Button
        label="Sauvegarder"
        onClick={saveDescription}
        loading={savingStatus.running}
        disabled={value === description.value}
      />
    </Fragment>
  );
}
