export type LocalFilePointer = { type: "local-file"; hash: string; file: File }; // Locally selected file (not uploaded yet)
export type UploadedFilePointer = { type: "uploaded-file"; jwt: string }; // File uploaded to the server, but not linked to any ressource (not confirmed)
export type ConfirmerFilePointer = { type: "confirmed-file"; url: string }; // File confirmed, linked to a ressource (URL)
export type FilePointer =
  | LocalFilePointer
  | UploadedFilePointer
  | ConfirmerFilePointer;

import BMF from "browser-md5-file";

function getFileHash(file: File) {
  return new Promise<string>((res, rej) => {
    const bmf = new BMF();
    bmf.md5(file, (err: unknown, md5: string | null) => {
      if (md5) res(md5);
      else rej(err || new Error("Cannot de file hash"));
    });
  });
}

const cache: Record<string, LocalFilePointer> = {};

export async function createLocalFilePointer(
  file: File,
): Promise<LocalFilePointer> {
  const hash = await getFileHash(file);
  const pointer: LocalFilePointer = { type: "local-file", hash, file };
  cache[hash] = pointer;
  return pointer;
}

export function toFilePointer(s: string | FilePointer): FilePointer {
  if (typeof s === "string") return stringToFilePointer(s);
  else return s;
}

export function stringToFilePointer(s: string): FilePointer {
  try {
    new URL(s);
    return { type: "confirmed-file", url: s };
  } catch (e) {
    if (s.length === 32) {
      const cached = cache[s] as LocalFilePointer | undefined;
      if (!cached) throw new Error("Unknown hash in LocalFilePointer cache");
      return cached;
    }
    return { type: "uploaded-file", jwt: s };
  }
}

export function filePointerToString(s: FilePointer): string {
  if (s.type === "confirmed-file") return s.url;
  else if (s.type === "uploaded-file") return s.jwt;
  else if (s.type === "local-file") return s.hash;
  else throw new Error("Cannot create string from this type of pointer");
}

export function isSameFilePointer(p1: FilePointer, p2: FilePointer) {
  if (p1.type === "local-file" && p2.type === "local-file") {
    return p1.hash === p2.hash;
  } else if (p1.type === "uploaded-file" && p2.type === "uploaded-file") {
    return p1.jwt === p2.jwt;
  } else if (p1.type === "confirmed-file" && p2.type === "confirmed-file") {
    return p1.url === p2.url;
  } else {
    return false;
  }
}
