import { Fragment, useState } from "react";
import { cloneDeep } from "lodash";
import { useServerSdk } from "../../RootNavigator/services/ServerSdk";
import { MyProjectBaseProps } from ".";
import DataTable, { DataTableColumn } from "@hpo/client/components/DataTable";
import SwitchField from "@hpo/client/utilities/fields/SwitchField";
import { ComplianceBeneficiary } from "@hpo/client/models/Compliance";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import Toasting from "@hpo/client/components/Toasting";
import ErrorToast from "@hpo/client/utilities/ErrorToast";

type MyProjectCompliancesProps = MyProjectBaseProps;

export default function MyProjectCompliances(props: MyProjectCompliancesProps) {
  const { project, onRefresh } = props;

  const server = useServerSdk();

  const [compliances, setCompliances] = useState<ComplianceBeneficiary[]>(
    project.compliances,
  );

  function mustBeOn(value: boolean) {
    if (value === false) return "Ce champ n'a pas été coché";
    return null;
  }

  const [saveCommitment, savingStatus] = useSubmitCallback(
    async (complianceId: string, validated: boolean) => {
      await server.updateCompliance(project.id, complianceId, validated);
      Toasting.success("Succès", "Elligibilité mise à jour");
      onRefresh();
    },
    [],
  );

  const setCompliancesProp = (
    compliance: ComplianceBeneficiary,
    newValue: boolean,
  ) => {
    void saveCommitment(compliance.id, newValue);

    setCompliances((compliances) => {
      const newCompliances = cloneDeep(compliances);

      const index = newCompliances.findIndex((c) => c.id === compliance.id);

      newCompliances[index] = {
        ...newCompliances[index],
        acceptedAt: newValue ? new Date() : null,
      };

      return newCompliances;
    });
  };

  const columns: Array<DataTableColumn<ComplianceBeneficiary>> = [
    {
      field: "label",
      header: "Libellé",
    },
    {
      body: (compliance: ComplianceBeneficiary) => {
        return (
          <SwitchField
            value={!!compliance.acceptedAt}
            onChange={(value) => setCompliancesProp(compliance, value)}
            errorMessage={mustBeOn}
          />
        );
      },
    },
  ];

  return (
    <Fragment>
      <ErrorToast error={savingStatus.error} />
      <DataTable columns={columns} value={compliances} />
    </Fragment>
  );
}
