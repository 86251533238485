import UrlPattern from "url-pattern";

export class Route<
  Params extends Record<string, string> | void = void,
  ParentsParams extends Record<string, string> | void = void,
> {
  constructor(
    private path: string,
    private parent?: Route<
      Record<string, string> | void,
      Record<string, string> | void
    >,
  ) {}

  getPath() {
    return this.path;
  }

  getRootPath(): string {
    if (this.parent) return this.parent.getRootPath() + this.getPath();
    return this.path;
  }

  getUrl(params: Params): string {
    const pattern = new UrlPattern(this.getPath());
    return pattern.stringify(params);
  }

  getRootUrl(params: MergeParams<Params, ParentsParams>): string {
    const pattern = new UrlPattern(this.getRootPath());
    return pattern.stringify(params);
  }

  matchesRootUrl(url: string) {
    const pattern = new UrlPattern(this.getRootPath());
    const match = pattern.match(url);
    return match !== null;
  }

  child<ChildParams extends Record<string, string> | void = void>(
    path: string,
  ) {
    return new Route<ChildParams, MergeParams<Params, ParentsParams>>(
      path,
      this,
    );
  }
}

export type RouteParams<R extends Route<any, any>> =
  R extends Route<infer Params, infer ParentsParams>
    ? MergeParams<Params, ParentsParams>
    : unknown;

export type MergeParams<
  Params extends Record<string, string> | void = void,
  ParentsParams extends Record<string, string> | void = void,
> =
  Params extends Record<string, string>
    ? ParentsParams extends Record<string, string>
      ? Params & ParentsParams
      : Params
    : ParentsParams;

// type MergeParams<
//   Params extends Record<string, string> | void = void,
//   Parent extends Route<any, any> | void = void,
// > =
//   Params extends Record<string, string>
//     ? Parent extends Route<infer ParentParams, infer ParentParent>
//       ? ParentParams extends Record<string, string>
//         ? Params & MergeParams<ParentParams, ParentParent>
//         : Params
//       : Params
//     : void;

// Accueil
export const homeRoute = new Route("/");

// Projects
export const projectsRoute = new Route("/projets");
export const projectRoute = projectsRoute.child<{ id: string }>("/:id");

// Mes projets
export const myProjectsRoute = new Route("/mes-projets");
export const newProjectRoute = myProjectsRoute.child("/nouveau");
export const myProjectRoute = myProjectsRoute.child<{ projectId: string }>(
  "/:projectId",
);

// Conventions
export const conventionsRoute = new Route("/conventions");
export const conventionCreationRoute = conventionsRoute.child("/new");
export const conventionRoute = conventionsRoute.child<{ conventionId: string }>(
  "/:conventionId",
);
export const conventionPaymentRoute = conventionRoute.child<{
  paymentId: string;
}>("/paiements/:paymentId");

// Payments
export const paymentsRoute = new Route("/paiements");

// MyPayments
export const myPaymentsRoute = new Route("/mes-paiements");
export const myPaymentRoute = myPaymentsRoute.child<{ paymentId: string }>(
  "/:paymentId",
);

export const managementRoute = new Route("/gestion-plateforme");

export const settingsRoute = new Route("/parametres");
export const errorRoute = new Route("/test-erreur");

/** @deprecated : Use route.getUrl() instead */
export function getUrl(path: string, params?: Record<string, string>): string;
/** @deprecated : Use route.getUrl() instead */
export function getUrl<
  Params extends Record<string, string> | void = void,
  ParentParams extends Record<string, string> | void = void,
>(
  route: Route<Params, ParentParams>,
  params?: MergeParams<Params, ParentParams>,
): string;
export function getUrl(input: string | Route, params?: any) {
  const route =
    typeof input === "string"
      ? new Route<Record<string, string>>(input)
      : input;
  return route.getRootUrl(params);
}
