import { Panel as PRPanel, PanelHeaderTemplateOptions as PRPanelHeaderTemplateOptions } from "primereact/panel";
import { CSSProperties, ReactNode, useCallback } from "react";
import { SPACING_BASE } from "../utilities/Spacer";
import { Interpolation, Theme } from "@emotion/react";

type PanelProps = {
  header: string;
  padding?: "none" | "small" | "medium";
  headerRight?: ReactNode;
  children: ReactNode;
  css?: Interpolation<Theme>;
  headerCSS?: CSSProperties;
  contentCSS?: CSSProperties;
};

export default function Panel(props: PanelProps) {
  const { padding, header, headerRight, ...panelProps } = props;

  const paddingValue = padding === "none" ? 0 : padding === "small" ? SPACING_BASE / 2 : SPACING_BASE;

  const headerTemplate = useCallback(
    (options: PRPanelHeaderTemplateOptions) => {
      return (
        <div className={options.className} style={{ display: "flex" }}>
          <div css={{ display: "flex", alignItems: "center" }}>
            <span css={{ fontWeight: "bold" }}>{header}</span>
          </div>
          <div>{headerRight}</div>
        </div>
      );
    },
    [props],
  );

  return (
    <PRPanel
      {...panelProps}
      className="m-0"
      headerTemplate={headerTemplate}
      pt={{
        header: {
          style: {
            padding: paddingValue,
          },
        },
        content: {
          style: {
            padding: paddingValue,
          },
        },
      }}
    />
  );
}
