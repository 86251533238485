import { css } from "@emotion/react";
import { PropsWithChildren } from "react";

type ColumnsProps = PropsWithChildren<{ columns?: number }>;

export default function Columns(props: ColumnsProps) {
  const { columns = 2 } = props;
  const containerCss = css({
    display: "grid",
    gridTemplateColumns: `repeat(${columns}, minmax(0, 1fr))`,
    gap: 20,
    alignItems: "start",
  });
  return <div css={containerCss}>{props.children}</div>;
}
