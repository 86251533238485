import { PropsWithChildren } from "react";
import { css } from "@emotion/react";
import T from "../components/text/Text";
import Theme from "./Theme";

type BadgeProps = PropsWithChildren<{
  color?: string;
}>;

export default function Badge(props: BadgeProps) {
  const { children, color = Theme.color } = props;
  const containerCss = css({
    background: color,
    color: "white",
    paddingLeft: 6,
    paddingRight: 6,
    paddingBottom: 4,
    paddingTop: 4,
    borderRadius: 6,
  });

  return (
    <div css={containerCss}>
      <T style="badge" oneLine>
        {children}
      </T>
    </div>
  );
}
