import { Fragment, useState } from "react";
import { useServerSdk } from "../../RootNavigator/services/ServerSdk";
import Button from "@hpo/client/components/Button";
import SideBar from "@hpo/client/components/SideBar";
import Toasting from "@hpo/client/components/Toasting";
import TextTitleMinor from "@hpo/client/components/text/TextTitleMinor";
import { UserAccess } from "@hpo/client/models/User";
import Spacer from "@hpo/client/utilities/Spacer";
import Role, { RoleValues } from "@hpo/client/utilities/enums/Role";
import MultiSelectField from "@hpo/client/utilities/fields/MultiSelectField";
import TextField from "@hpo/client/utilities/fields/TextField";
import UserHelper, {
  getRoleLabel,
} from "@hpo/client/utilities/helpers/UserHelper";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import MessageException from "@hpo/client/utilities/errors/MessageException";
import ErrorToast from "@hpo/client/utilities/ErrorToast";
import Divider from "@hpo/client/components/Divider";
import T from "@hpo/client/components/text/Text";
import {
  Organization,
  OrganizationSummary,
} from "@hpo/client/models/Organization";
import DropDownField from "@hpo/client/utilities/fields/DropDownField";

type UserSideBarProps = {
  user: UserAccess | null;
  organizations: Array<Organization>;
  visible: boolean;
  onDone: (newUser: string | null) => unknown;
};

export default function UserSideBar(props: UserSideBarProps) {
  const { user, organizations, visible, onDone } = props;
  const server = useServerSdk();

  const [firstName, setFirstName] = useState<string | null>(
    user ? user.firstName : null,
  );
  const [lastName, setLastName] = useState<string | null>(
    user ? user.lastName : null,
  );
  const [email, setEmail] = useState<string | null>(user ? user.email : null);
  const [org, setOrg] = useState<OrganizationSummary | null>(
    user ? user.organization : null,
  );
  const [roles, setRoles] = useState<Array<Role>>(user ? user.roles : []);

  const [saveUser, userSave] = useSubmitCallback(async () => {
    if (!email || !firstName || !lastName || roles.length === 0) {
      throw new MessageException(
        "Vous devez saisir un prénom, un nom, un email valide et sélectionner au moins un rôle",
        null,
      );
    }
    if (user) {
      await server.updateUser(user.id, {
        firstName,
        lastName,
        roles,
        organization: org ? org.id : null,
      });
      Toasting.success("Succès", "L'utilisateur a bien été modifié.");
      onDone(null);
    } else {
      const id = await server.createUser({
        email,
        firstName,
        lastName,
        roles,
        organization: org ? org.id : null,
      });
      Toasting.success("Succès", "L'utilisateur a bien été créé.");
      onDone(id);
    }
  }, [firstName, lastName, email, org, roles, onDone]);

  return (
    <SideBar
      visible={visible}
      onHide={() => onDone(null)}
      header={
        <TextTitleMinor>
          {user ? UserHelper.getUserName(user) : "Nouvel utilisateur"}
        </TextTitleMinor>
      }
    >
      <ErrorToast error={userSave.error} />
      <TextField
        required
        id="firstName"
        label="Prénom"
        value={firstName}
        onChange={setFirstName}
      />
      <Spacer />
      <TextField
        required
        id="lastName"
        label="Nom de famille"
        value={lastName}
        onChange={setLastName}
      />
      <Spacer />
      <TextField
        required
        disabled={Boolean(user)}
        id="email"
        label="Email"
        value={email}
        onChange={setEmail}
      />
      <Spacer />
      <DropDownField
        id="org"
        label="Bénéficiaire"
        value={org}
        options={organizations}
        keyExtractor={(o) => o.id}
        renderOption={(o) => ({ label: o.businessName })}
        onChange={setOrg}
      />
      <Spacer />
      <MultiSelectField
        label="Rôles"
        value={roles}
        onChange={setRoles}
        options={[...RoleValues]}
        keyExtractor={(r) => r}
        renderOption={(r) => ({ label: getRoleLabel(r) })}
        required
      />
      <Spacer />
      <Button onClick={saveUser} label="Valider" loading={userSave.running} />
      {user ? (
        <PasswordManagement user={user} onDone={() => onDone(user.id)} />
      ) : null}
    </SideBar>
  );
}

type PasswordManagementProps = {
  user: UserAccess;
  onDone: () => void;
};

function PasswordManagement(props: PasswordManagementProps) {
  const { user, onDone } = props;
  const server = useServerSdk();

  const [definePassword, define] = useSubmitCallback(async () => {
    await server.updateUserPassword(user.id),
      Toasting.success(
        "C'est fait !",
        "L'utilisateur a reçu un email l'informant de la création de son compte. Cet email contient un mot de passe temporaire. L'utilisateur devra choisir un nouveau mot de passe personnel à sa première connexion.",
      );
    onDone();
  }, [user]);

  const [changePassword, change] = useSubmitCallback(async () => {
    await server.updateUserPassword(user.id);
    Toasting.success(
      "C'est fait !",
      "L'utilisateur a reçu un email contenant un nouveau mot de passe. Il devra le modifier à se première connexion.",
    );
  }, [user]);
  return (
    <div>
      <Spacer />
      <Divider />
      <Spacer />
      <T style="section">Accès à la plateforme</T>
      <Spacer />
      <T>
        {user.hasPassword
          ? "L'utilisateur peut se connecter à la plateforme. Il dispose d'un mot de passe qui lui a été attribué par la plateforme ou d'un d'un mot de passe qu'il a lui-même défini. Si l'utilisateur a oublié son mot de passe, vous pouvez en forcer le renouvellement. L'utilisateur recevra un nouveau mot de passe par email, qu'il devra changer à la première connexion."
          : "L'utilisateur n'a pas accès à la plateforme pour le moment. Vous pouvez lui permettre d'y accéder. Un email contenant les instructions de connexion et son mot de passe lui sera envoyé. Dès sa première connexion, l'utilisateur devra choisir un nouveau mot de passe."}
      </T>
      <Spacer />
      {!user.hasPassword ? (
        <Fragment>
          <Button
            onClick={definePassword}
            loading={define.running}
            label="Permettre l'accès à la plateforme"
          />
          <ErrorToast error={define.error} />
        </Fragment>
      ) : null}
      {user.hasPassword ? (
        <Fragment>
          <Button
            onClick={changePassword}
            loading={change.running}
            label="Changer le mot de passe de l'utilisateur"
          />
          <ErrorToast error={change.error} />
        </Fragment>
      ) : null}
    </div>
  );
}
