import { useState } from "react";
import { ProjectInstructor } from "@hpo/client/models/Project";
import FieldsForm from "@hpo/client/utilities/fields/FieldsForm";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import TextField from "@hpo/client/utilities/fields/TextField";
import Button from "@hpo/client/components/Button";
import ErrorToast from "@hpo/client/utilities/ErrorToast";
import Spacer from "@hpo/client/utilities/Spacer";
import { useServerSdk } from "@hpo/client/navigators/RootNavigator/services/ServerSdk";
import TextsField from "@hpo/client/utilities/fields/TextsField";
import Buttons from "@hpo/client/components/Buttons";
import { useAssistance } from "@hpo/client/navigators/RootNavigator/services/Assistance";

type ProjectDisplayProps = {
  project: ProjectInstructor;
  onReload: () => unknown;
};

export default function ProjectDisplay(props: ProjectDisplayProps) {
  const { project, onReload } = props;
  const server = useServerSdk();
  const assistance = useAssistance();
  assistance.useVisibilityDecision(true);
  const [label, setLabel] = useState<string | null>(project.label);
  const [periods, setPeriods] = useState<Array<string> | null>(project.periods);

  const [onSubmit, submission] = useSubmitCallback(async () => {
    if (!label) throw new Error("Label must be defined");
    if (!periods) throw new Error("Periods should not be null");
    await server.updateProject(project.id, { label, periods });
    await onReload();
  }, [label, periods, onReload]);

  return (
    <FieldsForm onSubmit={onSubmit}>
      <ErrorToast error={submission.error} />
      <TextField
        value={label}
        onChange={setLabel}
        label="Nom du projet"
        help={`70 caractères maximum`}
        maxLength={70}
        required
      />
      <Spacer />
      <TextsField
        value={periods}
        onChange={setPeriods}
        label="Périodes"
        required
      />
      <Spacer />
      <Buttons>
        <Button submit label="Valider" />
      </Buttons>
    </FieldsForm>
  );
}
