import { Fragment, useMemo, useState } from "react";
import { MyProjectBaseProps } from "..";
import ActionSideBar from "./ActionSideBar";
import ActionType from "@hpo/client/utilities/enums/ActionType";
import Button from "@hpo/client/components/Button";
import Spacer from "@hpo/client/utilities/Spacer";
import DataTable, { DataTableColumn } from "@hpo/client/components/DataTable";
import { Action } from "@hpo/client/models/Action";
import Units from "@hpo/client/utilities/Units";
import PageHeader from "@hpo/client/utilities/PageHeader";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import Dialog from "@hpo/client/components/Dialog";
import { useServerSdk } from "@hpo/client/navigators/RootNavigator/services/ServerSdk";
import Toasting from "@hpo/client/components/Toasting";

type MyProjectActionsProps = MyProjectBaseProps & {
  actionType: ActionType;
};

export default function MyProjectActions(props: MyProjectActionsProps) {
  const { project, onRefresh, actionType } = props;

  const server = useServerSdk();

  const [open, setOpen] = useState<string | "new" | null>(null);

  const actions = useMemo(() => {
    return project.actions.filter((action) => action.type === actionType);
  }, [project, actionType]);

  const columns: Array<DataTableColumn<Action>> = [
    {
      field: "label",
      header: "Libellé",
    },
    {
      field: "goal",
      header: "Objectif",
    },
    {
      header: "Coût",
      body: (action: Action) => {
        return Units.euro.display(action.cost);
      },
    },
    {
      field: "means",
      header: "Moyens",
    },
    {
      field: "planning",
      header: "Planning",
    },
  ];

  const onDone = () => {
    setOpen(null);
    onRefresh();
  };

  const [deleteAction, deletingStatus] = useSubmitCallback(
    async (action: Action) => {
      const confirm = await Dialog.confirm({
        message: "Cette action sera supprimée ce manière définitive",
      });

      if (!confirm) return;

      await server.deleteAction(project.id, action.id);

      Toasting.success("Succès", "Action supprimée");

      onRefresh();
    },
    [],
  );

  return (
    <Fragment>
      <PageHeader
        title={`Plan de ${actionType === "communication" ? "communication" : "transfert"}`}
        titleStyle="subtitle"
        right={
          <Button icon="plus" label="Ajouter" onClick={() => setOpen("new")} />
        }
      />
      <Spacer />
      <DataTable
        columns={columns}
        value={actions}
        actions={{
          onEditClick: (action: Action) => setOpen(action.id),
          onDeleteClick: deleteAction,
          deleteLoading: deletingStatus.running,
          editDisabled: deletingStatus.running,
        }}
      />
      {actions.map((action) => (
        <ActionSideBar
          key={action.id}
          project={project}
          action={action}
          actionType={actionType}
          visible={open === action.id}
          onDone={onDone}
        />
      ))}
      <ActionSideBar
        project={project}
        action={null}
        actionType={actionType}
        visible={open === "new"}
        onDone={onDone}
      />
    </Fragment>
  );
}
