import { useEffect, useRef } from "react";
import { Toast } from "primereact/toast";
import getErrorMessage from "./errors/getErrorMessage";

export type PromiseViewProps = {
  promise: Promise<unknown> | null;
  message?: string | null;
};

export default function PromiseToast(props: PromiseViewProps) {
  const { promise, message } = props;

  const ref = useRef<Promise<unknown> | null>(promise);
  ref.current = promise;
  const toast = useRef<Toast>(null);

  useEffect(() => {
    if (promise === null) return;
    promise.then(
      () => {
        if (ref.current !== promise) return;
        if (message === null) return;
        const text = message || "C'est fait !";
        toast.current?.show({ severity: "info", summary: text });
      },
      (err) => {
        console.log(err);
        if (ref.current !== promise) return;
        toast.current?.show({
          severity: "error",
          summary: getErrorMessage(err),
        });
      },
    );
  }, [promise, message]);

  return <Toast ref={toast} />;
}
