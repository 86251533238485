type Caster<Input, Output, Context> = {
  is: (err: unknown) => boolean;
  cast: (err: Input, context: Context) => Output;
};

/**
 * ErrorCaster allow to cast anything to a given error type
 */
export default class ErrorCaster<Output, Context = void> {
  private casters: Array<Caster<any, Output, Context>> = [];

  constructor(private isAlreadyOutputCheck: (err: unknown) => boolean) {}

  register<Input>(
    is: (err: unknown) => err is Input,
    cast: (err: Input, context: Context) => Output,
  ): void;
  register(
    is: (err: unknown) => boolean,
    cast: (err: unknown, context: Context) => Output,
  ): void;
  register<Input = unknown>(
    is: (err: unknown) => err is Input,
    cast: (err: Input, context: Context) => Output,
  ) {
    this.casters.push({ is, cast });
  }

  registerClass<T>(c: Class<T>, cast: (err: T, context: Context) => Output) {
    this.casters.push({ is: (err: unknown) => err instanceof c, cast });
  }

  cast(err: unknown, context: Context): Output | null {
    if (this.isAlreadyOutputCheck(err)) return err as Output;
    const match = this.casters.find((c) => c.is(err));
    if (match) return match.cast(err, context);
    else return null;
  }
}

type Class<T> = new (...args: any[]) => T;
