import { Infer, array, nullable, number, object, string } from "superstruct";
import { InvestigationDecisionSchema } from "../utilities/enums/InvestigationDecision";

// Notation

export const NotationSchema = object({
  id: string(),
  key: string(),
  scope: string(),
  criteria: string(),
  description: string(),
  value: nullable(number()),
  maximum: number(),
});

export type Notation = Infer<typeof NotationSchema>;

export const NotationCreationSchema = object({
  key: string(),
  value: number(),
});

export type NotationCreation = Infer<typeof NotationCreationSchema>;

// Investigation

export const InvestigationSchema = object({
  comment: string(),
  decision: InvestigationDecisionSchema,
  amount: number(),
});

export type Investigation = Infer<typeof InvestigationSchema>;

// InvestigationCreation

export const InvestigationCreationSchema = object({
  comment: string(),
  decision: InvestigationDecisionSchema,
  amount: number(),
  notations: array(NotationCreationSchema),
});

export type InvestigationCreation = Infer<typeof InvestigationCreationSchema>;
