import dayjs from "dayjs";
import { useServerSdk } from "../RootNavigator/services/ServerSdk";
import Button from "@hpo/client/components/Button";
import ContentLayout from "@hpo/client/components/layout/ContentLayout";
import Await from "@hpo/client/utilities/Await";
import List, { ListItem } from "@hpo/client/utilities/List";
import {
  conventionCreationRoute,
  conventionRoute,
  getUrl,
} from "@hpo/client/utilities/routes";
import useData from "@hpo/client/utilities/useData";
import smoothJoin from "@hpo/client/utilities/smoothJoin";

export default function ConventionsScreen() {
  const server = useServerSdk();

  const [data] = useData(() => server.getConventions(), []);

  return (
    <ContentLayout
      title="Conventions"
      actions={[
        <Button
          key="new"
          label="Nouvelle convention"
          to={getUrl(conventionCreationRoute)}
          icon="plus"
        />,
      ]}
    >
      <Await promise={data}>
        {(conventions) => (
          <List
            data={conventions}
            renderItem={(c) => (
              <ListItem
                label={`Convention pour ${smoothJoin(c.organizations.map((a) => a.businessName))}`}
                help={`N°${c.reference} en date du ${dayjs(c.date).format("LL")}`}
                to={getUrl(conventionRoute, { conventionId: c.id })}
              />
            )}
          />
        )}
      </Await>
    </ContentLayout>
  );
}
