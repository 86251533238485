import { sumBy } from "lodash";
import { Fragment } from "react";
import Intersperse from "@hpo/client/components/Intersperse";
import T from "@hpo/client/components/text/Text";
import { BeneficiaryAchievementEdition } from "@hpo/client/models/Achievement";
import { BeneficiaryPaymentDetails } from "@hpo/client/models/BeneficiaryPayment";
import List, { ListItem } from "@hpo/client/utilities/List";

import Spacer from "@hpo/client/utilities/Spacer";
import Stepper from "@hpo/client/utilities/Stepper2";
import { getProjectLabel } from "@hpo/client/utilities/helpers/ProjectHelper";
import Units from "@hpo/client/utilities/Units";

type PaymentSummaryProps = {
  payment: BeneficiaryPaymentDetails;
  requests: Array<BeneficiaryAchievementEdition>;
};

export default function PaymentReview(props: PaymentSummaryProps) {
  const { payment, requests } = props;

  const total = sumBy(requests, (r) => r.amount);

  return (
    <Stepper.Step
      id="validation"
      title="Relisez et validez votre demande"
      details="Vérifiez le montant demandé et validez votre demande."
    >
      <Intersperse between={() => <Spacer />}>
        <T>{`Vous demandez une subvention pour un montant total de ${Units.euro.display(total)}`}</T>
        {requests.length > 1 ? (
          <Fragment>
            <T>{`Ce montant est ainsi réparti :`}</T>
            <List
              data={requests}
              renderItem={(r) => {
                const achievment = payment.achievements.find(
                  (a) => a.id === r.id,
                );
                if (!achievment) throw new Error("No achievement");
                return (
                  <ListItem
                    intro="A destination du projet"
                    label={getProjectLabel(achievment.funding.project)}
                    right={<T>vous demandez {Units.euro.display(r.amount)}</T>}
                  />
                );
              }}
            ></List>
          </Fragment>
        ) : null}
        {payment.maximalAdvance !== null ? (
          <T>{`Vous avez déjà reçu ${Units.euro.display(payment.maximalAdvance)} par avance.`}</T>
        ) : null}
      </Intersperse>
    </Stepper.Step>
  );
}
