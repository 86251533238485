import {
  Infer,
  coerce,
  literal,
  mask,
  nullable,
  number,
  object,
  omit,
  string,
  union,
  unknown,
} from "superstruct";
import ExpenseType from "../utilities/enums/ExpenseType";
import { AnalysisSchema } from "./Analysis";

// Product

const ProductInstructorSchema = object({
  id: string(),
  type: literal(ExpenseType.Product),
  label: string(),
  provider: string(),
  quotationReference: string(),
  quotationDate: string(),
  quotationDetail: string(),
  quantity: number(),
  annualCost: number(),
  quotationTotalCost: number(),
  helpAmountPerYear: number(),
  helpAmountThreeYears: number(),
  proposal: nullable(number()),
  analysis: AnalysisSchema,
});

export type ProductInstructor = Infer<typeof ProductInstructorSchema>;

const ProductBeneficiarySchema = omit(ProductInstructorSchema, ["analysis"]);
export type ProductBeneficiary = Infer<typeof ProductBeneficiarySchema>;

const ProductCreationSchema = object({
  type: literal(ExpenseType.Product),
  label: string(),
  provider: string(),
  quotationReference: string(),
  quotationDate: string(),
  quotationDetail: string(),
  quantity: number(),
  annualCost: number(),
});

export type ProductCreation = Infer<typeof ProductCreationSchema>;

// Salary

const SalaryInstructorSchema = object({
  id: string(),
  type: literal(ExpenseType.Salary),
  label: string(),
  annualCost: number(),
  employeeName: string(),
  employeePosition: string(),
  affectation: number(),
  fullCostOneYear: number(),
  fullCostThreeYears: number(),
  annualCostCeiling: number(),
  eligibleHelpOneYear: number(),
  eligibleHelpThreeYears: number(),
  proposal: nullable(number()),
  analysis: AnalysisSchema,
});

export type SalaryInstructor = Infer<typeof SalaryInstructorSchema>;

const SalaryBeneficiarySchema = omit(SalaryInstructorSchema, ["analysis"]);
export type SalaryBeneficiary = Infer<typeof SalaryBeneficiarySchema>;

const SalaryCreationSchema = object({
  type: literal(ExpenseType.Salary),
  label: string(),
  annualCost: number(),
  employeeName: string(),
  employeePosition: string(),
  affectation: number(),
});

export type SalaryCreation = Infer<typeof SalaryCreationSchema>;

// Service

const ServiceInstructorSchema = object({
  id: string(),
  type: literal(ExpenseType.Service),
  label: string(),
  provider: string(),
  quotationReference: string(),
  quotationDate: string(),
  quotationDetail: string(),
  annualCost: number(),
  quotationTotalCost: number(),
  helpAmountPerYear: number(),
  helpAmountThreeYears: number(),
  proposal: nullable(number()),
  analysis: AnalysisSchema,
});

export type ServiceInstructor = Infer<typeof ServiceInstructorSchema>;

const ServiceBeneficiarySchema = omit(ServiceInstructorSchema, ["analysis"]);
export type ServiceBeneficiary = Infer<typeof ServiceBeneficiarySchema>;

export const ServiceCreationSchema = object({
  type: literal(ExpenseType.Service),
  label: string(),
  provider: string(),
  quotationReference: string(),
  quotationDate: string(),
  quotationDetail: string(),
  annualCost: number(),
});

export type ServiceCreation = Infer<typeof ServiceCreationSchema>;

// Expense

export const ExpenseInstructorSchema = coerce(
  union([
    ServiceInstructorSchema,
    SalaryInstructorSchema,
    ProductInstructorSchema,
  ]),
  unknown(),
  (v: unknown) => {
    try {
      return mask(v, ProductInstructorSchema);
    } catch (_err) {
      try {
        return mask(v, ServiceInstructorSchema);
      } catch (_err) {
        return mask(v, SalaryInstructorSchema);
      }
    }
  },
);

export type ExpenseInstructor = Infer<typeof ExpenseInstructorSchema>;

export const ExpenseBeneficiarySchema = coerce(
  union([
    ServiceBeneficiarySchema,
    SalaryBeneficiarySchema,
    ProductBeneficiarySchema,
  ]),
  unknown(),
  (v: unknown) => {
    try {
      return mask(v, ProductBeneficiarySchema);
    } catch (_err) {
      try {
        return mask(v, ServiceBeneficiarySchema);
      } catch (_err) {
        return mask(v, SalaryBeneficiarySchema);
      }
    }
  },
);

export type ExpenseBeneficiary = Infer<typeof ExpenseBeneficiarySchema>;

export const ExpenseCreationSchema = coerce(
  union([ServiceCreationSchema, SalaryCreationSchema, ProductCreationSchema]),
  unknown(),
  (v: unknown) => {
    try {
      return mask(v, ProductCreationSchema);
    } catch (_err) {
      try {
        return mask(v, ServiceCreationSchema);
      } catch (_err) {
        return mask(v, SalaryCreationSchema);
      }
    }
  },
);

export type ExpenseCreation = Infer<typeof ExpenseCreationSchema>;
