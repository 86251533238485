import { Fragment } from "react";
import { useServerSdk } from "../../RootNavigator/services/ServerSdk";
import { useAssistance } from "../../RootNavigator/services/Assistance";
import ProjectTabCommunication from "./tabs/ProjectTabCommunication";
import ProjectTabCompliances from "./tabs/ProjectTabCompliances";
import ProjectTabDescription from "./tabs/ProjectTabDescription";
import ProjectTabFiles from "./tabs/ProjectTabFiles";
import ProjectTabFunding from "./tabs/ProjectTabFunding";
import ProjectTabInvestigation from "./ProjectTabInvestigation";
import ProjectTabMain from "./tabs/ProjectTabMain";
import ProjectTabProducts from "./tabs/ProjectTabProducts";
import ProjectTabSalaries from "./tabs/ProjectTabSalaries";
import ProjectTabServices from "./tabs/ProjectTabServices";
import ProjectTabTransfer from "./tabs/ProjectTabTransfer";
import ProjectDisplay from "./tabs/ProjectDisplay";
import ProjectTabIndicators from "./tabs/ProjectTabIndicators";
import useData from "@hpo/client/utilities/useData";
import { getProjectLabel } from "@hpo/client/utilities/helpers/ProjectHelper";
import Tabs from "@hpo/client/utilities/Tabs";
import Spacer from "@hpo/client/utilities/Spacer";
import PageHeader from "@hpo/client/utilities/PageHeader";
import Page from "@hpo/client/utilities/Page";
import Badge from "@hpo/client/utilities/Badge";
import Await from "@hpo/client/utilities/Await";
import { ProjectInstructor } from "@hpo/client/models/Project";
import { projectRoute } from "@hpo/client/utilities/routes";
import { useRouteParam } from "@hpo/client/utilities/Navigation";
import {
  ProjectStatusColors,
  ProjectStatusLabels,
} from "@hpo/client/utilities/enums/ProjectStatus";
import { Tag } from "@hpo/client/models/Tag";

export default function ProjectScreen() {
  const server = useServerSdk();
  const id = useRouteParam(projectRoute, "id");

  const [req, data] = useData(async () => server.getProject(id), [id]);
  const [tags] = useData(async () => server.getTags(), []);

  return (
    <Fragment>
      <Await promise={req} hideReload>
        {(req) => (
          <Await promise={tags}>
            {(tags) => (
              <ProjectView
                project={req}
                onReload={data.reloadSilent}
                tags={tags}
              />
            )}
          </Await>
        )}
      </Await>
    </Fragment>
  );
}

type RequestViewProps = {
  project: ProjectInstructor;
  tags: Array<Tag>;
  onReload: () => unknown;
};

function ProjectView(props: RequestViewProps) {
  const { project } = props;

  const assistance = useAssistance();
  assistance.useChatWith([project.organization]);
  assistance.useVisibilityDecision(true);

  const annexesTabs = project.annexes.map((a) => {
    if (a.type === "a2_salary") {
      return (
        <Tabs.Tab id="a2_salary" title="A2 - Récap salaires" key={a.type}>
          <ProjectTabSalaries {...props} annex={a} />
        </Tabs.Tab>
      );
    } else if (a.type === "a2_product") {
      return (
        <Tabs.Tab
          id="a2_product"
          title="A2 - Récap devis (produits)"
          key={a.type}
        >
          <ProjectTabProducts {...props} annex={a} />
        </Tabs.Tab>
      );
    } else if (a.type === "a2_service") {
      return (
        <Tabs.Tab
          id="a2_service"
          title="A2 - Récap devis (services)"
          key={a.type}
        >
          <ProjectTabServices {...props} annex={a} />
        </Tabs.Tab>
      );
    } else if (a.type === "a3") {
      return (
        <Tabs.Tab id="a3" title="A3 - Plan de financement" key={a.type}>
          <ProjectTabFunding {...props} annex={a} />
        </Tabs.Tab>
      );
    } else if (a.type === "a4") {
      return (
        <Tabs.Tab id="a4" title="A4 - Plan de comm" key={a.type}>
          <ProjectTabCommunication {...props} annex={a} />
        </Tabs.Tab>
      );
    } else if (a.type === "a5") {
      return (
        <Tabs.Tab id="a5" title="A5 - Plan de transfert" key={a.type}>
          <ProjectTabTransfer {...props} annex={a} />
        </Tabs.Tab>
      );
    }
  }, []);

  return (
    <Page>
      <PageHeader
        title={getProjectLabel(props.project)}
        subtitle={props.project.organization.businessName}
        right={
          <Badge color={ProjectStatusColors[project.status]}>
            {ProjectStatusLabels[project.status]}
          </Badge>
        }
      />
      <Spacer />
      <Tabs>
        <Tabs.Tab id="identification" title="Demande">
          <ProjectTabMain {...props} />
        </Tabs.Tab>
        <Tabs.Tab id="compliances" title="Elligibilité">
          <ProjectTabCompliances {...props} />
        </Tabs.Tab>
        <Tabs.Tab id="files" title="Pièces jointes">
          <ProjectTabFiles {...props} />
        </Tabs.Tab>
        <Tabs.Tab id="a1-description" title="A1 - Description">
          <ProjectTabDescription {...props} />
        </Tabs.Tab>
        <Tabs.Tab id="a1-indicators" title="A1 - Indicateurs">
          <ProjectTabIndicators {...props} />
        </Tabs.Tab>
        {annexesTabs}
        <Tabs.Tab id="instruction" title="Instruction">
          <ProjectTabInvestigation {...props} />
        </Tabs.Tab>
        <Tabs.Tab id="display" title="Affichage">
          <ProjectDisplay project={project} onReload={props.onReload} />
        </Tabs.Tab>
      </Tabs>
    </Page>
  );
}
