import { Route, Switch } from "wouter";
import ConventionScreen from "../ConventionNavigator/ConventionScreen";
import ConventionPaymentScreen from "./PaymentScreen";
import { conventionPaymentRoute } from "@hpo/client/utilities/routes";

export default function ConventionNavigator() {
  return (
    <Switch>
      <Route path={"/"} component={ConventionScreen} />
      <Route
        path={conventionPaymentRoute.getPath()}
        component={ConventionPaymentScreen}
      />
    </Switch>
  );
}
