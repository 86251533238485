import {
  Infer,
  boolean,
  nullable,
  object,
  optional,
  string,
} from "superstruct";

// Analysis

export const AnalysisSchema = object({
  internalNote: optional(nullable(string())),
  comment: nullable(string()),
  decision: nullable(boolean()),
  skippable: boolean(),
});

export type Analysis = Infer<typeof AnalysisSchema>;
