import Program from "../models/Program";
import ProgramKey from "./enums/ProgramKey";

const Programs: Record<ProgramKey, Program> = {
  [ProgramKey.LutteCollective]: {
    key: ProgramKey.LutteCollective,
    label:
      "Aides en faveur de la lutte collective et de la protection sanitaire des filières animales et végétales",
    annexes: ["a2_product", "a3", "a4", "a5"],
  },
  [ProgramKey.EchangeConnaissance]: {
    key: ProgramKey.EchangeConnaissance,
    label:
      "Aides à l’échange de connaissances et aux actions d’information pour les filières identitaires et émergentes",
    annexes: ["a2_salary", "a3", "a4", "a5"],
  },
  [ProgramKey.PromotionProduits]: {
    key: ProgramKey.PromotionProduits,
    label:
      "Aides aux actions de promotion en faveur des produits agricoles pour les filières identitaires et émergentes",
    annexes: ["a2_service", "a3", "a4", "a5"],
  },
  [ProgramKey.Animation]: {
    key: ProgramKey.Animation,
    label: "Aides à l’animation dans le secteur agricole",
    annexes: ["a2_salary", "a2_service", "a3", "a4", "a5"],
  },
  [ProgramKey.AnimationChambre]: {
    key: ProgramKey.AnimationChambre,
    label: "Aides à l’animation dans le secteur agricole (chambre)",
    annexes: ["a2_salary", "a2_service", "a3", "a4", "a5"],
  },
  [ProgramKey.LutteCollectiveAgroeco]: {
    key: ProgramKey.LutteCollectiveAgroeco,
    label:
      "Aides en faveur de la lutte collective et de la protection sanitaire des filières animales et végétales par des méthodes de lutte agroécologiques et promotion de la Protection Biologique Intégrée",
    annexes: ["a2_service", "a2_product", "a3", "a4", "a5"],
  },
  [ProgramKey.SystemesQualite]: {
    key: ProgramKey.SystemesQualite,
    label:
      "Aides en faveur de la particpation des producteurs de produits agricoles à des systèmes de qualité",
    annexes: ["a2_service", "a2_product", "a3", "a4"],
  },
};

export default Programs;
