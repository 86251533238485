import { SerializedStyles, css } from "@emotion/react";

export type IconName =
  | "spinner"
  | "hourglass"
  | "plus"
  | "check"
  | "radio-on"
  | "radio-off"
  | "checkbox-on"
  | "checkbox-off"
  | "trash"
  | "eye"
  | "eye-off"
  | "close"
  | "file-pdf"
  | "image"
  | "file-word"
  | "file-excel"
  | "file"
  | "error"
  | "chevron"
  | "thumbs-up"
  | "thumbs-down"
  | "forbidden"
  | "hammer"
  | "arrow"
  | "arrow-corner"
  | "fast-forward"
  | "send"
  | "asterisk"
  | "dots"
  | "dots-v"
  | "help"
  | "table"
  | "pencil"
  | "external-link"
  | "search"
  | "message"
  | "tags";

const renamings: Partial<Record<IconName, string>> = {
  "radio-on": "check-circle",
  "radio-off": "circle",
  "checkbox-on": "check-square",
  "checkbox-off": "stop",
  "eye-off": "eye-slash",
  close: "times",
  error: "exclamation-circle",
  chevron: "chevron-up",
  "thumbs-up": "thumbs-up-fill",
  "thumbs-down": "thumbs-down-fill",
  arrow: "arrow-up",
  "arrow-corner": "arrow-up-right",
  dots: "ellipsis-h",
  "dots-v": "ellipsis-v",
  help: "info-circle",
  message: "comment",
};

export type IconProps = {
  name: IconName;
  color?: string;
  size?: number;
  rotate?: number;
  opacity?: number;
  additionalCss?: SerializedStyles;
};

// https://primereact.org/icons/
export default function Icon(props: IconProps) {
  const { color, size, rotate = 0, opacity = 1 } = props;
  const name = renamings[props.name] || props.name;
  return (
    <i
      className={`pi pi-${name}`}
      css={css(props.additionalCss, {
        display: "inline-block",
        transition: "all 150ms",
        fontSize: size || "inherit",
        color,
        opacity,
        transform: `rotate(${rotate}deg)`,
      })}
    />
  );
}
