import dayjs from "dayjs";
import fr from "dayjs/locale/fr";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import RelativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(LocalizedFormat);
dayjs.extend(RelativeTime);
dayjs.locale(fr);

export const SERVER_DATE_FORMAT: string = "YYYY-MM-DD";
export const FRONT_DATE_FORMAT: string = "DD/MM/YYYY";

export function getServerDateAsString(date: string): string {
  return dayjs(date, SERVER_DATE_FORMAT).format(FRONT_DATE_FORMAT);
}

const DateHelper = {
  getServerDateAsString,
};

export default DateHelper;
