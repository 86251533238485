import { rgba } from "polished";

const Theme = {
  color: "#1A7E4E",
  warning: "#c89400",
  error: "#8c0921",
  text: {
    normal: rgba("black", 0.8),
    fade: rgba("black", 0.5),
  },
  background: {
    loggedin: "#ECECEC",
    anonymous: "white",
  },
};

export default Theme;
