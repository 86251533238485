import { Fragment, ReactNode, useEffect, useState } from "react";
import ErrorView from "./ErrorView";
import CatchError from "./CatchError";

type AwaitProps<TValue> = {
  promise: TValue | Promise<TValue>;
  children: (value: TValue) => ReactNode;
  hideReload?: boolean;
};

export default function Await<TValue>(props: AwaitProps<TValue>) {
  return (
    <AwaitedView
      {...props}
      renderPending={() => <Fragment>Chargement...</Fragment>}
      renderRejected={(err) => <ErrorView error={err} />}
    />
  );
}

// AwaitedView

type AwaitedView<TValue> = {
  promise: TValue | Promise<TValue>;
  children: (value: TValue) => ReactNode;
  renderPending: () => ReactNode;
  renderRejected: (error: any) => ReactNode;
  hideReload?: boolean;
};

export function AwaitedView<TValue>(props: AwaitedView<TValue>) {
  const { promise, children, hideReload = false } = props;

  const promiseState = usePromiseState(promise, hideReload);

  const renderPending = props.renderPending;
  const renderRejected = props.renderRejected;

  if (promiseState) {
    if (promiseState.state === "pending") {
      return <Fragment>{renderPending()}</Fragment>;
    } else if (promiseState.state === "resolved") {
      return <CatchError>{children(promiseState.value)}</CatchError>;
    } else {
      return <Fragment>{renderRejected(promiseState.error)}</Fragment>;
    }
  } else {
    return <Fragment />;
  }
}

// usePromiseState

type PromiseState<TValue> =
  | { state: "pending" }
  | { state: "resolved"; value: TValue; reloading: boolean }
  | { state: "rejected"; error: unknown };

export function usePromiseState<TValue>(
  promise: TValue | Promise<TValue>,
  keepResult?: boolean,
) {
  const [state, setState] = useState<PromiseState<TValue>>(() =>
    promise instanceof Promise
      ? { state: "pending" }
      : { state: "resolved", value: promise, reloading: false },
  );

  useEffect(() => {
    if (promise instanceof Promise) {
      const i = setTimeout(() => {
        setState((current) => {
          if (keepResult && current && current.state === "resolved") {
            return { ...current, reloading: true };
          } else {
            return { state: "pending" };
          }
        });
      }, 0);
      promise.then(
        (value) => {
          clearTimeout(i);
          setState(() => ({
            state: "resolved",
            value,
            reloading: false,
          }));
        },
        (error) => {
          clearTimeout(i);
          setState({
            state: "rejected",
            error,
          });
        },
      );
    } else {
      setState(() => ({
        state: "resolved",
        value: promise,
        reloading: false,
      }));
    }
  }, [promise, keepResult]);

  return state;
}
