import { Fragment, useEffect, useMemo, useState } from "react";
import { uniq } from "lodash";
import { MyProjectBaseProps } from "..";
import T from "../../../../components/text/Text";
import IndicatorEditor from "./IndicatorEditor";
import Button from "@hpo/client/components/Button";
import {
  BeneficiaryIndicator,
  IndicatorPayload,
} from "@hpo/client/models/Indicator";
import { IndicatorTypeLabels } from "@hpo/client/utilities/enums/IndicatorType";
import { useServerSdk } from "@hpo/client/navigators/RootNavigator/services/ServerSdk";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import Table from "@hpo/client/navigators/ConventionNavigator/PaymentScreen/Table";
import WithHelp from "@hpo/client/navigators/RootNavigator/assistance/Help/WithHelp";
import Buttons from "@hpo/client/components/Buttons";
import SideBar from "@hpo/client/components/SideBar";
import PromiseToast from "@hpo/client/utilities/PromiseToast";
import Spacer from "@hpo/client/utilities/Spacer";
import Message from "@hpo/client/components/Message";
import Theme from "@hpo/client/utilities/Theme";
import Divider from "@hpo/client/components/Divider";
import Units from "@hpo/client/utilities/Units";
import getIndicatorTypesByProgramKey from "@hpo/client/utilities/getIndicatorTypesByProjectTypeKey";

export type IndicatorGoalData = {
  period: string;
  value: number;
};

type MyProjectIndicatorsProps = MyProjectBaseProps;

export default function MyProjectIndicators(props: MyProjectIndicatorsProps) {
  const { project, onRefresh } = props;

  const server = useServerSdk();

  const [draftId, setDraftId] = useState<number>(() => Math.random());
  const [selected, setSelected] = useState<string | null | true>(null);

  const [indicators, setIndicators] = useState<Array<BeneficiaryIndicator>>(
    project.indicators,
  );

  useEffect(() => setIndicators(project.indicators), [project.indicators]);

  const [onCreateIndicator, creation] = useSubmitCallback(
    async (payload: IndicatorPayload) => {
      await server.createMyIndicator(project.id, payload, indicators.length);
      await onRefresh();
      setSelected(null);
      setDraftId(Math.random());
    },
    [indicators.length],
  );

  const [onUpdateIndicator, update] = useSubmitCallback(
    async (id: string, payload: IndicatorPayload) => {
      await server.updateMyIndicator(project.id, id, payload);
      await onRefresh();
      setSelected(null);
    },
    [indicators.length],
  );

  const [onDeleteIndicator, deletion] = useSubmitCallback(
    async (id: string) => {
      await server.deleteMyIndicator(project.id, id);
      await onRefresh();
      setSelected(null);
    },
    [indicators.length],
  );

  const requiredIndicatorTypes = useMemo(
    () => getIndicatorTypesByProgramKey(project.type),
    [project.type],
  );

  const hasAllTypes = useMemo(() => {
    const filled = uniq(indicators.map((i) => i.type))
      .sort()
      .join(" ");
    const toFill = requiredIndicatorTypes.sort().join(" ");
    return filled === toFill;
  }, [indicators, requiredIndicatorTypes]);

  return (
    <WithHelp
      text={
        <Fragment>
          <T>
            Les indicateurs permettront de suivre l'évolution de votre projet et
            d'en évaluer la performance.
            <Spacer />
            Ils se répartissent en 4 catégories :
            <ul>
              <li>Les indicateur de réalisation</li>
              <li>Les indicateur de résultat</li>
              <li>Les indicateur de communication</li>
              <li>Les indicateur de transfert aux agriculteurs</li>
            </ul>
            {!hasAllTypes ? (
              <Fragment>
                <Spacer />
                <Message color={Theme.warning}>
                  Vous devez renseigner au moins 1 indicateur pour ces{" "}
                  {requiredIndicatorTypes.length} catégories :
                  <ul>
                    {requiredIndicatorTypes.map((type) => (
                      <li>{IndicatorTypeLabels[type]}</li>
                    ))}
                  </ul>
                </Message>
              </Fragment>
            ) : null}
            <Spacer />
            Lors des demandes de paiement, vous pourrez être ammené(e) à
            indiquer votre réalisation, au regard des objectifs que vous fixez
            ici.
            <Spacer />
            <Divider />
            <Spacer />
            Des indicateurs supplémentaires pourront être ajoutés par le
            Département. Lors des demandes de paiement, vous pourrez renseigner
            vos objectifs et réalisations sur ces indicateurs supplémentaires.
          </T>
        </Fragment>
      }
    >
      {indicators.length > 0 ? (
        <Table>
          <thead>
            <tr>
              <th>Libellé et catégorie</th>
              {project.periods.map((p) => (
                <th key={p}>
                  <T style="minor" oneLine>
                    Objectif pour
                  </T>{" "}
                  {p}
                </th>
              ))}
              <th></th>
            </tr>
          </thead>
          <tbody>
            {indicators.map((i) => (
              <tr key={i.id}>
                <td>
                  <T>{i.label}</T>
                  <br />
                  <T style="minor">{IndicatorTypeLabels[i.type]}</T>
                </td>
                {project.periods.map((p) => {
                  const goal = i.goals.find((g) => g.period === p);
                  if (!goal) return <td key={p}>-</td>;
                  else
                    return <td key={p}>{Units[i.unit].display(goal.value)}</td>;
                })}
                <td>
                  <Button
                    icon="pencil"
                    onClick={() => setSelected(i.id)}
                    style="discreet"
                  />
                  <SideBar
                    visible={selected === i.id}
                    onHide={() => setSelected(null)}
                  >
                    <IndicatorEditor
                      indicator={i}
                      onDone={(p) => onUpdateIndicator(i.id, p)}
                      onDelete={() => onDeleteIndicator(i.id)}
                      periods={project.periods}
                    />
                  </SideBar>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : null}
      <PromiseToast promise={creation.promise} message={"Indicateur ajouté"} />
      <PromiseToast
        promise={update.promise}
        message={"Indicateur mis à jour"}
      />
      <PromiseToast promise={deletion.promise} message={"Indicateur retiré"} />
      <Buttons>
        <Button
          label="Ajouter un indicateur"
          icon="plus"
          onClick={() => setSelected(true)}
        />
      </Buttons>
      <SideBar visible={selected === true} onHide={() => setSelected(null)}>
        <IndicatorEditor
          key={draftId}
          indicator={null}
          onDone={onCreateIndicator}
          periods={project.periods}
        />
      </SideBar>
    </WithHelp>
  );

  // return (
  //   <Fragment>
  //     <Intersperse between={() => <Spacer />}>
  //       {indicatorTypes.map((type) => (
  //         <IndicatorsByType
  //           key={type}
  //           project={project}
  //           label={IndicatorTypeLabels[type]}
  //           type={type}
  //           indicators={project.indicators.filter(
  //             (indicator) => indicator.type === type,
  //           )}
  //           onRefresh={onRefresh}
  //           openIndicator={openIndicator}
  //           setOpenIndicator={setOpenIndicator}
  //         />
  //       ))}
  //     </Intersperse>
  //   </Fragment>
  // );
}
