import AttachmentTemplate from "../enums/AttachmentTemplate";
import ProgramKey from "../enums/ProgramKey";

export type AttachmentDescriptor = {
  key: string;
  projectType: ProgramKey;
  template: AttachmentTemplate | null;
  label: string;
  required: boolean;
};

export function getAttachementDescriptorsByProjectType(
  projectType: ProgramKey,
) {
  return Attachments.filter((a) => a.projectType === projectType);
}

export function getAttachementDescriptor(key: string) {
  const a = Attachments.find((a) => a.key === key);
  if (!a) throw new Error("Attachement descriptor not found");
  return a;
}

const Attachments: Array<AttachmentDescriptor> = [
  {
    key: "eb565531",
    template: null,
    projectType: ProgramKey.EchangeConnaissance,
    label: "Conventions partenariales signées sur les actions proposées",
    required: true,
  },
  {
    key: "0637ffd1",
    template: null,
    projectType: ProgramKey.EchangeConnaissance,
    label:
      "Listing du personnel affecté aux actions techniques ainsi que temps passé sur le programme d’action en équivalent ETP",
    required: true,
  },
  {
    key: "f2b6893d",
    template: null,
    projectType: ProgramKey.EchangeConnaissance,
    label:
      "CV et fiches de postes nominatives de ces personnels avec le détail des missions directement liées aux actions techniques",
    required: true,
  },
  {
    key: "58cbc6bd",
    template: null,
    projectType: ProgramKey.EchangeConnaissance,
    label: "Statuts de la structure",
    required: true,
  },
  {
    key: "a6db1dc2",
    template: null,
    projectType: ProgramKey.EchangeConnaissance,
    label:
      "Attestations de régularité fiscale et sociale (un certificat ou une attestation prouvant que le porteur de projet est à jour de ses obligations fiscales et une attestation de fourniture des déclarations sociales et de paiement des cotisations et contributions de sécurité sociale)",
    required: true,
  },
  {
    key: "c6cc1f76",
    template: null,
    projectType: ProgramKey.EchangeConnaissance,
    label: "Deux derniers comptes de résultat et bilans comptables",
    required: true,
  },
  {
    key: "6d1f405e",
    template: null,
    projectType: ProgramKey.EchangeConnaissance,
    label: "Kbis ou avis SIRENE de moins de 3 mois",
    required: true,
  },
  {
    key: "7a1127ba",
    template: null,
    projectType: ProgramKey.EchangeConnaissance,
    label: "Relevé d’identité bancaire",
    required: true,
  },
  {
    key: "1d0b3e10",
    template: null,
    projectType: ProgramKey.LutteCollective,
    label: "Avis ou la décision du CROSPAV",
    required: true,
  },
  {
    key: "cd50accc",
    template: null,
    projectType: ProgramKey.LutteCollective,
    label: "Conventions partenariales signées sur les actions proposées",
    required: true,
  },
  {
    key: "9373cdb2",
    template: null,
    projectType: ProgramKey.LutteCollective,
    label:
      "Devis des prestations externes et des achats nécessaires à la réalisation des actions de lutte collective décrites plus haut",
    required: true,
  },
  {
    key: "7f78398d",
    template: null,
    projectType: ProgramKey.LutteCollective,
    label: "Statuts de la structure",
    required: true,
  },
  {
    key: "2bde50f3",
    template: null,
    projectType: ProgramKey.LutteCollective,
    label:
      "Attestations de régularité fiscale et sociale (un certificat ou une attestation prouvant que le porteur de projet est à jour de ses obligations fiscales et une attestation de fourniture des déclarations sociales et de paiement des cotisations et contributions de sécurité sociale)",
    required: true,
  },
  {
    key: "a9497522",
    template: null,
    projectType: ProgramKey.LutteCollective,
    label: "Deux derniers comptes de résultat et bilans comptables",
    required: true,
  },
  {
    key: "7bcf33ec",
    template: null,
    projectType: ProgramKey.LutteCollective,
    label: "Kbis ou avis SIRENE de moins de 3 mois",
    required: true,
  },
  {
    key: "5ff26be5",
    template: null,
    projectType: ProgramKey.LutteCollective,
    label: "Relevé d’identité bancaire",
    required: true,
  },
  {
    key: "21a0950b",
    template: null,
    projectType: ProgramKey.Animation,
    label:
      "Pour les 2 axes : Conventions partenariales signées sur les actions proposées.",
    required: true,
  },
  {
    key: "25ed1dcc",
    template: null,
    projectType: ProgramKey.Animation,
    label:
      "Pour les 2 axes : Listing du personnel affecté aux actions techniques ainsi que temps passé sur le programme d’action en équivalent ETP",
    required: true,
  },
  {
    key: "804f0320",
    template: null,
    projectType: ProgramKey.Animation,
    label:
      "Pour les 2 axes : CV et fiches de postes nominatives de ces personnels avec le détail des missions directement liées aux actions techniques",
    required: true,
  },
  {
    key: "7ab460b7",
    template: null,
    projectType: ProgramKey.Animation,
    label: "Pour les 2 axes : Le relevé d’identité bancaire",
    required: true,
  },
  {
    key: "0bb71090",
    template: null,
    projectType: ProgramKey.Animation,
    label: "Pour l'axe 1 : Les statuts de la structure",
    required: false,
  },
  {
    key: "a5df1601",
    template: null,
    projectType: ProgramKey.Animation,
    label:
      "Pour l'axe 1 : Attestations de régularité fiscale et sociale (un certificat ou une attestation prouvant que le porteur de projet est à jour de ses obligations fiscales et une attestation de fourniture des déclarations sociales et de paiement des cotisations et contributions de sécurité sociale)",
    required: false,
  },
  {
    key: "62f76141",
    template: null,
    projectType: ProgramKey.Animation,
    label:
      "Pour l'axe 1 : Deux derniers comptes de résultat et bilans comptables",
    required: false,
  },
  {
    key: "871de8fe",
    template: null,
    projectType: ProgramKey.Animation,
    label: "Pour l'axe 1 : Kbis ou avis SIRENE de moins de 3 mois",
    required: false,
  },
  {
    key: "8712k8fe",
    template: null,
    projectType: ProgramKey.Animation,
    label: "Pour l'axe 2 : Les statuts de la structure",
    required: false,
  },
  {
    key: "7058fcb1",
    template: null,
    projectType: ProgramKey.Animation,
    label:
      "Pour l'axe 2 : Attestations de régularité fiscale et sociale (un certificat ou une attestation prouvant que le porteur de projet est à jour de ses obligations fiscales et une attestation de fourniture des déclarations sociales et de paiement des cotisations et contributions de sécurité sociale)",
    required: false,
  },
  {
    key: "cbbae2c5",
    template: null,
    projectType: ProgramKey.Animation,
    label:
      "Pour l'axe 2 : Deux derniers comptes de résultat et bilans comptables",
    required: false,
  },
  {
    key: "e5ae2929",
    template: null,
    projectType: ProgramKey.Animation,
    label: "Pour l'axe 2 : Kbis ou avis SIRENE de moins de 3 mois",
    required: false,
  },
  {
    key: "32af0920",
    template: null,
    projectType: ProgramKey.PromotionProduits,
    label: "Conventions partenariales signées sur les actions proposées",
    required: true,
  },
  {
    key: "8ec51996",
    template: null,
    projectType: ProgramKey.PromotionProduits,
    label:
      "Devis des prestations externes et des achats nécessaires au fonctionnement et à l’organisation des actions décrites plus haut",
    required: true,
  },
  {
    key: "428f204a",
    template: null,
    projectType: ProgramKey.PromotionProduits,
    label: "Statuts de la structure",
    required: true,
  },
  {
    key: "6f19dab3",
    template: null,
    projectType: ProgramKey.PromotionProduits,
    label:
      "Attestations de régularité fiscale et sociale (un certificat ou une attestation prouvant que le porteur de projet est à jour de ses obligations fiscales et une attestation de fourniture des déclarations sociales et de paiement des cotisations et contributions de sécurité sociale)",
    required: true,
  },
  {
    key: "9411df95",
    template: null,
    projectType: ProgramKey.PromotionProduits,
    label: "Deux derniers comptes de résultat et bilans comptables",
    required: true,
  },
  {
    key: "5a90521e",
    template: null,
    projectType: ProgramKey.PromotionProduits,
    label: "Kbis ou avis SIRENE de moins de 3 mois",
    required: true,
  },
  {
    key: "248f5de4",
    template: null,
    projectType: ProgramKey.PromotionProduits,
    label: "Relevé d’identité bancaire",
    required: true,
  },
  {
    key: "411b0107",
    template: null,
    projectType: ProgramKey.AnimationChambre,
    label: "Conventions partenariales signées sur les actions proposées",
    required: true,
  },
  {
    key: "155e5b10",
    template: null,
    projectType: ProgramKey.AnimationChambre,
    label:
      "Listing du personnel affecté aux actions techniques ainsi que temps passé sur le programme d’action en équivalent ETP",
    required: true,
  },
  {
    key: "f3c4aa97",
    template: null,
    projectType: ProgramKey.AnimationChambre,
    label:
      "CV et fiches de postes nominatives de ces personnels avec le détail des missions directement liées aux actions techniques",
    required: true,
  },
  {
    key: "fe0a6442",
    template: null,
    projectType: ProgramKey.AnimationChambre,
    label: "Relevé d’identité bancaire",
    required: true,
  },
  {
    key: "4f97114c",
    template: null,
    projectType: ProgramKey.AnimationChambre,
    label:
      "Devis des prestations externes et des achats nécessaires au fonctionnement et à l’organisation des actions décrites plus haut",
    required: true,
  },

  {
    key: "4a5f1c4f",
    template: null,
    projectType: ProgramKey.LutteCollectiveAgroeco,
    label: "Les statuts de la structure",
    required: true,
  },
  {
    key: "f43e2217",
    template: null,
    projectType: ProgramKey.LutteCollectiveAgroeco,
    label:
      "La stratégie et le plan de développement de la structure à 4 ans avec objectifs chiffrés",
    required: true,
  },
  {
    key: "a34b3676",
    template: null,
    projectType: ProgramKey.LutteCollectiveAgroeco,
    label: "Le plan de lutte sanitaire collectif",
    required: true,
  },
  {
    key: "f576a233",
    template: null,
    projectType: ProgramKey.LutteCollectiveAgroeco,
    label:
      "Une note argumentée annexée au plan de lutte collectif démontrant le respect des cadres règlementaires nationaux en matière de lutte sanitaire collective et rassurer sur les perspectives sanitaires à long terme, après l’arrêt du financement par le Département",
    required: true,
  },
  {
    key: "2fd28947",
    template: null,
    projectType: ProgramKey.LutteCollectiveAgroeco,
    label: "L’avis ou la décision du CROPSAV",
    required: true,
  },
  {
    key: "5e059894",
    template: null,
    projectType: ProgramKey.LutteCollectiveAgroeco,
    label:
      "Le plan de financement justifiant la capacité financière de la structure à supporter la quote-part restant à charge",
    required: true,
  },
  {
    key: "8f114769",
    template: null,
    projectType: ProgramKey.LutteCollectiveAgroeco,
    label:
      "Le récapitulatif des dépenses prévues en cohérence avec le programme d’action",
    required: true,
  },
  {
    key: "bd3118ea",
    template: null,
    projectType: ProgramKey.LutteCollectiveAgroeco,
    label:
      "Le plan de communication mettant en avant l’intervention départementale",
    required: true,
  },
  {
    key: "8210c1bd",
    template: null,
    projectType: ProgramKey.LutteCollectiveAgroeco,
    label:
      "Le plan de promotion de la Protection Biologique Intégrée (PBI) et de transfert auprès des agriculteurs",
    required: true,
  },
  {
    key: "59d6d019",
    template: null,
    projectType: ProgramKey.LutteCollectiveAgroeco,
    label: "Les conventions partenariales signées sur les actions proposées.",
    required: true,
  },
  {
    key: "5a30cd14",
    template: null,
    projectType: ProgramKey.LutteCollectiveAgroeco,
    label:
      "Les devis des prestations externes et des achats nécessaires à la réalisation des actions de lutte collective décrites plus haut",
    required: true,
  },
  {
    key: "07d3ec44",
    template: null,
    projectType: ProgramKey.LutteCollectiveAgroeco,
    label:
      "Une note justificative permettant de démontrer que les moyens humains et matériels de la structure permettent de mener à bien les actions",
    required: true,
  },
  {
    key: "6b996f88",
    template: null,
    projectType: ProgramKey.LutteCollectiveAgroeco,
    label:
      "Les attestations de régularité fiscale et sociale (un certificat ou une attestation prouvant que le porteur de projet est à jour de ses obligations fiscales et une attestation de fourniture des déclarations sociales et de paiement des cotisations et contributions de sécurité sociale)",
    required: true,
  },
  {
    key: "0978b2f2",
    template: null,
    projectType: ProgramKey.LutteCollectiveAgroeco,
    label: "Les deux derniers comptes de résultat et bilans comptables",
    required: true,
  },
  {
    key: "e549b792",
    template: null,
    projectType: ProgramKey.LutteCollectiveAgroeco,
    label: "Le Kbis ou l'avis SIRENE de moins de 3 mois",
    required: true,
  },
  {
    key: "40f3942d",
    template: null,
    projectType: ProgramKey.LutteCollectiveAgroeco,
    label: "Le relevé d’identité bancaire",
    required: true,
  },
  {
    key: "73aa6b06",
    template: null,
    projectType: ProgramKey.SystemesQualite,
    label:
      "Les statuts de la structure démontant sa qualité d’organisme de gestion au sens des règlements régissant les systèmes de qualité",
    required: true,
  },
  {
    key: "7693dbcc",
    template: null,
    projectType: ProgramKey.SystemesQualite,
    label:
      "Une déclaration sur l’honneur que le montant total des aides de minimis ayant été obtenues, additionnées à celles demandées, n’excède pas 300 000 € sur une période de trois années glissantes",
    required: true,
  },
  {
    key: "5f5f38e7",
    template: null,
    projectType: ProgramKey.SystemesQualite,
    label:
      "La stratégie et le plan de développement de la structure à 4 ans avec objectifs chiffrés",
    required: true,
  },
  {
    key: "76b05310",
    template: null,
    projectType: ProgramKey.SystemesQualite,
    label:
      "Le récapitulatif des dépenses prévues en cohérence avec le programme d’actions",
    required: true,
  },
  {
    key: "628c9eb5",
    template: null,
    projectType: ProgramKey.SystemesQualite,
    label:
      "Le plan de communication mettant en avant l’intervention départementale",
    required: true,
  },
  {
    key: "3894e5f8",
    template: null,
    projectType: ProgramKey.SystemesQualite,
    label: "Le plan de financement du projet",
    required: true,
  },
  {
    key: "4dd6eec9",
    template: null,
    projectType: ProgramKey.SystemesQualite,
    label:
      "Les devis des prestations externes et des achats nécessaires à la réalisation des actions décrites plus haut",
    required: true,
  },
  {
    key: "ee4978c9",
    template: null,
    projectType: ProgramKey.SystemesQualite,
    label: "Les conventions partenariales signées sur les actions proposées",
    required: true,
  },
  {
    key: "da820588",
    template: null,
    projectType: ProgramKey.SystemesQualite,
    label:
      "Le contrat avec l’organisme en charge des mesures de contrôle obligatoire",
    required: true,
  },
  {
    key: "007805e2",
    template: null,
    projectType: ProgramKey.SystemesQualite,
    label:
      "Une note justificative permettant de démontrer que les moyens humains et matériels de la structure permettent de mener à bien les actions, et de faire vivre le système de qualité dans le temps (comité de gestion, animation des agriculteurs bénéficiaires, suivi des prestataires...)",
    required: true,
  },
  {
    key: "fe956629",
    template: null,
    projectType: ProgramKey.SystemesQualite,
    label:
      "Une note justificative permettant de démontrer l’intérêt et les retombées de la mise en place du système de qualité à long terme",
    required: true,
  },
  {
    key: "83ca7d1e",
    template: null,
    projectType: ProgramKey.SystemesQualite,
    label:
      "Les attestations de régularité fiscale et sociale (un certificat ou une attestation prouvant que le porteur de projet est à jour de ses obligations fiscales et une attestation de fourniture des déclarations sociales et de paiement des cotisations et contributions de sécurité sociale)",
    required: true,
  },
  {
    key: "f743a783",
    template: null,
    projectType: ProgramKey.SystemesQualite,
    label: "Les deux derniers comptes de résultat et bilans comptables",
    required: true,
  },
  {
    key: "577d8cb3",
    template: null,
    projectType: ProgramKey.SystemesQualite,
    label: "Le Kbis ou l'avis SIRENE de moins de 3 mois de moins de 3 mois",
    required: true,
  },
  {
    key: "45d96478",
    template: null,
    projectType: ProgramKey.SystemesQualite,
    label: "Le relevé d’identité bancaire",
    required: true,
  },
];
