import { SerializedStyles, css } from "@emotion/react";
import { PropsWithChildren } from "react";

export type TextStyleName =
  | "body"
  | "bold"
  | "title"
  | "subtitle"
  | "section"
  | "minor"
  | "badge";

export type TextConfig = Partial<{
  style: TextStyleName;
  tag: "h1" | "h2" | "h3" | "p" | "span" | "div";
  color: string;
  opacity: number;
  oneLine: boolean;
  /** @deprecated : Use align instead */
  center: boolean;
  align: "left" | "center" | "right" | "justify";
  display: "block" | "inline";
}>;

export type TextProps = PropsWithChildren<
  Partial<TextConfig> & {
    id?: string | null;
  }
>;

export const cssPerStyle: Record<TextStyleName, SerializedStyles> = {
  body: css({
    fontFamily: "Futura",
    fontSize: "1.2rem",
    fontWeight: 400,
  }),
  bold: css({
    fontFamily: "Futura",
    fontWeight: 600,
    fontSize: "1.2rem",
  }),
  title: css({
    fontFamily: "Futura",
    fontSize: "2rem",
    fontWeight: 600,
  }),
  subtitle: css({
    fontFamily: "Futura",
    fontWeight: 600,
    fontSize: "1.4rem",
  }),
  section: css({
    fontFamily: "Futura",
    fontWeight: 600,
    fontSize: "1.4rem",
  }),
  minor: css({
    fontFamily: "Futura",
    fontSize: "0.9rem",
    fontWeight: 400,
  }),
  badge: css({
    fontFamily: "Futura",
    fontWeight: 600,
    fontSize: "0.8rem",
    textTransform: "uppercase",
  }),
};

export default function T(props: TextProps) {
  const configCss = textCss(props);
  const Component = props.tag || "span";
  return (
    <Component css={configCss} id={props.id || undefined}>
      {props.children}
    </Component>
  );
}

export function textCss(config: TextConfig) {
  const baseCss = css({
    color: config.color,
    display: config.display,
  });
  const truncateCss = config.oneLine
    ? css({
        whiteSpace: "nowrap",
        width: "100%",
        display: "block",
        textOverflow: "ellipsis",
        overflow: "hidden",
      })
    : null;
  const alignStyle = config.center
    ? css({ textAlign: "center" })
    : config.align
      ? css({ textAlign: config.align })
      : css();
  return css(
    cssPerStyle[config.style || "body"],
    baseCss,
    truncateCss,
    alignStyle,
  );
}
