import { enums } from "superstruct";

enum ProgramKey {
  LutteCollective = "lutte_collective",
  EchangeConnaissance = "echanges_connaissances",
  PromotionProduits = "promotions_produits",
  Animation = "animation",
  AnimationChambre = "animation_chambre",
  LutteCollectiveAgroeco = "lutte_collective_agroeco",
  SystemesQualite = "systemes_qualite",
}

export const ProgramKeySchema = enums(Object.values(ProgramKey));

export const ProgramKeyValues = [
  ProgramKey.LutteCollective,
  ProgramKey.EchangeConnaissance,
  ProgramKey.PromotionProduits,
  ProgramKey.Animation,
  ProgramKey.AnimationChambre,
  ProgramKey.LutteCollectiveAgroeco,
  ProgramKey.SystemesQualite,
] as const;

export default ProgramKey;
