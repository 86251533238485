import IndicatorType from "./enums/IndicatorType";
import ProgramKey from "./enums/ProgramKey";
import Programs from "./Programs";

export default function getIndicatorTypesByProgramKey(key: ProgramKey) {
  const types: IndicatorType[] = [
    IndicatorType.Achievement,
    IndicatorType.Result,
  ];
  const annexes = Programs[key].annexes;
  if (annexes.find((annex) => annex === "a4")) {
    types.push(IndicatorType.Communication);
  }
  if (annexes.find((annex) => annex === "a5")) {
    types.push(IndicatorType.Transfert);
  }
  return types;
}
