import { enums } from "superstruct";

enum IndicatorOrigin {
  Beneficiary = "beneficiary",
  Instructor = "instructor",
}

export const IndicatorOriginValues = [
  IndicatorOrigin.Beneficiary,
  IndicatorOrigin.Instructor,
] as const;

export const IndicatorOriginLabels: Record<IndicatorOrigin, string> = {
  [IndicatorOrigin.Beneficiary]: "Déclaré par le bénéficiaire",
  [IndicatorOrigin.Instructor]: "Ajouté par l'instructeur",
};

export const IndicatorOriginSchema = enums(IndicatorOriginValues);

export default IndicatorOrigin;
