import { PropsWithChildren } from "react";
import Message from "../components/Message";
import T from "../components/text/Text";
import Theme from "./Theme";

type InfoBoxStyle = "info" | "warn" | "error";

const colorPerStyle: Record<InfoBoxStyle, string> = {
  info: Theme.color,
  warn: Theme.warning,
  error: Theme.error,
};

type InfoBoxProps = PropsWithChildren<{
  title: string;
  style?: "info" | "warn" | "error";
}>;

/** @deprecated : Use Message instead */
export default function InfoBox(props: InfoBoxProps) {
  const { style = "info" } = props;
  return (
    <Message color={colorPerStyle[style]}>
      <T style="bold">{props.title}</T>
      <br />
      {props.children}
    </Message>
  );
}
