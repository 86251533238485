import { Infer, object, string } from "superstruct";
import { legalIdSchema } from "../utilities/models/LegalId";
import { AddressSchema } from "./Address";

// Organization

export const OrganizationSchema = object({
  id: string(),
  businessName: string(),
  type: string(),
  legalRepresentativeName: string(),
  legalRepresentativePosition: string(),
  legalId: legalIdSchema,
  address: AddressSchema,
});

export type Organization = Infer<typeof OrganizationSchema>;

// OrganizationSummary

export const OrganizationSummarySchema = object({
  id: string(),
  businessName: string(),
});

export type OrganizationSummary = Infer<typeof OrganizationSummarySchema>;

// OrganizationCreation

export const OrganizationCreationSchema = object({
  businessName: string(),
  type: string(),
  legalRepresentativeName: string(),
  legalRepresentativePosition: string(),
  legalId: legalIdSchema,
  address: AddressSchema,
});

export type OrganizationCreation = Infer<typeof OrganizationCreationSchema>;
