type SmoothJoinOptions = {
  joiner?: string;
  lastJoiner?: string;
};

export default function smoothJoin(
  values: Array<string>,
  options: SmoothJoinOptions = {},
) {
  const { joiner = ", ", lastJoiner = " et " } = options;
  if (values.length === 0) return "";
  else if (values.length === 1) return values[0];
  else if (values.length === 2) return values.join(lastJoiner);
  else {
    return [
      values.slice(0, values.length - 1).join(joiner),
      values[values.length - 1],
    ].join(lastJoiner);
  }
}
