import { groupBy } from "lodash";
import ExpenseType from "../enums/ExpenseType";
import {
  ExpenseInstructor,
  ExpenseBeneficiary,
  ProductInstructor,
  ProductBeneficiary,
  SalaryInstructor,
  SalaryBeneficiary,
  ServiceInstructor,
  ServiceBeneficiary,
} from "@hpo/client/models/Expense";

// /** @todo : Multiplier les signatures de la fonction, plutot que de dupliquer le code
//  * Voir premier exemple sur https://dev.to/zirkelc/how-to-overload-functions-in-typescript-22c3
//  */

// export function expenseIsSalary(
//   expense: ExpenseBeneficiary,
// ): expense is SalaryBeneficiary;
// export function expenseIsSalary(
//   expense: ExpenseInstructor,
// ): expense is SalaryInstructor;
// export function expenseIsSalary(
//   expense: ExpenseBeneficiary | ExpenseInstructor,
// ): expense is SalaryBeneficiary | SalaryInstructor {
//   return expense.type === EXPENSE_TYPE.SALARY;
// }

// export function expenseBeneficiaryIsProduct(
//   expense: ExpenseBeneficiary,
// ): expense is ProductBeneficiary {
//   return expense.type === EXPENSE_TYPE.PRODUCT;
// }

// export function expenseIsProduct(
//   expense: ExpenseInstructor,
// ): expense is ProductInstructor {
//   return expense.type === EXPENSE_TYPE.PRODUCT;
// }

// export function expenseBeneficiaryIsService(
//   expense: ExpenseBeneficiary,
// ): expense is ServiceBeneficiary {
//   return expense.type === EXPENSE_TYPE.SERVICE;
// }

// export function expenseIsService(
//   expense: ExpenseInstructor,
// ): expense is ServiceInstructor {
//   return expense.type === EXPENSE_TYPE.SERVICE;
// }

export function distpatchExpenses(expenses: Array<ExpenseInstructor>): {
  [ExpenseType.Salary]: Array<SalaryInstructor>;
  [ExpenseType.Service]: Array<ServiceInstructor>;
  [ExpenseType.Product]: Array<ProductInstructor>;
};
export function distpatchExpenses(expenses: Array<ExpenseBeneficiary>): {
  [ExpenseType.Salary]: Array<SalaryBeneficiary>;
  [ExpenseType.Service]: Array<ServiceBeneficiary>;
  [ExpenseType.Product]: Array<ProductBeneficiary>;
};
export function distpatchExpenses(
  expenses: Array<ExpenseBeneficiary> | Array<ExpenseInstructor>,
) {
  const groups = groupBy(expenses, (e) => e.type);
  return {
    [ExpenseType.Salary]: groups[ExpenseType.Salary] || [],
    [ExpenseType.Service]: groups[ExpenseType.Service] || [],
    [ExpenseType.Product]: groups[ExpenseType.Product] || [],
  };
}
