import { Infer, nullable, object, string } from "superstruct";
import { isoDateCoercer } from "../utilities/models/Date";
import { AnalysisSchema } from "./Analysis";

export const ComplianceBeneficiarySchema = object({
  id: string(),
  label: string(),
  acceptedAt: nullable(isoDateCoercer),
});

export type ComplianceBeneficiary = Infer<typeof ComplianceBeneficiarySchema>;

// Compliance

export const ComplianceInstructorSchema = object({
  id: string(),
  label: string(),
  acceptedAt: isoDateCoercer,
  analysis: AnalysisSchema,
});

export type ComplianceInstructor = Infer<typeof ComplianceInstructorSchema>;

// ComplianceCreation

export const ComplianceCreationSchema = object({
  label: string(),
  acceptedAt: nullable(isoDateCoercer),
});

export type ComplianceCreation = Infer<typeof ComplianceCreationSchema>;
