import { Fragment, useCallback, useState } from "react";
import { useAssistance } from "../../../RootNavigator/services/Assistance";
import { useServerSdk } from "../../../RootNavigator/services/ServerSdk";
import ProjectInquiryStatus from "../inquiry/ProjectInquiryStatus";
import { Analysis } from "@hpo/client/models/Analysis";
import { AttachmentInstructor } from "@hpo/client/models/Attachment";
import { ProjectInstructor } from "@hpo/client/models/Project";
import Sections from "@hpo/client/utilities/Sections";
import FilesField from "@hpo/client/utilities/fields/FilesField";
import { canProjectHaveInquiry } from "@hpo/client/utilities/helpers/ProjectHelper";
import {
  getAttachementDescriptor,
  getAttachementDescriptorsByProjectType,
} from "@hpo/client/utilities/project/Attachments";
import Toasting from "@hpo/client/components/Toasting";

type ProjectTabFilesProps = {
  project: ProjectInstructor;
  onReload: () => unknown;
};

export default function ProjectTabFiles(props: ProjectTabFilesProps) {
  const { project, onReload } = props;

  const assistance = useAssistance();
  assistance.useVisibilityDecision(true);

  return (
    <Fragment>
      <Sections>
        {project.attachments.map((a) => (
          <ProjectTabFile
            project={project}
            attachment={a}
            key={a.id}
            onReload={onReload}
          />
        ))}
      </Sections>
    </Fragment>
  );
}

type ProjectTabFileProps = {
  project: ProjectInstructor;
  attachment: AttachmentInstructor;
  onReload: () => unknown;
};

function ProjectTabFile(props: ProjectTabFileProps) {
  const { project, attachment, onReload } = props;
  const server = useServerSdk();
  const descriptor = getAttachementDescriptorsByProjectType(project.type).find(
    (att) => attachment.key === att.key,
  );
  if (!descriptor) throw new Error("No descriptor found");
  const [files, setFiles] = useState<Array<string> | null>(attachment.files);

  const onChangeFiles = useCallback(
    async (f: Array<string> | null) => {
      setFiles(f);
      await server.uploadAttachment(project.id, descriptor.key, f || []);
      Toasting.success("Succès", "Les fichiers ont été enregistrés.");
      onReload();
    },
    [descriptor.key, project.id],
  );

  const saveAnalysis = useCallback(
    async (resourceId: string, analysis: Analysis) => {
      await server.saveAnalysis(resourceId, analysis);
      await onReload();
    },
    [server, onReload],
  );

  return (
    <Sections.Section
      title={descriptor.label}
      id={attachment.id}
      right={
        <ProjectInquiryStatus
          analysis={attachment.analysis}
          onChange={(analysis) => saveAnalysis(attachment.id, analysis)}
          readonly={!canProjectHaveInquiry(project)}
        />
      }
    >
      <FilesField
        value={files}
        onChange={onChangeFiles}
        required={getAttachementDescriptor(attachment.key).required}
      />
    </Sections.Section>
  );
}
