import ContentLayout from "@hpo/client/components/layout/ContentLayout";
import {
  BeneficiaryPaymentDetails,
  getPaymentLabel,
} from "@hpo/client/models/BeneficiaryPayment";
import LoadingView from "@hpo/client/utilities/LoadingView";

type PaymentEndedProps = {
  payment: BeneficiaryPaymentDetails;
};

export default function PaymentEnded(props: PaymentEndedProps) {
  const { payment } = props;
  return (
    <ContentLayout title={getPaymentLabel(payment)}>
      <LoadingView message="Ce paiement est clos. Si ce n'est pas déja le cas, vous devriez recevoir les fonds prochainement." />
    </ContentLayout>
  );
}
