import { Global, css } from "@emotion/react";
import { PrimeReactProvider } from "primereact/api";
import { Fragment } from "react";
import "primereact/resources/themes/mira/theme.css";
import dayjs from "dayjs";
import LoggedInNavigator from "../LoggedInNavigator";
import LoginScreen from "./LoginScreen";
import Config from "./services/Config";
import ServerSdk, {
  NetworkError,
  ServerSdkProvider,
} from "./services/ServerSdk";
import WhoAmI, { WhoAmIProvider, useWhoAmI } from "./services/WhoAmI";
import Background from "./Background";
import Menu from "./Menu";
import PasswordRenewalScreen from "./PasswordRenewalScreenScreen";
import AssistancePanel from "./assistance/AssistancePanel";
import Assistance, { AssistanceProvider } from "./services/Assistance";
import JwtManager from "@hpo/client/utilities/JwtManager";
import { UserAccessSchema } from "@hpo/client/models/User";
import useServices from "@hpo/client/utilities/useServices";
import Await from "@hpo/client/utilities/Await";
import { Toasts } from "@hpo/client/components/Toasting";
import Theme from "@hpo/client/utilities/Theme";
import { Dialogs } from "@hpo/client/components/Dialog";
import logger, { LoggerProvider } from "@hpo/client/logger";
import messageExceptionCaster from "@hpo/client/message-exception-caster";
import MessageException from "@hpo/client/utilities/errors/MessageException";
import CatchError from "@hpo/client/utilities/CatchError";

messageExceptionCaster.registerClass(
  NetworkError,
  () =>
    new MessageException(
      "La connexion à la plateforme HPO est impossible pour le moment. Veuillez réessayer plus tard.",
      null,
    ),
);

export default function RootNavigator() {
  const services = useServices(async () => {
    const jwt = new JwtManager(UserAccessSchema);
    const whoami = new WhoAmI(jwt);
    const server = new ServerSdk(Config.server.url, whoami);
    const assistance = new Assistance();
    await server.refreshLogin();
    return { whoami, server, assistance };
  }, []);

  return (
    <Fragment>
      <PrimeReactProvider>
        <Global
          styles={css`
            html {
              background-color: var(--surface-ground);
            }
            body {
              margin: 0px;
              color: ${Theme.text.normal};
              transition: padding-right 300ms;
            }
            input {
              min-width: 0px;
              width: auto;
            }
            ul {
              margin: 0;
              list-style-type: disc;
              padding-left: 25px;
            }
            ,
            p,
            h1,
            h2,
            h3 {
              margin: 0;
              padding: 0;
            }
          `}
        />
        <Toasts />
        <Dialogs />
        <LoggerProvider value={logger}>
          <Await promise={services}>
            {(services) => {
              return (
                <AssistanceProvider value={services.assistance}>
                  <WhoAmIProvider value={services.whoami}>
                    <ServerSdkProvider value={services.server}>
                      <Background />
                      <Menu />
                      <AssistancePanel />
                      <div id="content">
                        <RootNavigatorScreens />
                      </div>
                    </ServerSdkProvider>
                  </WhoAmIProvider>
                </AssistanceProvider>
              );
            }}
          </Await>
        </LoggerProvider>
      </PrimeReactProvider>
    </Fragment>
  );
}

function RootNavigatorScreens() {
  const whoami = useWhoAmI();
  const userToken = whoami.use();
  if (!userToken) {
    return <LoginScreen />;
  } else {
    const expiration = userToken.getPayload().passwordExpiartion;
    if (expiration && dayjs(expiration).isBefore(new Date())) {
      return <PasswordRenewalScreen />;
    } else {
      return (
        <CatchError>
          <LoggedInNavigator />
        </CatchError>
      );
    }
  }
}
