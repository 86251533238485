import { Fragment, ReactNode, useCallback, useEffect, useState } from "react";
import { css } from "@emotion/react";
import { assert } from "superstruct";
import { useServerSdk } from "../../RootNavigator/services/ServerSdk";
import { MyProjectBaseProps } from ".";
import Card from "@hpo/client/components/Card";
import Text from "@hpo/client/components/text/Text";
import Spacer from "@hpo/client/utilities/Spacer";
import TextField from "@hpo/client/utilities/fields/TextField";
import Button from "@hpo/client/components/Button";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import Toasting from "@hpo/client/components/Toasting";
import ErrorToast from "@hpo/client/utilities/ErrorToast";
import MessageException from "@hpo/client/utilities/errors/MessageException";
import { phoneCoercer } from "@hpo/client/utilities/helpers/ProjectHelper";

type MyProjectIdentityProps = MyProjectBaseProps;

export default function MyProjectIdentity(props: MyProjectIdentityProps) {
  const { onRefresh } = props;

  const [contactPhoneNumber, setContactPhoneNumber] = useState<string | null>(
    props.project.contactPhoneNumber,
  );
  const [contactMobilePhoneNumber, setContactMobilePhoneNumber] = useState<
    string | null
  >(props.project.contactMobilePhoneNumber);
  const [keywords, setKeywords] = useState<Array<string>>(
    props.project.keywords || [],
  );
  const [label, setLabel] = useState<string | null>(props.project.label);
  const [summary, setSummary] = useState<string | null>(props.project.summary);

  const server = useServerSdk();

  const [saveProject, savingStatus] = useSubmitCallback(
    async (
      key:
        | "label"
        | "contactPhoneNumber"
        | "contactMobilePhoneNumber"
        | "keywords"
        | "summary",
      value: string | string[] | null,
    ) => {
      if (key === "keywords" && value && value.length > 5) {
        throw new MessageException(
          "Vous ne pouvez pas ajouter plus de 5 mots clés",
          null,
        );
      }

      // We check the phone number validity
      if (key === "contactPhoneNumber" || key === "contactMobilePhoneNumber") {
        try {
          assert(value, phoneCoercer);
        } catch (_error) {
          throw new MessageException("Format de téléphone incorrect", null);
        }
      }

      await server.updateMyProject(props.project.id, {
        [key]: value,
      });

      Toasting.success("Succès", "Projet à jour");
      onRefresh();
    },
    [
      props.project,
      label,
      contactPhoneNumber,
      contactMobilePhoneNumber,
      keywords,
      summary,
      server,
      onRefresh,
    ],
  );

  useEffect(() => {
    setContactPhoneNumber(props.project.contactPhoneNumber);
    setContactMobilePhoneNumber(props.project.contactMobilePhoneNumber);
  }, [props.project]);

  const keywordsArrayToString = useCallback((keywords: string[]): string => {
    return keywords.join(", ");
  }, []);

  const keywordsStringToArray = useCallback(
    (keywords: string | null): string[] => {
      return (
        (keywords && keywords.split(",").map((keyword) => keyword.trim())) || []
      );
    },
    [],
  );

  return (
    <Fragment>
      <ErrorToast error={savingStatus.error} />
      <CardIdentity title="Structure">
        <TextField
          required
          id="organizationBusinessName"
          label="Raison sociale"
          value={props.project.organization.businessName}
          disabled
        />
        <Spacer />
        <TextField
          required
          id="organizationType"
          label="Statut juridique"
          value={props.project.organization.type}
          disabled
        />
        <Spacer />
        <TextField
          required
          id="organizationLegalId"
          label="Numéro de SIRET"
          value={props.project.organization.legalId}
          disabled
        />
      </CardIdentity>
      <Spacer />
      <CardIdentity title="Représentant légal">
        <TextField
          required
          id="organizationLegalRepresentativeName"
          label="Nom du réprésentant légal"
          value={props.project.organization.legalRepresentativeName}
          disabled
        />
        <Spacer />
        <TextField
          required
          id="organizationLegalRepresentativePosition"
          label="Fonction du réprésentant légal"
          value={props.project.organization.legalRepresentativePosition}
          disabled
        />
      </CardIdentity>
      <Spacer />
      <CardIdentity title="Adresse">
        <TextField
          required
          id="organizationAddressStreet"
          label="Adresse postale"
          value={props.project.organization.address.street}
          disabled
        />
        <Spacer />
        <TextField
          required
          id="organizationAddressPostalCode"
          label="Code postal"
          value={props.project.organization.address.postalCode}
          disabled
        />
        <Spacer />
        <TextField
          required
          id="organizationAddressTown"
          label="Commune"
          // {...fieldProps("organization.address.town")}
          value={props.project.organization.address.town}
          disabled
        />
      </CardIdentity>
      <Spacer />
      <CardIdentity title="Référent">
        <TextField
          required
          id="ownerFirstName"
          label="Personne référente dossier HPO :"
          value={`${props.project.owner.firstName} ${props.project.owner.lastName}`}
          disabled
        />
        <Spacer />
        <TextField
          required
          id="ownerEmail"
          label="Adresse email"
          value={props.project.owner.email}
          disabled
        />
        <Spacer />
        <TextField
          required
          id="contactPhoneNumber"
          label="Téléphone fixe"
          value={contactPhoneNumber}
          onChange={(value) => setContactPhoneNumber(value)}
        />
        <Spacer scale={0.5} />
        <Button
          label="Sauvegarder"
          onClick={() => saveProject("contactPhoneNumber", contactPhoneNumber)}
          loading={savingStatus.running}
          disabled={contactPhoneNumber === props.project.contactPhoneNumber}
        />
        <Spacer />
        <TextField
          required
          id="contactMobilePhoneNumber"
          label="Téléphone mobile"
          value={contactMobilePhoneNumber}
          onChange={(value) => setContactMobilePhoneNumber(value)}
        />
        <Spacer scale={0.5} />
        <Button
          label="Sauvegarder"
          onClick={() =>
            saveProject("contactMobilePhoneNumber", contactMobilePhoneNumber)
          }
          loading={savingStatus.running}
          disabled={
            contactMobilePhoneNumber === props.project.contactMobilePhoneNumber
          }
        />
      </CardIdentity>
      <Spacer />
      <CardIdentity title="Détails">
        <TextField
          required
          id="label"
          label="Libellé du projet"
          value={label}
          onChange={setLabel}
          textArea
        />
        <Spacer scale={0.5} />
        <Button
          label="Sauvegarder"
          onClick={() => saveProject("label", label)}
          loading={savingStatus.running}
          disabled={label === props.project.label}
        />
        <Spacer />
        <TextField
          required
          id="keywords"
          label="Mots clés (séparés par une virgule - 5 maximum)"
          value={keywordsArrayToString(keywords)}
          onChange={(value) => setKeywords(keywordsStringToArray(value))}
        />
        <Spacer scale={0.5} />
        <Button
          label="Sauvegarder"
          onClick={() => saveProject("keywords", keywords)}
          loading={savingStatus.running}
          disabled={
            keywordsArrayToString(keywords) ===
            keywordsArrayToString(props.project.keywords || [])
          }
        />
        <Spacer />
        <TextField
          required
          id="summary"
          label="Résumé"
          value={summary}
          onChange={setSummary}
          textArea
        />
        <Spacer scale={0.5} />
        <Button
          label="Sauvegarder"
          onClick={() => saveProject("summary", summary)}
          loading={savingStatus.running}
          disabled={summary === props.project.summary}
        />
      </CardIdentity>
    </Fragment>
  );
}

type CardIdentityProps = {
  title: string;
  children: ReactNode;
};

const CardIdentity = (props: CardIdentityProps) => {
  const cardCss = css({ width: "100%" });

  return (
    <Card css={cardCss}>
      <Text style="subtitle">{props.title}</Text>
      <Spacer scale={1} />
      {props.children}
    </Card>
  );
};
