import { Fragment, useMemo } from "react";
import ProjectAnnexHeader from "../ProjectAnnexHeader";
import DataTable, { DataTableColumn } from "@hpo/client/components/DataTable";
import { Action } from "@hpo/client/models/Action";
import { Annex, ProjectInstructor } from "@hpo/client/models/Project";
import Spacer from "@hpo/client/utilities/Spacer";
import Units from "@hpo/client/utilities/Units";

type ProjectTabCommunicationProps = {
  project: ProjectInstructor;
  annex: Annex;
  onReload: () => unknown;
};

export default function ProjectTabCommunication(
  props: ProjectTabCommunicationProps,
) {
  const { project, onReload, annex } = props;

  const commActions: Action[] = useMemo(() => {
    return project.actions.filter((action) => action.type === "communication");
  }, [project.actions]);

  const columns: DataTableColumn<Action>[] = [
    {
      field: "label",
      header: "Intitulé des actions",
    },
    {
      field: "goal",
      header: "Objectifs de la communication",
    },
    {
      header: "Cout total prévisionnel éligible",
      body: (a: Action) => {
        return Units.euro.display(a.cost);
      },
    },
    {
      field: "means",
      header: "Moyens / Outils de la communication",
    },
    {
      field: "planning",
      header: "Planning prévisionnel",
    },
  ];

  return (
    <Fragment>
      <ProjectAnnexHeader
        title="Plan de communication"
        annex={annex}
        onReload={onReload}
        project={project}
      />
      <Spacer />
      <DataTable value={commActions as Array<Action>} columns={columns} />
    </Fragment>
  );
}
