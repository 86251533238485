import { useServerSdk } from "../RootNavigator/services/ServerSdk";
import Await from "@hpo/client/utilities/Await";
import Page from "@hpo/client/utilities/Page";
import PageHeader from "@hpo/client/utilities/PageHeader";
import useData from "@hpo/client/utilities/useData";
import List, { ListItem } from "@hpo/client/utilities/List";
import smoothJoin from "@hpo/client/utilities/smoothJoin";
import InstructorPaymentStatusBadge from "@hpo/client/utilities/InstructorPaymentStatusBadge";
import { conventionPaymentRoute } from "@hpo/client/utilities/routes";
import EmptyView from "@hpo/client/utilities/EmptyView";
import { getMostRevealantAmount } from "@hpo/client/models/InstructorPayment";
import Units from "@hpo/client/utilities/Units";
import { getPaymentLabel } from "@hpo/client/models/BeneficiaryPayment";

export default function PaymentsScreen() {
  const server = useServerSdk();
  const [payments] = useData(() => server.getPayments(), []);

  return (
    <Page>
      <PageHeader title="Paiements à instruire" />
      <Await promise={payments}>
        {(payments) => {
          return (
            <List
              renderEmpty={() => (
                <EmptyView message="Vous n'avez aucun paiement à instruire pour le moment. Vous serez informé par email dès lors que vous aurez une action à effectuer." />
              )}
              data={payments}
              renderItem={(p) => {
                const { amount, label } = getMostRevealantAmount(p);
                return (
                  <ListItem
                    label={`${Units.euro.display(amount)} pour ${smoothJoin(p.organizations.map((o) => o.businessName))}`}
                    help={[
                      label,
                      `${getPaymentLabel(p)} de la convention n°${p.convention.reference}`,
                    ]}
                    right={<InstructorPaymentStatusBadge status={p.status} />}
                    to={conventionPaymentRoute.getRootUrl({
                      conventionId: p.convention.id,
                      paymentId: p.id,
                    })}
                  />
                );
              }}
            />
          );
        }}
      </Await>
    </Page>
  );
}
