import { Fragment } from "react";
import { useServerSdk } from "../../services/ServerSdk";
import ChatMessages from "./ChatMessages";
import useData from "@hpo/client/utilities/useData";
import { OrganizationSummary } from "@hpo/client/models/Organization";
import Await from "@hpo/client/utilities/Await";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import ErrorToast from "@hpo/client/utilities/ErrorToast";

type ChatConversation = {
  target: OrganizationSummary | true;
};

export default function ChatConversation(props: ChatConversation) {
  const { target } = props;
  const server = useServerSdk();
  const [messages, messagesData] = useData(() => {
    return server.getMessages(target === true ? target : target.id);
  }, [target]);

  const [onMessageSubmit, messageSubmission] = useSubmitCallback(
    async (content: string) => {
      await server.sendMessage(target === true ? target : target.id, content);
      messagesData.reload();
    },
    [target],
  );

  return (
    <Fragment>
      <ErrorToast error={messageSubmission.error} />
      <Await promise={messages}>
        {(messages) => (
          <ChatMessages
            target={target}
            messages={messages}
            onMessageSubmit={onMessageSubmit}
            reload={() => messagesData.reload(true)}
          />
        )}
      </Await>
    </Fragment>
  );
}
