import { get } from "lodash";

export default function stringifyError(error: unknown): string {
  const errorType = get(error, "constructor.name") as string | undefined;
  const errorMessage = get(error, "message") as string | undefined;
  if (errorType && errorMessage) {
    return `[${errorType}] ${errorMessage}`;
  } else if (errorType && !errorMessage) {
    return `[${errorType}]`;
  } else if (!errorType && errorMessage) {
    return `${errorMessage}`;
  } else {
    return "Unknown error";
  }
}
