import { css } from "@emotion/react";

export const SPACING_BASE = 20;

type SpacerProps = {
  horizontal?: boolean;
  size?: number;
  scale?: number;
  grow?: boolean;
};

export default function Spacer(props: SpacerProps) {
  const {
    horizontal = false,
    size = SPACING_BASE,
    scale = 1,
    grow = false,
  } = props;
  const style = css({
    [horizontal ? "width" : "height"]: size * scale,
    display: horizontal ? "inline-block" : "block",
    flexShrink: 0,
    flexGrow: grow ? 1 : 0,
  });
  return <div css={style} />;
}
