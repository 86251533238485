import { enums } from "superstruct";

enum IndicatorType {
  Achievement = "achievment",
  Result = "result",
  Communication = "communication",
  Transfert = "transfer",
}

export const IndicatorTypeValues = [
  IndicatorType.Achievement,
  IndicatorType.Result,
  IndicatorType.Communication,
  IndicatorType.Transfert,
] as const;

export const IndicatorTypeLabels: Record<IndicatorType, string> = {
  [IndicatorType.Achievement]: "Indicateur de réalisation",
  [IndicatorType.Result]: "Indicateur de résultat",
  [IndicatorType.Communication]: "Indicateur de communication",
  [IndicatorType.Transfert]: "Indicateur de transfert aux agriculteurs",
};

export const IndicatorTypeSchema = enums(IndicatorTypeValues);

export default IndicatorType;
