import { Struct, enums, record } from "superstruct";

enum PaymentFragment {
  Deposit1 = "deposit1",
  Deposit2 = "deposit2",
  Balance = "balance",
}

export const PaymentFragmentValues = [
  ...Object.values(PaymentFragment),
] as const;

export const PaymentFragmentSchema = enums(Object.values(PaymentFragment));

export const PaymentFragmentLabels: Record<PaymentFragment, string> = {
  deposit1: "Acompte n°1",
  deposit2: "Acompte n°2",
  balance: "Solde",
};

export const PaymentFragmentOfLabels: Record<PaymentFragment, string> = {
  deposit1: "de l'acompte n°1",
  deposit2: "de l'acompte n°2",
  balance: "du solde",
};

export const PaymentFragmentRates: Record<PaymentFragment, number> = {
  deposit1: 0.5,
  deposit2: 0.3,
  balance: 0.2,
};

export const OnPeriodSchema = <T, S>(s: Struct<T, S>) =>
  record(enums([...PaymentFragmentValues, "total"]), s);

export type OnPeriod<V> = Record<PaymentFragment | "total", V>;

export default PaymentFragment;
