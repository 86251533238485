import { Infer, nullable, object, string } from "superstruct";
import { AnalysisSchema } from "./Analysis";

// Description
export const DescriptionBeneficiarySchema = object({
  id: string(),
  label: string(),
  value: nullable(string()),
});

export type DescriptionBeneficiary = Infer<typeof DescriptionBeneficiarySchema>;

export const DescriptionInstructorSchema = object({
  id: string(),
  label: string(),
  value: string(),
  analysis: AnalysisSchema,
});

export type DescriptionInstructor = Infer<typeof DescriptionInstructorSchema>;

// DescriptionCreation

export const DescriptionCreationSchema = object({
  label: string(),
  value: nullable(string()),
});

export type DescriptionCreation = Infer<typeof DescriptionCreationSchema>;
