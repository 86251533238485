import { Route, Switch } from "wouter";
import ConventionNavigator from "../ConventionNavigator";
import ConventionsScreen from "./ConventionsScreen";
import NewConventionScreen from "./NewConventionScreen";
import {
  conventionCreationRoute,
  conventionRoute,
} from "@hpo/client/utilities/routes";

export default function ConventionsNavigator() {
  return (
    <Switch>
      <Route path={"/"} component={ConventionsScreen} />
      <Route
        path={conventionCreationRoute.getPath()}
        component={NewConventionScreen}
      />
      <Route
        path={conventionRoute.getPath()}
        component={ConventionNavigator}
        nest
      />
    </Switch>
  );
}
