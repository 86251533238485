import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
} from "react";
import { css } from "@emotion/react";
import Theme from "../../Theme";
import { FieldWrapperElementContext } from "./FieldWrapper";

type FieldDropdownProps = PropsWithChildren<{
  visible: boolean;
  onHide: () => void;
  height?: number | "auto";
}>;

export default function FieldDropdown(props: FieldDropdownProps) {
  const { visible, onHide, height = 200 } = props;

  const wrapperRef = useContext(FieldWrapperElementContext);

  const containerCss = css({
    height: 0,
    overflow: "visible",
    position: "relative",
    zIndex: 10000,
  });

  const innerCss = css({
    maxHeight: height === "auto" ? undefined : height,
    background: "white",
    position: "absolute",
    top: 1,
    left: 6,
    right: 6,
    borderBottomLeftRadius: 6,
    borderBottomRightRadius: 6,
    borderWidth: 1,
    borderTopWidth: 0,
    borderColor: Theme.color,
    borderStyle: "solid",
    opacity: visible ? 1 : 0,
    transition: "all 200ms",
    pointerEvents: visible ? "all" : "none",
    overflow: "auto",
  });

  const onClickOnConatainer = useCallback(
    (e: React.MouseEvent) => e.stopPropagation(),
    [],
  );

  useEffect(() => {
    if (!visible) return;
    const onClick = (e: MouseEvent) => {
      if (
        wrapperRef.current &&
        e.target instanceof HTMLElement &&
        wrapperRef.current.contains(e.target)
      ) {
        return;
      }
      onHide();
    };
    document.addEventListener("click", onClick);
    return () => document.removeEventListener("click", onClick);
  }, [visible, onHide, wrapperRef]);

  return (
    <div css={containerCss} onClick={onClickOnConatainer}>
      <div css={innerCss}>{props.children}</div>
    </div>
  );
}
