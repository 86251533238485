import { useMemo } from "react";

export function useFieldId(preferedId?: string) {
  return useMemo(
    () => preferedId || `field${Math.random().toString().split(".")[1]}`,
    [preferedId],
  );
}

export type FieldValidity =
  | { valid: true }
  | { valid: false; reason: "empty" | "other"; message: string };

export function useFieldValidity<V>(
  value: V,
  required: boolean,
  emptynessFn: (v: V) => boolean,
  errorFn?: (v: V) => string | null,
): FieldValidity {
  return useMemo(() => {
    if (required) {
      const isEmpty = emptynessFn(value);
      if (isEmpty)
        return {
          valid: false,
          reason: "empty",
          message: "Ce champ doit être renseigné",
        };
    }
    if (errorFn) {
      const error = errorFn(value);
      if (error !== null) {
        return {
          valid: false,
          reason: "other",
          message: error,
        };
      }
    }
    return { valid: true };
  }, [value, required, errorFn]);
}

export function usePrimeClassname(validity: FieldValidity) {
  return useMemo(() => {
    const output: Array<string> = [];
    if (!validity.valid) output.push("p-invalid");
    return output.join(" ");
  }, [validity]);
}

export type DropDownItem<D> = {
  label: string;
  value: D;
};
