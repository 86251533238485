import { PropsWithChildren } from "react";
import WithHelp from "./WithHelp";
import Icon, { IconName } from "@hpo/client/components/Icon";
import Theme from "@hpo/client/utilities/Theme";

type HelpIconProps = PropsWithChildren<{
  title?: string;
  icon?: IconName;
  color?: string;
}>;

export function HelpIcon(props: HelpIconProps) {
  const { color = Theme.color, icon = "help" } = props;
  return (
    <div css={{ display: "inline-block" }}>
      <WithHelp
        text={props.children}
        title={props.title}
        display="mouseover"
        color={color}
      >
        <Icon name={icon} color={color} />
      </WithHelp>
    </div>
  );
}
