import { string, Struct, coerce, trimmed, define } from "superstruct";
import isEmail from "is-email";

export const emailSchema = define<string>(
  "Email",
  (v: unknown): v is string => typeof v === "string" && isEmail(v),
);

export function lowered<T, S>(struct: Struct<T, S>): Struct<T, S> {
  return coerce(struct, string(), (x) => x.toLowerCase());
}
export const emailCoercer = lowered(trimmed(emailSchema));
