type ExceptionTags = Record<
  string,
  string | number | boolean | null | undefined
>;

export type ExceptionParams = Omit<ErrorOptions, "cause"> & {
  message?: string;
  tags?: ExceptionTags;
  cause: unknown;
};

export default class Exception extends Error {
  readonly tags?: ExceptionTags;
  constructor(params: ExceptionParams) {
    super(params.message, params);
    this.tags = params.tags;
  }
}
