import { DependencyList, useEffect, useMemo } from "react";
import Cleaner from "./Cleaner";

export default function useServices<Output>(
  fn: (cleaner: Cleaner) => Output,
  deps: DependencyList = [],
) {
  const cleaner = useMemo(() => new Cleaner(), deps);
  const services = useMemo(() => fn(cleaner), [cleaner]);
  useEffect(() => {
    return () => cleaner.call();
  }, [cleaner]);
  return services;
}
