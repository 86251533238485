import { Logtail } from "@logtail/browser";
import stringifyError from "../errors/stringifyError";
import getErrorsStack from "../errors/getErrorStack";
import jsonifyError from "../errors/jsonifyError";
import Logger, { LogTags } from ".";

export default class LogtailLogger implements Logger {
  private logtail: Logtail;
  constructor(
    sourceToken: string,
    private context_tags: LogTags,
  ) {
    this.logtail = new Logtail(sourceToken);
    this.logtail.use(async (log) => ({ ...log, context_tags }));
  }
  info(message: string, tags: LogTags = {}) {
    void this.logtail.info(message, { tags: tags });
  }
  warn(message: string, tags: LogTags = {}) {
    void this.logtail.warn(message, { tags: tags });
  }
  error(error: unknown) {
    const stack = getErrorsStack(error);
    const message = stack.map((s) => stringifyError(s)).join(" (caused by) ");
    void this.logtail.error(message, {
      errors: stack.map((e) => jsonifyError(e)),
    });
  }
}
