import { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { css } from "@emotion/react";
import { useAssistance } from "../../services/Assistance";
import ChatBubble from "./ChatBubble";
import ChatConversation from "./ChatConversation";
import { OrganizationSummary } from "@hpo/client/models/Organization";

export default function ChatPanel() {
  const el = useMemo(() => {
    let el = document.getElementById("chat-panel");
    if (!el) {
      el = document.createElement("div");
      el.id = "chat-panel";
      document.body.appendChild(el);
    }
    return el;
  }, []);

  const chats = useAssistance().useChats();
  const [chat, setChat] = useState<OrganizationSummary | true | null>(null);

  useEffect(() => {
    if (chat === null) return;
    else if (chat === true) {
      const isAvailable = chats.find((c) => c === true);
      if (!isAvailable) setChat(null);
    } else {
      const isAvailable = chats.find((c) => c !== true && c.id === chat.id);
      if (!isAvailable) setChat(null);
    }
  }, [chat, chats]);

  const chatContainerCss = css({
    position: "fixed",
    right: 0,
    bottom: 0,
    top: chat ? 0 : undefined,
    display: "flex",
    flexDirection: "column",
  });

  const conversationCss = css({
    flex: 1,
    flexShrink: 1,
    width: "33vw",
    minHeight: 0,
    pointerEvents: chat === null ? "none" : "auto",
    padding: 20,
    paddingLeft: 0,
  });

  const buttonsCss = css({
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    padding: 20,
    paddingLeft: 0,
    width: "33vw",
    gap: 20,
    flexWrap: "wrap",
  });

  return createPortal(
    <div css={chatContainerCss}>
      {chat ? (
        <div css={conversationCss}>
          <ChatConversation target={chat} />
        </div>
      ) : null}
      <div css={buttonsCss}>
        {chats.map((c) => (
          <ChatBubble
            key={c === true ? "main" : c.id}
            label={c === true ? "Poser une question" : c.businessName}
            onClick={() =>
              setChat((current) => {
                if (current) {
                  if (current === c) return null;
                  else return c;
                } else return c;
              })
            }
            selected={chat ? chat === c : false}
          />
        ))}
      </div>
    </div>,
    el,
  );
}
