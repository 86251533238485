import { useState } from "react";
import { Tag, TagCreation } from "@hpo/client/models/Tag";
import FieldsForm from "@hpo/client/utilities/fields/FieldsForm";
import TextField from "@hpo/client/utilities/fields/TextField";
import Spacer from "@hpo/client/utilities/Spacer";
import MessageException from "@hpo/client/utilities/errors/MessageException";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import ErrorToast from "@hpo/client/utilities/ErrorToast";
import Button from "@hpo/client/components/Button";

type TagEditorProps = {
  tag: Tag | null;
  onDone: (tag: TagCreation) => unknown;
};

export default function TagEditor({ tag, onDone }: TagEditorProps) {
  const [label, setLabel] = useState<string | null>(tag ? tag.label : null);
  const [description, setDescription] = useState<string | null>(
    tag ? tag.description : null,
  );

  const [onSubmit, submission] = useSubmitCallback(async () => {
    if (!label)
      throw new MessageException(
        "Veuillez définir un libellé à l'étiquette",
        null,
      );

    onDone({ label, description });
  }, [label, description]);

  return (
    <FieldsForm onSubmit={onSubmit}>
      <ErrorToast error={submission.error} />
      <TextField value={label} onChange={setLabel} label="Libellé" required />
      <Spacer />
      <TextField
        value={description}
        onChange={setDescription}
        label="Description"
      />
      <Spacer />
      <Button
        label={tag ? "Modifier l'étiquette" : "Créer l'étiquette"}
        submit
        loading={submission.running}
      />
    </FieldsForm>
  );
}
