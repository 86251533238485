import Table from "../PaymentScreen/Table";
import { HelpIcon } from "../../RootNavigator/assistance/Help/HelpIcon";
import { PaymentFragmentLabels } from "@hpo/client/utilities/enums/PaymentFragment";
import Amounts from "@hpo/client/utilities/Amounts";
import T from "@hpo/client/components/text/Text";
import Units from "@hpo/client/utilities/Units";
import { ConventionFoundation } from "@hpo/client/models/Convention";

type FoundationTableProps = {
  foundation: ConventionFoundation;
};

export default function FoundationTable(props: FoundationTableProps) {
  const f = props.foundation;
  return (
    <Table>
      <thead>
        <tr>
          <th></th>
          <th>{PaymentFragmentLabels["deposit1"]}</th>
          <th>{PaymentFragmentLabels["deposit2"]}</th>
          <th>{PaymentFragmentLabels["balance"]}</th>
          <th>Cumulé</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <T>
              {Amounts.granted.label}{" "}
              <HelpIcon title={Amounts.granted.label}>
                {Amounts.granted.info}
              </HelpIcon>
            </T>
          </td>
          <td>{Units.euro.display(f.granted.deposit1)}</td>
          <td>{Units.euro.display(f.granted.deposit2)}</td>
          <td>{Units.euro.display(f.granted.balance)}</td>
          <td>{Units.euro.display(f.granted.total)}</td>
        </tr>
        <tr>
          <td>
            <T>
              {Amounts.stock.label}{" "}
              <HelpIcon title={Amounts.stock.label}>
                {Amounts.stock.info}
              </HelpIcon>
            </T>
          </td>
          <td>{Units.euro.display(f.stock.deposit1)}</td>
          <td>{Units.euro.display(f.stock.deposit2)}</td>
          <td>{Units.euro.display(f.stock.balance)}</td>
          <td>{Units.euro.display(f.stock.total)}</td>
        </tr>
        <tr className="total">
          <td>
            <T>
              {Amounts.available.label}{" "}
              <HelpIcon title={Amounts.available.label}>
                {Amounts.available.info}
              </HelpIcon>
            </T>
          </td>
          <td>{Units.euro.display(f.available.deposit1)}</td>
          <td>{Units.euro.display(f.available.deposit2)}</td>
          <td>{Units.euro.display(f.available.balance)}</td>
          <td>{Units.euro.display(f.available.total)}</td>
        </tr>
        <tr className="divider">
          <td colSpan={5} />
        </tr>
        <tr>
          <td>
            <T>
              {Amounts.requested.label}{" "}
              <HelpIcon title={Amounts.requested.label}>
                {Amounts.requested.info}
              </HelpIcon>
            </T>
          </td>
          <td>{Units.euro.display(f.requested.deposit1)}</td>
          <td>{Units.euro.display(f.requested.deposit2)}</td>
          <td>{Units.euro.display(f.requested.balance)}</td>
          <td>{Units.euro.display(f.requested.total)}</td>
        </tr>
        <tr>
          <td>
            <T>
              {Amounts.instructed.label}{" "}
              <HelpIcon title={Amounts.instructed.label}>
                {Amounts.instructed.info}
              </HelpIcon>
            </T>
          </td>
          <td>{Units.euro.display(f.instructed.deposit1)}</td>
          <td>{Units.euro.display(f.instructed.deposit2)}</td>
          <td>{Units.euro.display(f.instructed.balance)}</td>
          <td>{Units.euro.display(f.instructed.total)}</td>
        </tr>
        <tr>
          <td>
            <T>
              {Amounts.validated.label}{" "}
              <HelpIcon title={Amounts.validated.label}>
                {Amounts.validated.info}
              </HelpIcon>
            </T>
          </td>
          <td>{Units.euro.display(f.validated.deposit1)}</td>
          <td>{Units.euro.display(f.validated.deposit2)}</td>
          <td>{Units.euro.display(f.validated.balance)}</td>
          <td>{Units.euro.display(f.validated.total)}</td>
        </tr>
        <tr className="total">
          <td>
            <T>
              {Amounts.certified.label}{" "}
              <HelpIcon title={Amounts.certified.label}>
                {Amounts.certified.info}
              </HelpIcon>
            </T>
          </td>
          <td>{Units.euro.display(f.certified.deposit1)}</td>
          <td>{Units.euro.display(f.certified.deposit2)}</td>
          <td>{Units.euro.display(f.certified.balance)}</td>
          <td>{Units.euro.display(f.certified.total)}</td>
        </tr>
        <tr>
          <td>
            <T>
              {Amounts.stockable.label}{" "}
              <HelpIcon title={Amounts.stockable.label}>
                {Amounts.stockable.info}
              </HelpIcon>
            </T>
          </td>
          <td>{Units.euro.display(f.stockable.deposit1)}</td>
          <td>{Units.euro.display(f.stockable.deposit2)}</td>
          <td>{Units.euro.display(f.stockable.balance)}</td>
          <td>{Units.euro.display(f.stockable.total)}</td>
        </tr>
        <tr className="divider">
          <td colSpan={5} />
        </tr>
        <tr>
          <td>
            <T>
              {Amounts.maximalAdvance.label}{" "}
              <HelpIcon title={Amounts.maximalAdvance.label}>
                {Amounts.maximalAdvance.info}
              </HelpIcon>
            </T>
          </td>
          <td>{Units.euro.display(f.maximalAdvance.deposit1)}</td>
          <td>{Units.euro.display(f.maximalAdvance.deposit2)}</td>
          <td>{Units.euro.display(f.maximalAdvance.balance)}</td>
          <td rowSpan={2}>
            {Units.euro.display(
              (f.maximalAdvance.total || 0) + (f.transfer.total || 0),
            )}
          </td>
        </tr>
        <tr className="total">
          <td>
            <T>
              {Amounts.transfer.label}{" "}
              <HelpIcon title={Amounts.transfer.label}>
                {Amounts.transfer.info}
              </HelpIcon>
            </T>
          </td>
          <td>{Units.euro.display(f.transfer.deposit1)}</td>
          <td>{Units.euro.display(f.transfer.deposit2)}</td>
          <td>{Units.euro.display(f.transfer.balance)}</td>
          <td style={{ display: "none" }}></td>
        </tr>
        <tr>
          <td>
            <T>
              {Amounts.remainingAdvance.label}{" "}
              <HelpIcon title={Amounts.remainingAdvance.label}>
                {Amounts.remainingAdvance.info}
              </HelpIcon>
            </T>
          </td>
          <td>{Units.euro.display(f.remainingAdvance.deposit1)}</td>
          <td>{Units.euro.display(f.remainingAdvance.deposit2)}</td>
          <td>{Units.euro.display(f.remainingAdvance.balance)}</td>
          <td>{Units.euro.display(f.remainingAdvance.total)}</td>
        </tr>
      </tbody>
    </Table>
  );
}
