import { css } from "@emotion/react";
import { PropsWithChildren } from "react";

type PageProps = PropsWithChildren;

export default function Page(props: PageProps) {
  const containerCss = css({
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 60,
    paddingBottom: "25vh",
  });
  return <div css={containerCss}>{props.children}</div>;
}
