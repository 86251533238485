import { Fragment, useMemo } from "react";
import ProjectAnnexHeader from "../ProjectAnnexHeader";
import DataTable, { DataTableColumn } from "@hpo/client/components/DataTable";
import { SalaryInstructor } from "@hpo/client/models/Expense";
import { Annex, ProjectInstructor } from "@hpo/client/models/Project";
import Spacer from "@hpo/client/utilities/Spacer";
import Units from "@hpo/client/utilities/Units";
import { distpatchExpenses } from "@hpo/client/utilities/helpers/ExpenseHelper";

type ProjectTabSalariesProps = {
  project: ProjectInstructor;
  annex: Annex;
  onReload: () => unknown;
};

export default function ProjectTabSalaries(props: ProjectTabSalariesProps) {
  const { project, annex, onReload } = props;

  const salaries = useMemo(
    () => distpatchExpenses(project.expenses).salary,
    [project.expenses],
  );

  const columns: DataTableColumn<SalaryInstructor>[] = [
    { field: "label", header: "Intitulé" },
    { field: "employeeName", header: "Nom Prénom" },
    {
      field: "employeePosition",
      header: "Fonction du poste affecté à l'action",
    },
    {
      header: "Salaires + charges patronales prévisionnels / an",
      body: (e) => {
        return Units.euro.display(e.annualCost);
      },
    },
    {
      header: "% ETP affecté /an",
      body: (e) => {
        return Units.percentage.display(e.affectation);
      },
    },
    {
      header:
        "Salaires + charges patronales + frais prévisionnels affectés à l'action / an",
      body: (e) => {
        return Units.euro.display(e.fullCostOneYear);
      },
    },
    {
      header: "Cout total prévisionnel sur 3 ans ",
      body: (e) => {
        return Units.euro.display(e.fullCostThreeYears);
      },
    },
    {
      header: " Salaires + charges patronales plafonnés le cas échéant / an",
      body: (e) => {
        return Units.euro.display(e.annualCostCeiling);
      },
    },
    {
      header:
        "Montant de l'aide éligible : Salaires + charges patronales  plafonnés le cas échéant + frais affectés à l'action / an",
      body: (e) => {
        return Units.euro.display(e.eligibleHelpOneYear);
      },
    },
    {
      header: "Montant de l'aide éligible sur 3 ans",
      body: (e) => {
        return Units.euro.display(e.eligibleHelpThreeYears);
      },
    },
  ];

  return (
    <Fragment>
      <ProjectAnnexHeader
        title="Récapitulatif des dépenses prévisionnelles - SALAIRES"
        annex={annex}
        onReload={onReload}
        project={project}
      />
      <Spacer />
      <DataTable value={salaries} columns={columns} />
    </Fragment>
  );
}
