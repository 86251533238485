import Spacer from "@hpo/client/utilities/Spacer";
import Page from "@hpo/client/utilities/Page";
import PageHeader from "@hpo/client/utilities/PageHeader";

type ContentLayoutProps = {
  children: React.ReactNode;
  title: string;
  actions?: React.ReactNode;
  titleOneLine?: boolean;
};

export default function ContentLayout({
  children,
  title,
  actions,
  titleOneLine,
}: ContentLayoutProps) {
  return (
    <Page>
      <PageHeader title={title} right={actions} titleOneLine={titleOneLine} />
      <Spacer />
      {children}
    </Page>
  );
}
