import { Infer, array, enums, object, string } from "superstruct";
import { AnalysisSchema } from "./Analysis";
import { MarkerPayloadSchema, MarkerSchema } from "./Marker";
import { TagSchema } from "./Tag";
import { UnitNamesValues } from "@hpo/client/utilities/Units";
import IndicatorType from "@hpo/client/utilities/enums/IndicatorType";
import IndicatorOrigin from "@hpo/client/utilities/enums/IndicatorOrigin";

// IndicatorSummary

export const IndicatorSummarySchema = object({
  id: string(),
  label: string(),
  type: enums(Object.values(IndicatorType)),
  origin: enums(Object.values(IndicatorOrigin)),
  unit: enums(UnitNamesValues),
});

export type IndicatorSummary = Infer<typeof IndicatorSummarySchema>;

// BeneficiaryIndicator

export const BeneficiaryIndicatorSchema = object({
  id: string(),
  label: string(),
  type: enums(Object.values(IndicatorType)),
  origin: enums(Object.values(IndicatorOrigin)),
  unit: enums(UnitNamesValues),
  goals: array(MarkerSchema),
  actuals: array(MarkerSchema),
});

export type BeneficiaryIndicator = Infer<typeof BeneficiaryIndicatorSchema>;

// InstructorIndicator

export const InstructorIndicatorSchema = object({
  id: string(),
  label: string(),
  type: enums(Object.values(IndicatorType)),
  origin: enums(Object.values(IndicatorOrigin)),
  unit: enums(UnitNamesValues),
  goals: array(MarkerSchema),
  actuals: array(MarkerSchema),
  tags: array(TagSchema),
  analysis: AnalysisSchema,
});

export type InstructorIndicator = Infer<typeof InstructorIndicatorSchema>;

// IndicatorPayload

export const IndicatorPayloadSchema = object({
  label: string(),
  type: enums(Object.values(IndicatorType)),
  unit: enums(UnitNamesValues),
  goals: array(MarkerPayloadSchema),
});

export type IndicatorPayload = Infer<typeof IndicatorPayloadSchema>;
