import { css, keyframes } from "@emotion/react";
import Icon, { IconProps } from "./Icon";

export default function Spinner(
  props: Omit<IconProps, "name" | "additionalCss">,
) {
  const anim = keyframes`
  from {
    transform: rotate(0deg);
  }
  70% {
    transform: rotate(180deg);
  }
  100%{
    transform: rotate(180deg);
  }

`;

  const style = css({
    animation: `${anim} 1s linear infinite`,
  });
  return <Icon {...props} name="hourglass" additionalCss={style} />;
}
