import { PropsWithChildren } from "react";
import { css } from "@emotion/react";
import { Link } from "wouter";

type OnClickProps = {
  onClick?: (e: React.MouseEvent) => unknown;
  onDoubleClick?: (e: React.MouseEvent) => unknown;
};

type TargetProps = (
  | { submit: true }
  | { href: string; target?: string }
  | { to: string }
  | OnClickProps
) &
  OnClickProps & {
    disabled?: boolean;
    onClicked?: (output: unknown) => unknown;
  };

export type ClickableProps = PropsWithChildren<
  TargetProps & {
    className?: string;
    disabled?: boolean;
  }
>;

export default function Clickable(props: ClickableProps) {
  const { onClick, className, children, disabled, onDoubleClick } = props;

  const containerCss = css({
    display: "inline-block",
    textDecoration: "unset",
    border: "unset",
    background: "unset",
    textAlign: "unset",
    color: "inherit",
    padding: 0,
    fontFamily: "unset",
    fontSize: "unset",
  });

  if ("href" in props && props.href) {
    return (
      <a
        css={containerCss}
        className={className}
        href={props.href}
        target="_blank"
        onClick={onClick}
        onDoubleClick={onDoubleClick}
      >
        {children}
      </a>
    );
  } else if ("to" in props && props.to) {
    return (
      <Link href={`~${props.to}`} asChild>
        <a
          css={containerCss}
          className={className}
          onClick={onClick}
          onDoubleClick={onDoubleClick}
        >
          {children}
        </a>
      </Link>
    );
  } else if ("submit" in props && props.submit) {
    return (
      <button
        css={containerCss}
        type="submit"
        disabled={disabled}
        className={className}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
      >
        {children}
      </button>
    );
  } else if (onClick || onDoubleClick) {
    return (
      <button
        type="button"
        css={containerCss}
        disabled={disabled}
        className={className}
        onClick={onClick}
        onDoubleClick={onDoubleClick}
      >
        {children}
      </button>
    );
  } else {
    return (
      <div css={containerCss} className={className}>
        {children}
      </div>
    );
  }
}

Clickable.isReactive = (props: ClickableProps) => {
  return !isDisabled(props);
};

export function isDisabled(props: ClickableProps) {
  if (props.disabled === true) return true;
  if ("submit" in props && props.submit) return false;
  else if ("onClick" in props && props.onClick) return false;
  else if ("href" in props && props.href.length > 0) return false;
  else if ("to" in props && props.to.length > 0) return false;
  return true;
}
