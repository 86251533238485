import { Infer, array, nullable, object, string } from "superstruct";
import { AnalysisSchema } from "./Analysis";

// Receipt
export const ReceiptSchema = object({
  key: string(),
  files: array(string()),
  notes: nullable(string()),
});

export type Receipt = Infer<typeof ReceiptSchema>;

// ReceiptWithAnalysis
export const ReceiptWithAnalysisSchema = object({
  id: string(),
  key: string(),
  files: array(string()),
  notes: nullable(string()),
  analysis: AnalysisSchema,
});

export type ReceiptWithAnalysis = Infer<typeof ReceiptWithAnalysisSchema>;

// ReceiptCreation
export const ReceiptCreationSchema = object({
  key: string(),
  files: array(string()),
  notes: nullable(string()),
});

export type ReceiptCreation = Infer<typeof ReceiptCreationSchema>;

// ReceiptUpdate
export const ReceiptUpdateSchema = object({
  files: nullable(array(string())),
});

export type ReceiptUpdate = Infer<typeof ReceiptUpdateSchema>;
