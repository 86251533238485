import { ReactNode, useEffect, useState } from "react";
import { useServerSdk } from "./services/ServerSdk";
import Button from "@hpo/client/components/Button";
import Divider from "@hpo/client/components/Divider";
import ContentLayout from "@hpo/client/components/layout/ContentLayout";
import Text from "@hpo/client/components/text/Text";
import ErrorToast from "@hpo/client/utilities/ErrorToast";
import { useSearchParam } from "@hpo/client/utilities/Navigation";
import Spacer from "@hpo/client/utilities/Spacer";
import MessageException from "@hpo/client/utilities/errors/MessageException";
import FieldsForm from "@hpo/client/utilities/fields/FieldsForm";
import TextField from "@hpo/client/utilities/fields/TextField";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import "primereact/resources/themes/lara-light-cyan/theme.css";

export default function LoginScreen() {
  const server = useServerSdk();

  const [emailParam, setEmailParam] = useSearchParam("email");

  const [email, setEmail] = useState<string | null>(emailParam);
  const [password, setPassword] = useState<string | null>(null);

  useEffect(() => {
    if (emailParam) setEmailParam(null);
  }, [emailParam, setEmailParam]);

  const [onLogin, login] = useSubmitCallback(async () => {
    if (!email)
      throw new MessageException("Veuillez fournir votre adresse email", null);
    if (!password)
      throw new MessageException("Veuillez fournir votre mot de passe", null);
    await server.login(email, password);
  }, [email, password]);

  return (
    <ContentLayout title="Bievenue sur la plateforme HPO">
      <BodyText>
        Bienvenue sur la plateforme dédiée aux demandes d’aides HPO 2024-2027.
      </BodyText>
      <Spacer />
      <BodyText>
        Cette plateforme vous est mise à disposition pour vous permettre de
        déposer vos demandes de subventions financées par le Département de La
        Réunion dans le cadre du HPO de 2024 à 2027.
      </BodyText>
      <Spacer />
      <BodyText>
        Vous aurez la possibilité de suivre l’ensemble des éléments de votre
        dossier et échanger avec le service instructeur. Vous aurez également la
        possibilité d’utiliser cette plateforme pour toutes vos différentes
        démarches (demande d’acompte, dépôt de documents, retrouver une copie de
        votre convention…).
      </BodyText>
      <Spacer />
      <BodyText>
        Nous vous souhaitons une belle réalisation dans vos projets agricoles.
      </BodyText>
      <Spacer />
      <Divider />
      <Spacer />
      <Text style="section">Connexion à la plateforme</Text>
      <Spacer />
      <FieldsForm onSubmit={onLogin}>
        <TextField
          id="email"
          label="Adresse email"
          value={email}
          onChange={setEmail}
          required={true}
        />
        <Spacer />
        <TextField
          id="password"
          label="Mot de passe"
          value={password}
          onChange={setPassword}
          password={true}
          required={true}
        />
        <Spacer />
        <Button submit label={"Se connecter"} />
        <Spacer />
        <ErrorToast error={login.error} />
      </FieldsForm>
      <Spacer />
      <Divider />
      <Spacer />
      <BodyText>
        Le service instructeur du présent dispositif d’aide est le bureau
        d’études 3A Conseil, attributaire d’un marché public d’assistance à
        maîtrise d’ouvrage de la collectivité départementale.
      </BodyText>
      <Spacer />
      <Divider />
      <Spacer />
      <BodyText>
        Information RGPD, Règlement Général sur la Protection des Données
        <br />
        <br />
        En application de la loi informatique et libertés du 6 janvier 1978 du
        règlement général sur la protection des données (RGPD) du 27 avril 2016
        :
        <br />
        Le porteur de projet accepte que le service instructeur 3A CONSEIL,
        agissant en tant que Assistant à Maitrise d'Ouvrage du Département de La
        Réunion collecte, ainsi que le Département de La Réunion, utilisent et
        exploitent les données personnelles renseignées dans ce formulaire, en
        accord avec la politique de protection des données.
        <br />
        Le porteur de projet a été informé :
        <ul>
          <li>du caractère obligatoire des informations collectées</li>
          <li>
            de la durée de conservation de ces données sur une période de 10 ans
          </li>
          <li>
            qu'il bénéficie d’un droit d’accès et de rectification aux
            informations qui le concernent
          </li>
        </ul>
        <br />
        Si le porteur de projet souhaite exercer ce droit et obtenir
        communication des informations le concernant, il peut s'adresser au
        service instructeur 3A CONSEIL Courriel :&nbsp;
        <a href="mailto:hpo@3areunion.com">hpo@3areunion.com</a>
        <br />
        Si le porteur de projet estime, après avoir contacté le service
        instructeur, que ses droits « Informatique et Libertés » ne sont pas
        respectés, il peut adresser une réclamation à la CNIL :&nbsp;
        <a href="https://www.cnil.fr/fr/plaintes/" target="_blank">
          https://www.cnil.fr/fr/plaintes/
        </a>
      </BodyText>
    </ContentLayout>
  );
}

const BodyText = ({ children }: { children: ReactNode }) => {
  return (
    <Text style="body" align="justify">
      {children}
    </Text>
  );
};
