import { Infer, enums } from "superstruct";

export const AnnexTypeValues = [
  "a2_product",
  "a2_service",
  "a2_salary",
  "a3",
  "a4",
  "a5",
] as const;

export const AnnexTypeSchema = enums(AnnexTypeValues);

type AnnexType = Infer<typeof AnnexTypeSchema>;

export default AnnexType;
