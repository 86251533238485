import ContentLayout from "@hpo/client/components/layout/ContentLayout";
import { BeneficiaryPaymentDetails } from "@hpo/client/models/BeneficiaryPayment";
import { getPaymentLabel } from "@hpo/client/models/BeneficiaryPayment";
import LoadingView from "@hpo/client/utilities/LoadingView";

type PaymentLockedProps = {
  payment: BeneficiaryPaymentDetails;
};

export default function PaymentLocked(props: PaymentLockedProps) {
  const { payment } = props;
  return (
    <ContentLayout title={getPaymentLabel(payment)}>
      <LoadingView message="Vous pourrez effectuer cette demande de subvention dès lors que la précédente aura été instruite." />
    </ContentLayout>
  );
}
