import { useCallback, useEffect, useState } from "react";
import { css } from "@emotion/react";
import { difference } from "lodash";
import R from "../utilities/R";
import Spacer from "../utilities/Spacer";
import SideBar from "./SideBar";
import T from "./text/Text";
import Button from "./Button";

const r = new R();

type ConfirmDialog = {
  id: string;
  type: "confirm";
  message: string;
  title?: string;
  yesLabel?: string;
  noLabel?: string;
  dangerous?: boolean;
  onYes: () => void;
  onNo: () => void;
};

let dialogs: Array<ConfirmDialog> = [];

// Confirm

function confirm(
  params: Omit<ConfirmDialog, "id" | "type" | "onYes" | "onNo">,
) {
  return new Promise<boolean>((res) => {
    dialogs = [
      ...dialogs,
      {
        ...params,
        id: Math.random().toString(),
        type: "confirm",
        onYes: () => res(true),
        onNo: () => res(false),
      },
    ];
    r.notify();
  });
}

export function Dialogs() {
  const all = r.useSelector(() => dialogs);
  const [opened, setOpened] = useState<string | null>(null);
  const [done, setDone] = useState<Array<string>>([]);

  useEffect(() => {
    const i = setTimeout(() => {
      if (opened === null) {
        const ids = difference(
          all.map((d) => d.id),
          done,
        );
        if (ids[0]) setOpened(ids[0]);
      } else {
        if (!all.map((d) => d.id).includes(opened)) {
          setOpened(null);
        }
      }
    }, 10);
    return () => clearTimeout(i);
  }, [all, opened, done]);

  const hideDialog = useCallback((id: string) => {
    setDone((d) => [...d, id]);
    setOpened(null);
  }, []);

  return all.map((d) => (
    <DialogSideBar
      key={d.id}
      dialog={d}
      visible={opened === d.id}
      onHide={() => hideDialog(d.id)}
    />
  ));
}

const Dialog = { confirm };

export default Dialog;

type DialogSideBarProps = {
  dialog: ConfirmDialog;
  visible: boolean;
  onHide: () => unknown;
};
function DialogSideBar(props: DialogSideBarProps) {
  if (props.dialog.type === "confirm") {
    return <ConfirmDialogSideBar {...props} dialog={props.dialog} />;
  } else {
    return null;
  }
}

type ConfirmDialogSideBarProps = {
  dialog: ConfirmDialog;
  visible: boolean;
  onHide: () => unknown;
};

function ConfirmDialogSideBar(props: ConfirmDialogSideBarProps) {
  const buttonsCss = css({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  });
  return (
    <SideBar visible={props.visible} modal zIndex={10000}>
      <T style="subtitle">{props.dialog.title || "Confirmation"}</T>
      <Spacer />
      <T>{props.dialog.message}</T>
      <Spacer />
      <div css={buttonsCss}>
        <Button
          label={props.dialog.noLabel || "Non"}
          onClick={() => {
            props.dialog.onNo();
            props.onHide();
          }}
        />
        <Spacer horizontal />
        <Button
          label={props.dialog.yesLabel || "Oui"}
          onClick={() => {
            props.dialog.onYes();
            props.onHide();
          }}
        />
      </div>
    </SideBar>
  );
}
