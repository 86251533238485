import { Fragment, useCallback } from "react";
import { useServerSdk } from "../../RootNavigator/services/ServerSdk";
import ProjectInquiryStatus from "./inquiry/ProjectInquiryStatus";
import TextTitleMinor from "@hpo/client/components/text/TextTitleMinor";
import { Analysis } from "@hpo/client/models/Analysis";
import { Annex, ProjectInstructor } from "@hpo/client/models/Project";
import { canProjectHaveInquiry } from "@hpo/client/utilities/helpers/ProjectHelper";

type ProjectAnnexHeaderProps = {
  title: string;
  annex: Annex;
  onReload: () => unknown;
  project: ProjectInstructor;
};

export default function ProjectAnnexHeader(props: ProjectAnnexHeaderProps) {
  const { title, annex, onReload, project } = props;

  const server = useServerSdk();
  const onAnalysisChange = useCallback(
    async (a: Analysis) => {
      await server.saveAnalysis(annex.resource, a);
      await onReload();
    },
    [annex.resource],
  );

  return (
    <Fragment>
      <div
        css={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <TextTitleMinor>{title}</TextTitleMinor>
        <ProjectInquiryStatus
          analysis={annex.analysis}
          onChange={onAnalysisChange}
          readonly={!canProjectHaveInquiry(project)}
        />
      </div>
    </Fragment>
  );
}
