import { Infer, nullable, number, object, string } from "superstruct";
import { AnalysisSchema } from "./Analysis";

export const ProposalSchema = object({
  id: string(),
  actionLabel: string(),
  expenseLabel: string(),
  annualCost: number(),
  elligibleHelp: number(),
  askedSubsidy: number(),
  remainingCharges: number(),
  proposal: nullable(number()),
  analysis: AnalysisSchema,
});

export type Proposal = Infer<typeof ProposalSchema>;
