import { useCallback, useMemo } from "react";
import { useLocation } from "wouter";
import { useServerSdk } from "../../RootNavigator/services/ServerSdk";
import { useAssistance } from "../../RootNavigator/services/Assistance";
import MyProjectAttachments from "./MyProjectAttachments";
import MyProjectCommitments from "./MyProjectCommitments";
import MyProjectCompliances from "./MyProjectCompliances";
import MyProjectDescriptions from "./MyProjectDescriptions";
import MyProjectIdentity from "./MyProjectIdentity";
import MyProjectIndicators from "./MyProjectIndicators";
import MyProjectSalaries from "./MyProjectSalaries";
import MyProjectServices from "./MyProjectServices";
import MyProjectActions from "./MyProjectActions";
import MyProjectProducts from "./MyProjectProducts";
import ContentLayout from "@hpo/client/components/layout/ContentLayout";
import {
  ProjectDraftBeneficiary,
  assertActions,
  assertAttachments,
  assertCommitments,
  assertCompliances,
  assertDescriptions,
  assertExpenses,
  assertIndicators,
  assertMainProjectData,
} from "@hpo/client/models/Project";
import Await from "@hpo/client/utilities/Await";
import { useRouteParam } from "@hpo/client/utilities/Navigation";
import Tabs from "@hpo/client/utilities/Tabs";
import { getProjectLabel } from "@hpo/client/utilities/helpers/ProjectHelper";
import { myProjectRoute, myProjectsRoute } from "@hpo/client/utilities/routes";
import useData from "@hpo/client/utilities/useData";
import AnnexType from "@hpo/client/utilities/enums/AnnexType";
import Button from "@hpo/client/components/Button";
import Spacer from "@hpo/client/utilities/Spacer";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import ErrorToast from "@hpo/client/utilities/ErrorToast";
import Toasting from "@hpo/client/components/Toasting";

export default function ProjectDraftScreen() {
  const server = useServerSdk();

  const projectId = useRouteParam(myProjectRoute, "projectId");

  const [project, projectData] = useData(
    () => server.getMyProject(projectId),
    [projectId],
  );

  return (
    <Await promise={project} hideReload>
      {(project) => {
        return (
          <ProjectView project={project} onRefresh={projectData.reloadSilent} />
        );
      }}
    </Await>
  );
}

type ProjectViewProps = {
  project: ProjectDraftBeneficiary;
  onRefresh: () => unknown;
};

export type MyProjectBaseProps = {
  project: ProjectDraftBeneficiary;
  onRefresh: () => unknown;
};

type TabKey =
  | "identity"
  | "commitments"
  | "compliances"
  | "attachments"
  | "descriptions"
  | "indicators"
  | "salaries"
  | "services"
  | "products"
  | "communication"
  | "transfer";

type TabData = {
  key: TabKey;
  title: string;
  children: React.ReactNode;
  show: boolean;
};

type TabValidity = Record<TabKey, boolean>;

function ProjectView(props: ProjectViewProps) {
  const { project, onRefresh } = props;

  const assistance = useAssistance();
  assistance.useChatWithInstructor();
  assistance.useVisibilityDecision(true);

  const server = useServerSdk();
  const [, navigate] = useLocation();

  const tabValidity: TabValidity = useMemo(() => {
    const validityChecker = (fn: () => void) => {
      try {
        fn();
        return true;
      } catch (_error) {
        return false;
      }
    };

    const identity = validityChecker(() => assertMainProjectData(project));
    const descriptions = validityChecker(() => assertDescriptions(project));
    const compliances = validityChecker(() => assertCompliances(project));
    const commitments = validityChecker(() => assertCommitments(project));
    const attachments = validityChecker(() => assertAttachments(project));
    const indicators = validityChecker(() => assertIndicators(project));
    const communication = validityChecker(() =>
      assertActions(project, "communication"),
    );
    const transfer = validityChecker(() => assertActions(project, "transfer"));
    const products = validityChecker(() => assertExpenses(project));
    const services = validityChecker(() => assertExpenses(project));
    const salaries = validityChecker(() => assertExpenses(project));

    return {
      identity,
      descriptions,
      compliances,
      commitments,
      attachments,
      indicators,
      products,
      services,
      salaries,
      communication,
      transfer,
    };
  }, [project]);

  const showForProjectType = useCallback(
    (type: AnnexType): boolean => {
      return project.annexes.find((annex) => annex.type === type) !== undefined;
    },
    [project.annexes],
  );

  const baseProps: Omit<MyProjectBaseProps, "updateTabValidity"> = {
    project,
    onRefresh,
  };

  const tabsData: TabData[] = [
    {
      key: "identity",
      title: "Identité",
      children: <MyProjectIdentity {...baseProps} />,
      show: true,
    },
    {
      key: "commitments",
      title: "Engagements",
      children: <MyProjectCommitments {...baseProps} />,
      show: true,
    },
    {
      key: "compliances",
      title: "Elligibilités",
      children: <MyProjectCompliances {...baseProps} />,
      show: true,
    },
    {
      key: "attachments",
      title: "Pièces jointes",
      children: <MyProjectAttachments {...baseProps} />,
      show: true,
    },
    {
      key: "descriptions",
      title: "Description",
      children: <MyProjectDescriptions {...baseProps} />,
      show: true,
    },
    {
      key: "indicators",
      title: "Indicateurs",
      children: <MyProjectIndicators {...baseProps} />,
      show: true,
    },
    {
      key: "salaries",
      title: "Salaires",
      children: <MyProjectSalaries {...baseProps} />,
      show: showForProjectType("a2_salary"),
    },
    {
      key: "services",
      title: "Services",
      children: <MyProjectServices {...baseProps} />,
      show: showForProjectType("a2_service"),
    },
    {
      key: "products",
      title: "Produits",
      children: <MyProjectProducts {...baseProps} />,
      show: showForProjectType("a2_product"),
    },
    {
      key: "communication",
      title: "Plan de comm'",
      children: <MyProjectActions {...baseProps} actionType="communication" />,
      show: showForProjectType("a4"),
    },
    {
      key: "transfer",
      title: "Plan de transfert",
      children: <MyProjectActions {...baseProps} actionType="transfer" />,
      show: showForProjectType("a5"),
    },
  ];

  const [validateProject, validateStatus] = useSubmitCallback(async () => {
    await server.validateProject(project.id);
    onRefresh();
    Toasting.success("Succès", "Projet soumis aux instructeurs.");
    const route = myProjectsRoute.getRootUrl();
    navigate("/.." + route, { replace: true });
  }, [project, tabValidity]);

  return (
    <ContentLayout title={getProjectLabel(project)} titleOneLine={false}>
      <Button
        label="Soumettre le projet aux instructeurs"
        onClick={validateProject}
        loading={validateStatus.running}
      />
      <Spacer />
      <ErrorToast error={validateStatus.error} />
      <Tabs showSingleTab>
        {tabsData.map((tab) => {
          if (!tab.show) return null;

          const validity = tabValidity[tab.key];

          return (
            <Tabs.Tab
              title={tab.title}
              key={tab.key}
              id={tab.key}
              icon={
                validity === null ? undefined : validity ? "check" : "error"
              }
            >
              {tab.children}
            </Tabs.Tab>
          );
        })}
      </Tabs>
    </ContentLayout>
  );
}
