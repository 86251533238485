import { PropsWithChildren, createContext, useContext, useMemo } from "react";

export default function createInjectable<Value>(name: string) {
  const context = createContext<{ injected: Value } | null>(null);
  const Provider = (props: PropsWithChildren<{ value: Value }>) => {
    const value = useMemo(() => ({ injected: props.value }), [props.value]);
    return <context.Provider value={value}>{props.children}</context.Provider>;
  };
  const useInjected = () => {
    const inContext = useContext(context);
    if (inContext === null) throw new Error(`No ${name} injected`);
    return inContext.injected;
  };
  return [Provider, useInjected] as const;
}
