import { useState } from "react";
import { useServerSdk } from "../../../RootNavigator/services/ServerSdk";
import Button from "@hpo/client/components/Button";
import SideBar from "@hpo/client/components/SideBar";
import TextTitleMinor from "@hpo/client/components/text/TextTitleMinor";
import { ProductBeneficiary } from "@hpo/client/models/Expense";
import ErrorToast from "@hpo/client/utilities/ErrorToast";
import Spacer from "@hpo/client/utilities/Spacer";
import DateField from "@hpo/client/utilities/fields/DateField";
import NumberField from "@hpo/client/utilities/fields/NumberField";
import TextField from "@hpo/client/utilities/fields/TextField";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import { ProjectDraftBeneficiary } from "@hpo/client/models/Project";
import MessageException from "@hpo/client/utilities/errors/MessageException";
import Toasting from "@hpo/client/components/Toasting";
import ExpenseType from "@hpo/client/utilities/enums/ExpenseType";

type ProductSideBarProps = {
  project: ProjectDraftBeneficiary;
  product: ProductBeneficiary | null;
  visible: boolean;
  onDone: (newProduct: string | null) => unknown;
};

/** @todo : /** @todo : voir navigators/MyProjectsNavigator/MyProjectScreen/MyProjectActions/ActionSideBar.tsx */
export default function ProductSideBar(props: ProductSideBarProps) {
  const { project, product, visible, onDone } = props;
  const server = useServerSdk();

  const [label, setLabel] = useState<string | null>(product && product.label);

  const [provider, setProvider] = useState<string | null>(
    product && product.provider,
  );

  const [quotationReference, setQuotationReference] = useState<string | null>(
    product && product.quotationReference,
  );

  const [quotationDate, setQuotationDate] = useState<string | null>(
    product && product.quotationDate,
  );

  const [quotationDetail, setQuotationDetail] = useState<string | null>(
    product && product.quotationDetail,
  );

  const [quantity, setQuantity] = useState<number | null>(
    product && product.quantity,
  );

  const [annualCost, setAnnualCost] = useState<number | null>(
    product && product.annualCost,
  );

  const [saveProduct, savingStatus] = useSubmitCallback(async () => {
    if (
      !label ||
      !provider ||
      !quotationReference ||
      !quotationDate ||
      !quotationDetail ||
      quantity === null ||
      annualCost === null
    ) {
      throw new MessageException("Veuillez remplir tous les champs", null);
    }

    if (product) {
      await server.updateExpense(project.id, product.id, {
        label,
        type: ExpenseType.Product,
        provider,
        quotationReference,
        quotationDate,
        quotationDetail,
        quantity,
        annualCost,
      });
      Toasting.success("Succès", "Dépense mise à jour");
      onDone(product.id);
    } else {
      const expense = await server.createExpense(project.id, {
        label,
        type: ExpenseType.Product,
        provider,
        quotationReference,
        quotationDate,
        quotationDetail,
        quantity,
        annualCost,
      });
      Toasting.success("Succès", "Dépense créée");
      onDone(expense);
    }
  }, [
    label,
    provider,
    quotationReference,
    quotationDate,
    quotationDetail,
    quantity,
    annualCost,
    product,
  ]);

  return (
    <SideBar
      visible={visible}
      onHide={() => onDone(null)}
      header={
        <TextTitleMinor>
          {product ? product.label : "Nouveau devis produit"}
        </TextTitleMinor>
      }
    >
      <ErrorToast error={savingStatus.error} />
      <TextField
        required
        id="label"
        label="Intitulé"
        value={label}
        onChange={setLabel}
      />
      <Spacer />
      <TextField
        required
        id="provider"
        label="Fournisseur"
        value={provider}
        onChange={setProvider}
      />
      <Spacer />
      <TextField
        required
        id="quotationReference"
        label="N° de devis"
        value={quotationReference}
        onChange={setQuotationReference}
      />
      <Spacer />
      <DateField
        required
        id="quotationDate"
        label="Date du devis"
        value={quotationDate}
        onChange={setQuotationDate}
      />
      <Spacer />
      <TextField
        required
        id="quotationDetail"
        label="Objets de la dépense (détail des lignes du devis)"
        value={quotationDetail}
        onChange={setQuotationDetail}
      />
      <Spacer />
      <NumberField
        id="quantity"
        value={quantity}
        onChange={setQuantity}
        label="Quantité"
        unit="other"
        allowZero
        required
      />
      <Spacer />
      <NumberField
        id="annualCost"
        value={annualCost}
        onChange={setAnnualCost}
        label="Coût annuel"
        unit="euro"
        allowZero
        required
      />
      <Spacer scale={2} />
      <Button
        onClick={saveProduct}
        label="Valider"
        loading={savingStatus.running}
      />
    </SideBar>
  );
}
