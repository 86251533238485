import Button from "@hpo/client/components/Button";
import T from "@hpo/client/components/text/Text";
import ErrorView from "@hpo/client/utilities/ErrorView";
import Page from "@hpo/client/utilities/Page";
import Spacer from "@hpo/client/utilities/Spacer";
import MessageException from "@hpo/client/utilities/errors/MessageException";
import FieldsForm from "@hpo/client/utilities/fields/FieldsForm";
import TextField from "@hpo/client/utilities/fields/TextField";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import { useState } from "react";
import { useServerSdk } from "./services/ServerSdk";
import Toasting from "@hpo/client/components/Toasting";
import { passwordStrength } from "check-password-strength";

export default function PasswordRenewalScreen() {
  const server = useServerSdk();

  const [password, setPassword] = useState<string | null>(null);
  const [confirmation, setConfirmation] = useState<string | null>(null);

  const [onSubmit, submission] = useSubmitCallback(async () => {
    if (!password || !confirmation)
      throw new MessageException(
        "Saisissez un mot de passe et recopiez le en dessous",
        null,
      );
    if (password !== confirmation)
      throw new MessageException(
        "Les 2 mots de passe saisis son différents",
        null,
      );
    const strength = passwordStrength(password, [
      { id: 0, value: "Weak", minDiversity: 0, minLength: 0 },
      { id: 1, value: "Strong", minDiversity: 4, minLength: 12 },
    ]);
    if (strength.id === 0) {
      throw new MessageException(
        "Saisissez un mot de passe plus complexe, avec des lettres en minuscule et en majuscule, des chiffres et des caractères spéciaux (accents, ponctuation, etc)",
        null,
      );
    }
    await server.updateMyPassword(password);
    Toasting.success("C'est fait", "Votre mot de passe a été modifié");
  }, [password, confirmation]);

  return (
    <Page>
      <T style="title">Changement de votre mot de passe</T>
      <Spacer />
      <T>Pour votre sécurité, vous devez changer votre mot de passe.</T>
      <Spacer />
      <T tag="div">
        Votre mot de passe doit :
        <ul>
          <li>Contenir au moins 12 caractères</li>
          <li>
            Contenir des lettres en minuscule et en majuscule, des chiffres et
            des caractères spéciaux (accents, ponctuation, etc)
          </li>
        </ul>
      </T>
      <Spacer />
      <FieldsForm onSubmit={onSubmit}>
        <TextField
          id="password"
          label="Nouveau mot de passe"
          value={password}
          onChange={setPassword}
          password={true}
        />
        <Spacer />
        <TextField
          id="confirmation"
          label="Répétez votre nouveau mot de passe"
          value={confirmation}
          onChange={setConfirmation}
          password={true}
        />
        <Spacer />
        <ErrorView error={submission.error} />
        <Spacer />
        <Button submit label={"Continuer"} />
      </FieldsForm>
    </Page>
  );
}
