export default function getErrorsStack(
  error: unknown,
  previous: Array<Error> = [],
): Array<unknown> {
  const output: Array<Error> = [...previous];
  if (error instanceof Error) {
    output.push(error);
    if (error.cause) return getErrorsStack(error.cause, output);
    else return output;
  } else {
    return output;
  }
}
