import { Infer, object, string } from "superstruct";
import { postalCodeSchema } from "../utilities/models/PostalCode";

// Address

export const AddressSchema = object({
  street: string(),
  postalCode: postalCodeSchema,
  town: string(),
});
export type Address = Infer<typeof AddressSchema>;
