import { getPaymentLabel } from "@hpo/client/models/BeneficiaryPayment";
import ContentLayout from "@hpo/client/components/layout/ContentLayout";
import LoadingView from "@hpo/client/utilities/LoadingView";
import { BeneficiaryPaymentDetails } from "@hpo/client/models/BeneficiaryPayment";

type PaymentAnalyseProps = {
  payment: BeneficiaryPaymentDetails;
};

export default function PaymentAnalyse(props: PaymentAnalyseProps) {
  const { payment } = props;
  return (
    <ContentLayout title={getPaymentLabel(payment)}>
      <LoadingView
        message="Votre demande de subvention sera prochainement instruite. Les documents que vous avez transmis ont bien été reçus par le service instructeur. Vous serez
        informé.e par email lorsque l'instruction sera terminée."
      />
    </ContentLayout>
  );
}
