import { last, flatten, uniqBy } from "lodash";
import { RefObject, useEffect } from "react";
import { atom, useAtomValue, useSetAtom } from "jotai";
import { OrganizationSummary } from "@hpo/client/models/Organization";
import createInjectable from "@hpo/client/utilities/createInjectable";

export enum ASSISTANCE_MODE_TYPE {
  CHAT = "CHAT",
  HELP = "HELP",
}

export type AssistanceModeChat = {
  type: ASSISTANCE_MODE_TYPE.CHAT;
  organizations: Array<OrganizationSummary>;
};

export type AssistanceModeHelp = { type: ASSISTANCE_MODE_TYPE.HELP };

export type AssistanceMode = AssistanceModeChat | AssistanceModeHelp;

export type HelpDisplay = "always" | "mouseover";
export type Help = {
  ref: RefObject<HTMLDivElement>;
  visible: boolean;
  display: HelpDisplay;
  inactive: boolean;
};

export default class Assistance {
  /*
    Partie dédiée aux changements de la visibilité du panneau d'assistance
    Dans certains contextes, le panneau doit être visibles. Dans d'autres, non.
    Il doit être possible de cacher la panneau dans certaines parties de l'app, et de le ré-afficher dans des sous-parties de cette partie.
  */

  private visibilityDecisionsAtom = atom<
    Array<{
      decisionId: number;
      visible: boolean;
    }>
  >([]);

  useVisibilityDecision(visible: boolean = true) {
    const setVisibilityDecisions = useSetAtom(this.visibilityDecisionsAtom);
    useEffect(() => {
      // On définit un id au hasard, propre et constant à la partie qui appelle useVisibilityDecision()
      const decisionId = Math.random();
      // On ajoute cet id, avec la visibilté associé dans l'état
      setVisibilityDecisions((current) => [
        ...current,
        { decisionId, visible },
      ]);
      return () => {
        // Lorsque la partie qui appelle useVisibilityDecision() est démontée, on retire l'élément du tableau
        setVisibilityDecisions((current) =>
          current.filter((c) => c.decisionId !== decisionId),
        );
      };
    }, [visible]);
  }

  useIsVisible() {
    const visibilityDecisions = useAtomValue(this.visibilityDecisionsAtom);
    const lastDecision = last(visibilityDecisions);
    return lastDecision ? lastDecision.visible : true;
  }

  /*
    Partie dédiée à l'affichage ou non du chat
  */

  private chatsAtom = atom<
    Array<{
      decisionId: number;
      targets: Array<OrganizationSummary | true>; // true pour ouvrir le chat avec les instructeurs
    }>
  >([]);

  useChatWithInstructor() {
    const setChats = useSetAtom(this.chatsAtom);
    useEffect(() => {
      const decisionId = Math.random();
      setChats((current) => [...current, { decisionId, targets: [true] }]);
      return () => {
        setChats((current) =>
          current.filter((c) => c.decisionId !== decisionId),
        );
      };
    }, []);
  }

  useChatWith(organizations: Array<OrganizationSummary>) {
    const setChats = useSetAtom(this.chatsAtom);
    useEffect(() => {
      const decisionId = Math.random();
      setChats((current) => [
        ...current,
        { decisionId, targets: organizations },
      ]);
      return () => {
        setChats((current) =>
          current.filter((c) => c.decisionId !== decisionId),
        );
      };
    }, [...organizations.map((o) => o.id)]);
  }

  useChats() {
    const chats = useAtomValue(this.chatsAtom);
    const targets = flatten(chats.map((c) => c.targets));
    return uniqBy(targets, (o) => (o === true ? true : o.id));
  }

  // /*
  // Partie dédiée aux messages d'aide
  // Les messages d'aide sont affichés via le composant <WithHelp />, qui wrappe un contenu et lui ajoute une aide
  // positioné à sa droite (dans l'espace prévu dans le panneau latéral)
  // Cela peut conduire à ce que certains messages se superposent, les rendant illisibles.
  // Cette partie a vocation à cacher certains messages, en cas de superposition
  // */

  // private helps: Array<Help> = [];
  // private focusedHelp: Help | null = null;

  // useHelp(
  //   ref: RefObject<HTMLDivElement>,
  //   display: HelpDisplay,
  //   inactive: boolean,
  // ) {
  //   const help = useMemo(() => {
  //     const obj: Help = { ref, display, visible: false, inactive };
  //     return obj;
  //   }, [ref, display, inactive]);

  //   useEffect(() => {
  //     this.helps.push(help);
  //     this.computeHelpVisibilities();
  //     return () => {
  //       this.helps = this.helps.filter((h) => h.ref !== ref);
  //       this.computeHelpVisibilities();
  //     };
  //   }, [help]);

  //   return this.r.useSelector(() => {
  //     return this.helps.find((h) => h.ref === ref) || help;
  //   });
  // }

  // setFocusedHelp(help: Help, mouseover: boolean) {
  //   if (mouseover) this.focusedHelp = help;
  //   else if (this.focusedHelp && this.focusedHelp.ref === help.ref)
  //     this.focusedHelp = null;
  //   this.computeHelpVisibilities();
  // }

  // computeHelpVisibilities() {
  //   if (this.focusedHelp) {
  //     this.helps.forEach((h) => (h.visible = false));
  //     this.focusedHelp.visible = !this.focusedHelp.inactive;
  //   } else {
  //     this.helps.forEach((h) => {
  //       h.visible = h.display === "always" && !h.inactive;
  //     });
  //     this.helps.forEach((help, index) => {
  //       if (!help.visible) return;
  //       const after = this.helps.slice(index + 1);
  //       const el1 = help.ref.current;
  //       if (!el1) return;
  //       const rect1 = el1.getBoundingClientRect();
  //       after.forEach((a) => {
  //         if (!a.visible) return;
  //         const el2 = a.ref.current;
  //         if (!el2) return;
  //         const rect2 = el2.getBoundingClientRect();
  //         if (doesRectangleIntersct(rect1, rect2)) {
  //           a.visible = false;
  //         }
  //       });
  //     });
  //   }
  //   this.helps = this.helps.map((h) => ({ ...h }));
  //   this.r.notify();
  // }
}

// function doesRectangleIntersct(r1: DOMRect, r2: DOMRect) {
//   return !(
//     r2.left >= r1.right ||
//     r2.right <= r1.left ||
//     r2.top >= r1.bottom ||
//     r2.bottom <= r1.top
//   );
// }

export const [AssistanceProvider, useAssistance] =
  createInjectable<Assistance>("Assistance");
