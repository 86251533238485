import { css } from "@emotion/react";
import { atom, useAtomValue, useSetAtom } from "jotai";
import {
  PropsWithChildren,
  Fragment,
  useEffect,
  useState,
  useCallback,
} from "react";
import { HelpDisplay, useAssistance } from "../../services/Assistance";
import HelpZone from "./HelpZone";
import T from "@hpo/client/components/text/Text";

type WithHelpProps = PropsWithChildren<{
  title?: string;
  text?: React.ReactNode;
  color?: string;
  display?: HelpDisplay;
  inactive?: boolean;
}>;

const instancesCounterAtom = atom(0);
const hasInstancesAtom = atom((get) => get(instancesCounterAtom) > 0);

export default function WithHelp(props: WithHelpProps) {
  const { inactive, display = "always" } = props;
  const setInstances = useSetAtom(instancesCounterAtom);
  const haveInstances = useAtomValue(hasInstancesAtom);
  const areHelpsVisible = useAssistance().useIsVisible();

  useEffect(() => {
    if (inactive) return;
    setInstances((prev) => prev + 1);
    return () => {
      setInstances((prev) => prev - 1);
    };
  }, [inactive]);

  useEffect(() => {
    if (haveInstances) {
      const onScroll = () => {
        document.body.style.setProperty(
          "--with-help-offset",
          `${document.documentElement.scrollTop * -1}px`,
        );
      };
      document.addEventListener("scroll", onScroll);
      return () => document.removeEventListener("scroll", () => onScroll);
    }
  }, [haveInstances]);

  const [mouseOver, setMouseOver] = useState(false);

  const onMouseEnter = useCallback(() => {
    setMouseOver(true);
  }, []);

  const onMouseLeave = useCallback(() => {
    setMouseOver(false);
  }, []);

  const children =
    display === "mouseover" ? (
      <span onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
        {props.children}
      </span>
    ) : (
      props.children
    );

  const inScreen = areHelpsVisible && !inactive;

  let visible: boolean = true;
  if (!areHelpsVisible) visible = false;
  else if (inactive) visible = false;
  else if (display === "mouseover" && !mouseOver) visible = false;

  const containerCss = css({
    position: "fixed",
    right: inScreen ? 0 : "-33vw",
    width: "33vw",
    transform: `translateY(var(--with-help-offset))`,
    opacity: visible ? 1 : 0,
    pointerEvents: visible ? "auto" : "none",
    transition: "right 300ms, opacity 300ms",
  });

  return (
    <Fragment>
      <div css={containerCss}>
        <HelpZone>
          {props.title ? <T style="bold">{props.title}</T> : null}
          <T>{props.text}</T>
        </HelpZone>
      </div>
      {children}
    </Fragment>
  );
}
