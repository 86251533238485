import { without } from "lodash";
import Exception from "./errors/Exception";

export default class Cleaner {
  private fns: Array<CleanFn> = [];
  private keys: Record<string, CleanFn | null> = {};

  add(...fns: Array<CleanFn>) {
    this.fns.push(...fns);
  }

  register(key: string, fn: CleanFn) {
    this.add(fn);
    if (this.keys[key]) {
      throw new CleanerKeyIsAlreadyRegistered({ cause: null, tags: { key } });
    }
    this.keys[key] = fn;
  }

  unregister(key: string, preventCalling: boolean = false) {
    const fn = this.keys[key];
    if (!fn) {
      throw new CleanerKeyIsAlreadyUnregistered({ cause: null, tags: { key } });
    }
    if (!preventCalling) {
      fn();
    }
    this.fns = without(this.fns, fn);
    this.keys[key] = null;
  }

  swallow(cleaner: Cleaner) {
    this.fns.push(() => cleaner.call());
  }

  call() {
    this.fns.map((fn) => fn());
  }

  async clear() {
    this.fns = [];
  }

  static empty() {
    // Do nothing
  }
}

export type CleanFn = () => void;
export class CleanerKeyIsAlreadyRegistered extends Exception {}
export class CleanerKeyIsAlreadyUnregistered extends Exception {}
