import { CSSProperties, Fragment, ReactNode } from "react";
import Table from "../navigators/ConventionNavigator/PaymentScreen/Table";
import Button from "./Button";

type PickReactNode<T extends Record<string, unknown>> = {
  [K in keyof T]: T[K] extends string | number | boolean ? K : never;
};

export type DataTableColumn<T extends Record<string, unknown>> = {
  header?: string;
  style?: CSSProperties;
} & (
  | { field: keyof PickReactNode<T>; body?: never }
  | { body: (a: T) => ReactNode; field?: never }
);

export type DataTableActions<T> = {
  onEditClick?: (e: T) => unknown;
  onDeleteClick?: (e: T) => unknown;
  editLoading?: boolean;
  deleteLoading?: boolean;
  editDisabled?: boolean;
  deleteDisabled?: boolean;
};

export type DataTableProps<T extends Record<string, unknown>> = {
  value: Array<T>;
  columns: DataTableColumn<T>[];
  actions?: DataTableActions<T>;
};

export default function DataTable<T extends Record<string, unknown>>(
  props: DataTableProps<T>,
) {
  const { columns, value, actions } = props;

  const showActionsColumns =
    actions && (actions.onEditClick || actions.onDeleteClick);

  return (
    <Table>
      <thead>
        <tr>
          {columns.map((c, i) => (
            <th key={i}>{c.header}</th>
          ))}
          {showActionsColumns ? <th>Actions</th> : null}
        </tr>
      </thead>
      <tbody>
        {value.map((v, i) => {
          return (
            <tr key={i}>
              {columns.map((c, j) => {
                const field = "field" in c && c.field ? c.field : null;
                const body = "body" in c && c.body ? c.body : null;
                if (field) {
                  return (
                    <td key={j}>
                      <Fragment>{v[field] as ReactNode}</Fragment>
                    </td>
                  );
                } else if (body) {
                  return <td key={j}>{body(v)}</td>;
                } else {
                  return null;
                }
              })}
              {showActionsColumns ? (
                <td>
                  <DataTableActions data={v} actions={actions} />
                </td>
              ) : null}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

type DataTableActionsProps<T extends Record<string, unknown>> = {
  data: T;
  actions: DataTableActions<T>;
};

function DataTableActions<T extends Record<string, unknown>>(
  props: DataTableActionsProps<T>,
) {
  const { data, actions } = props;
  const {
    onEditClick,
    onDeleteClick,
    editLoading,
    deleteLoading,
    editDisabled,
    deleteDisabled,
  } = actions;

  return (
    <div style={{ display: "flex", gap: 20 }}>
      {onEditClick && (
        <Button
          key="edit"
          icon="pencil"
          onClick={() => onEditClick(data)}
          disabled={editDisabled}
          loading={editLoading}
        />
      )}
      {onDeleteClick && (
        <Button
          key="delete"
          icon="trash"
          style="danger"
          onClick={() => onDeleteClick(data)}
          disabled={deleteDisabled}
          loading={deleteLoading}
        />
      )}
    </div>
  );
}
