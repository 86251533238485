import { DependencyList, useCallback, useState } from "react";

export type SubmitStatus<D> = {
  submitted: boolean;
  running: boolean;
  error: unknown;
  promise: Promise<D> | null;
};

export function useSubmitCallback<TValue, TArgs extends Array<any>>(
  fn: (...args: TArgs) => TValue | Promise<TValue>,
  deps: DependencyList,
) {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [running, setRunning] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();
  const [promise, setPromise] = useState<Promise<TValue> | null>(null);

  const submitFn = useCallback(async (...args: TArgs) => {
    setSubmitted(true);
    setError(null);
    setRunning(true);
    const promise = new Promise<TValue>((resolve, reject) => {
      try {
        const execution = fn(...args);
        if (execution instanceof Promise) {
          execution
            .then((value) => {
              setRunning(false);
              setError(null);
              resolve(value);
            })
            .catch((err) => {
              setRunning(false);
              setError(err);
              reject(err);
            });
        } else {
          resolve(execution);
        }
      } catch (err) {
        // execution is not a Promise, and thrown error
        setRunning(false);
        setError(err);
        reject(err);
      }
    });
    setPromise(promise);
    return promise.then(
      () => {},
      () => {},
    );
  }, deps);

  const status: SubmitStatus<TValue> = { submitted, running, error, promise };

  return [submitFn, status] as const;
}
