import { css } from "@emotion/react";
import React, { PropsWithChildren } from "react";
import T from "../components/text/Text";
import Icon from "../components/Icon";
import Spacer from "./Spacer";

type EmptyViewProps = PropsWithChildren<{
  message?: string;
  grow?: boolean;
}>;

export default function EmptyView(props: EmptyViewProps) {
  const { message, grow } = props;

  const containerCss = css({
    flexGrow: grow ? 1 : 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
  });

  return (
    <div css={containerCss}>
      <Icon name="help" size={60} opacity={0.2} />
      <Spacer />
      <T align="center">{message}</T>
      {props.children}
    </div>
  );
}
