import { Infer, enums } from "superstruct";

export const InvestigationDecisionValues = [
  "favourable",
  "favourable-with-reservations",
  "unfavourable",
] as const;

export const InvestigationDecisionSchema = enums(InvestigationDecisionValues);

type InvestigationDecision = Infer<typeof InvestigationDecisionSchema>;

export const InvestigationDecisionLabels: Record<
  InvestigationDecision,
  string
> = {
  favourable: "Favorable",
  "favourable-with-reservations": "Favorable avec réserves",
  unfavourable: "Défavorable",
} as const;

export default InvestigationDecision;
