import { useState } from "react";
import Spacer from "@hpo/client/utilities/Spacer";

import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import FieldsForm from "@hpo/client/utilities/fields/FieldsForm";
import Button from "@hpo/client/components/Button";
import { Tag } from "@hpo/client/models/Tag";
import MultiSelectField from "@hpo/client/utilities/fields/MultiSelectField";

type IndicatorTagsEditorProps = {
  allTags: Array<Tag>;
  indicatorTags: Array<Tag>;
  onTagsDone: (tags: Array<Tag>) => unknown;
};

export default function IndicatorTagsEditor(props: IndicatorTagsEditorProps) {
  const [tags, setTags] = useState<Array<Tag>>(props.indicatorTags);

  const [onSubmit, submission] = useSubmitCallback(async () => {
    await props.onTagsDone(tags);
  }, [tags]);

  return (
    <FieldsForm onSubmit={onSubmit}>
      <MultiSelectField
        label="Etiquettes de l'indicateur"
        value={tags}
        onChange={setTags}
        options={props.allTags}
        keyExtractor={(tag) => tag.id}
        renderOption={(tag) => ({ label: tag.label })}
      />
      <Spacer />
      <Button submit label="Valider" loading={submission.running} />
    </FieldsForm>
  );
}
