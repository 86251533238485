import { Fragment, useState } from "react";
import OrganizationSideBar from "./OrganizationSideBar";
import Button from "@hpo/client/components/Button";
import { Organization } from "@hpo/client/models/Organization";
import List, { ListItem } from "@hpo/client/utilities/List";
import PageHeader from "@hpo/client/utilities/PageHeader";
import Spacer from "@hpo/client/utilities/Spacer";

type OrganizationsViewProps = {
  organizations: Array<Organization>;
  onReload: () => void;
};

export default function OrganizationsView(props: OrganizationsViewProps) {
  const { organizations, onReload } = props;

  const [openOrganization, setOpenOrganization] = useState<
    string | "new" | null
  >(null);

  const onOrganizationDone = async () => {
    setOpenOrganization(null);
    onReload();
  };

  return (
    <Fragment>
      <PageHeader
        title="Bénéficiaires"
        titleStyle="subtitle"
        right={
          <Button
            icon="plus"
            label="Nouveau bénéficiaire"
            onClick={() => setOpenOrganization("new")}
          />
        }
      />
      <Spacer />
      <OrganizationsList
        organizations={organizations}
        onClick={(orga) => setOpenOrganization(orga)}
      />
      {organizations.map((orga) => (
        <OrganizationSideBar
          key={orga.id}
          onDone={onOrganizationDone}
          visible={orga.id === openOrganization}
          organization={orga}
        />
      ))}
      <OrganizationSideBar
        onDone={onOrganizationDone}
        visible={openOrganization === "new"}
        organization={null}
      />
    </Fragment>
  );
}

type OrganizationsListProps = {
  organizations: Array<Organization>;
  onClick: (orga: string) => void;
};

function OrganizationsList({ organizations, onClick }: OrganizationsListProps) {
  return (
    <List
      data={organizations}
      renderItem={(orga) => {
        return (
          <ListItem
            label={orga.businessName}
            help={`${orga.legalRepresentativeName} (${orga.legalRepresentativePosition})`}
            onClick={() => onClick(orga.id)}
          />
        );
      }}
    />
  );
}
