import { Fragment, useMemo } from "react";
import ProjectAnnexHeader from "../ProjectAnnexHeader";
import DataTable, { DataTableColumn } from "@hpo/client/components/DataTable";
import { Action } from "@hpo/client/models/Action";
import { Annex, ProjectInstructor } from "@hpo/client/models/Project";
import Spacer from "@hpo/client/utilities/Spacer";
import Units from "@hpo/client/utilities/Units";

type ProjectTabTransferProps = {
  project: ProjectInstructor;
  annex: Annex;
  onReload: () => unknown;
};

export default function ProjectTabTransfer(props: ProjectTabTransferProps) {
  const { project, annex, onReload } = props;

  const transfertActions: Action[] = useMemo(() => {
    return project.actions.filter((action) => action.type === "transfer");
  }, [project.actions]);

  const columns: DataTableColumn<Action>[] = [
    { field: "label", header: "Intitulé des actions" },
    { field: "goal", header: "Objectifs du transfert aux agriculteurs" },
    {
      header: "Cout total prévisionnel éligible",
      body: (e) => {
        return Units.euro.display(e.cost);
      },
    },
    { field: "means", header: "Moyens / Outils pour réaliser le transfert" },
    { field: "planning", header: "Planning prévisionnel" },
  ];

  return (
    <Fragment>
      <ProjectAnnexHeader
        title="Plan de transfert"
        annex={annex}
        onReload={onReload}
        project={project}
      />
      <Spacer />
      <DataTable value={transfertActions as Array<Action>} columns={columns} />
    </Fragment>
  );
}
