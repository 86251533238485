import { css } from "@emotion/react";
import { PropsWithChildren, ReactNode } from "react";

type FieldWithButtonsProps = PropsWithChildren<{
  buttons: ReactNode;
}>;

export default function FieldWithButtons(props: FieldWithButtonsProps) {
  const wrapperCss = css({
    display: "flex",
  });

  const inputCss = css({
    flex: 1,
  });
  return (
    <div css={wrapperCss}>
      <div css={inputCss}>{props.children}</div>
      <div>{props.buttons}</div>
    </div>
  );
}
