import { Infer, number, object, pick, string } from "superstruct";
import { ProjectInstructorSchema, ProjectReferenceSchema } from "./Project";

// FundingSummary

export const FundingSummarySchema = object({
  id: string(),
  project: ProjectReferenceSchema,
  amount: number(),
});

export type FundingSummary = Infer<typeof FundingSummarySchema>;

// FundingDetails

export const FundingDetailsSchema = object({
  id: string(),
  project: ProjectReferenceSchema,
  amount: number(),
});

export type FundingDetails = Infer<typeof FundingDetailsSchema>;

// InstructorFundingDetails

export const InstructorFundingDetailsSchema = object({
  id: string(),
  project: pick(ProjectInstructorSchema, [
    "id",
    "type",
    "label",
    "periods",
    "indicators",
  ]),
  amount: number(),
});

export type InstructorFundingDetails = Infer<
  typeof InstructorFundingDetailsSchema
>;
