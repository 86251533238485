import { Fragment, useMemo, useState } from "react";
import { MyProjectBaseProps } from "..";
import SalarySideBar from "./SalarySideBar";
import DataTable, { DataTableColumn } from "@hpo/client/components/DataTable";
import { SalaryBeneficiary } from "@hpo/client/models/Expense";
import Spacer from "@hpo/client/utilities/Spacer";
import Button from "@hpo/client/components/Button";
import Units from "@hpo/client/utilities/Units";
import PageHeader from "@hpo/client/utilities/PageHeader";
import Dialog from "@hpo/client/components/Dialog";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import { useServerSdk } from "@hpo/client/navigators/RootNavigator/services/ServerSdk";
import Toasting from "@hpo/client/components/Toasting";
import { distpatchExpenses } from "@hpo/client/utilities/helpers/ExpenseHelper";

type MyProjectSalariesProps = MyProjectBaseProps;

export default function MyProjectSalaries(props: MyProjectSalariesProps) {
  const { project, onRefresh } = props;

  const server = useServerSdk();

  const salaries = useMemo(
    () => distpatchExpenses(project.expenses).salary,
    [project.expenses],
  );

  const [open, setOpen] = useState<string | "new" | null>(null);

  const columns: Array<DataTableColumn<SalaryBeneficiary>> = [
    {
      field: "label",
      header: "Libellé",
    },
    {
      field: "employeeName",
      header: "Nom de l'employé",
    },
    {
      field: "employeePosition",
      header: "Fonction",
    },
    {
      header: "Coût annuel",
      body: (salary: SalaryBeneficiary) => {
        return Units.euro.display(salary.annualCost);
      },
    },
    {
      header: "Affectation",
      body: (salary: SalaryBeneficiary) => {
        return Units.percentage.display(salary.affectation);
      },
    },
  ];

  const onDone = () => {
    setOpen(null);
    onRefresh();
  };

  const [deleteSalary, deletingStatus] = useSubmitCallback(
    async (salary: SalaryBeneficiary) => {
      const confirm = await Dialog.confirm({
        message: "Cette ligne de salaire sera supprimée ce manière définitive",
      });

      if (!confirm) return;

      await server.deleteExpense(project.id, salary.id);

      Toasting.success("Succès", "Ligne de salaire supprimée");

      onRefresh();
    },
    [],
  );

  return (
    <Fragment>
      <PageHeader
        title="Salaires"
        titleStyle="subtitle"
        right={
          <Button icon="plus" label="Ajouter" onClick={() => setOpen("new")} />
        }
      />
      <Spacer />
      <DataTable
        columns={columns}
        value={salaries}
        actions={{
          onEditClick: (salary: SalaryBeneficiary) => setOpen(salary.id),
          onDeleteClick: deleteSalary,
          deleteLoading: deletingStatus.running,
          editDisabled: deletingStatus.running,
        }}
      />
      {salaries.map((salary) => (
        <SalarySideBar
          key={salary.id}
          project={project}
          salary={salary}
          visible={open === salary.id}
          onDone={onDone}
        />
      ))}
      <SalarySideBar
        project={project}
        salary={null}
        visible={open === "new"}
        onDone={onDone}
      />
    </Fragment>
  );
}
