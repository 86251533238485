import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useState,
} from "react";

function useForm() {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  return { submitted, setSubmitted, loading, setLoading };
}

type FormContext = ReturnType<typeof useForm>;
export const FormContext = createContext<FormContext | null>(null);

type FieldsFormProps = PropsWithChildren<{
  onSubmit: () => unknown;
}>;

export default function FieldsForm(props: FieldsFormProps) {
  const { onSubmit } = props;

  const form = useForm();

  const onFormSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      form.setSubmitted(true);
      form.setLoading(true);
      try {
        return await onSubmit();
      } finally {
        form.setLoading(false);
      }
    },
    [onSubmit, form],
  );

  return (
    <FormContext.Provider value={form}>
      <form onSubmit={(e) => void onFormSubmit(e)}>{props.children}</form>
    </FormContext.Provider>
  );
}
