import {
  Infer,
  array,
  boolean,
  enums,
  nullable,
  number,
  object,
  partial,
  string,
} from "superstruct";
import {
  PaymentFragmentSchema,
  PaymentFragmentValues,
} from "../utilities/enums/PaymentFragment";
import { InstructorPaymentStatusSchema } from "../utilities/enums/InstructorPaymentStatus";
import { isoDateSchema } from "../utilities/models/Date";
import Amounts from "../utilities/Amounts";
import { ProjectReferenceSchema } from "./Project";
import { OrganizationSummarySchema } from "./Organization";
import { SignableDocumentSchema, SignatureSchema } from "./Signature";
import { InstructorAchievementSchema } from "./Achievement";

// InstructorPaymentSummary

export const InstructorPaymentSummarySchema = object({
  id: string(),
  grantedAmount: number(),
  availableAmount: nullable(number()),
  requestedAmount: nullable(number()),
  instructedAmount: nullable(number()),
  validatedAmount: nullable(number()),
  certifiedAmount: nullable(number()),
  period: string(),
  fragment: PaymentFragmentSchema,
  status: InstructorPaymentStatusSchema,
  projects: array(ProjectReferenceSchema),
  convention: object({
    id: string(),
    reference: string(),
    date: string(),
  }),
  organizations: array(OrganizationSummarySchema),
});

export type InstructorPaymentSummary = Infer<
  typeof InstructorPaymentSummarySchema
>;

// InstructorPaymentAmounts

export const InstructorPaymentAmountsSchema = object({
  id: string(),
  period: string(),
  fragment: PaymentFragmentSchema,
  theoreticalAmount: number(),
  grantedAmount: number(),
  stockAmount: nullable(number()),
  availableAmount: nullable(number()),
  requestedAmount: nullable(number()),
  instructedAmount: nullable(number()),
  validatedAmount: nullable(number()),
  certifiedAmount: nullable(number()),
  stockableAmount: nullable(number()),
  maximalAdvance: nullable(number()),
  transferAmount: nullable(number()),
  remainingAdvance: nullable(number()),
  status: InstructorPaymentStatusSchema,
});

export type InstructorPaymentAmounts = Infer<
  typeof InstructorPaymentAmountsSchema
>;

// InstructorPaymentDetails

export const InstructorPaymentDetailsSchema = object({
  id: string(),
  theoreticalAmount: number(), // Montant théoriquement accordé (correpondant au 50/30/20%)
  grantedAmount: number(), // Montant accordé (potentiellement modifié, en cas d'avenant)
  stockAmount: nullable(number()), // Montant du report (disponible - versé des paiements précédents)
  availableAmount: nullable(number()), // Montant disponible (granted + stock)
  requestedAmount: nullable(number()), // Montant demandé par le bénéficiaire
  instructedAmount: nullable(number()), // Montant issu de l'instruction
  validatedAmount: nullable(number()), // Montant issu de la validation niv 1
  certifiedAmount: nullable(number()), // Montant issu de la validation niv 2
  stockableAmount: nullable(number()),
  maximalAdvance: nullable(number()),
  transferAmount: nullable(number()), // Montant du virement (certified - advance)
  remainingAdvance: nullable(number()),
  period: string(),
  fragment: PaymentFragmentSchema,
  committeeDate: nullable(string()),
  status: InstructorPaymentStatusSchema,
  achievements: array(InstructorAchievementSchema),
  hasAmendments: boolean(),
  hasIban: boolean(),
  report: nullable(SignableDocumentSchema),
  instructionSignature: nullable(SignatureSchema),
  validationSignature: nullable(SignatureSchema),
  certificationSignature: nullable(SignatureSchema),
  submissionDate: nullable(isoDateSchema),
  cancelReason: nullable(string()),
});

export type InstructorPaymentDetails = Infer<
  typeof InstructorPaymentDetailsSchema
>;

// InstructorPaymentCreation

export const InstructorPaymentCreationSchema = object({
  grantedAmount: number(),
  period: string(),
  fragment: enums(PaymentFragmentValues),
});
export type InstructorPaymentCreation = Infer<
  typeof InstructorPaymentCreationSchema
>;

// InstructorPaymentUpdate

export const InstructorPaymentUpdateSchema = partial(
  object({
    committeeDate: nullable(string()),
    maximalAdvance: nullable(number()),
    report: nullable(array(string())),
  }),
);
export type InstructorPaymentUpdate = Infer<
  typeof InstructorPaymentUpdateSchema
>;

// InstructorPaymentValidation

export const InstructorPaymentValidationSchema = object({
  by: enums(["instructor", "controller", "financier"]),
  achievements: array(
    object({ id: string(), amount: number(), notes: nullable(string()) }),
  ),
  contentHash: string(),
});
export type InstructorPaymentValidation = Infer<
  typeof InstructorPaymentValidationSchema
>;

export function getMostRevealantAmount(
  payment: Pick<
    InstructorPaymentAmounts,
    | "grantedAmount"
    | "availableAmount"
    | "requestedAmount"
    | "instructedAmount"
    | "validatedAmount"
    | "certifiedAmount"
  >,
) {
  let label: string;
  let info: string;
  let amount: number;

  if (payment.certifiedAmount) {
    label = Amounts.certified.label;
    info = Amounts.certified.info;
    amount = payment.certifiedAmount;
  } else if (payment.validatedAmount) {
    label = Amounts.validated.label;
    info = Amounts.validated.info;
    amount = payment.validatedAmount;
  } else if (payment.instructedAmount) {
    label = Amounts.instructed.label;
    info = Amounts.instructed.info;
    amount = payment.instructedAmount;
  } else if (payment.requestedAmount) {
    label = Amounts.requested.label;
    info = Amounts.requested.info;
    amount = payment.requestedAmount;
  } else if (payment.availableAmount) {
    label = Amounts.available.label;
    info = Amounts.available.info;
    amount = payment.availableAmount;
  } else {
    label = Amounts.granted.label;
    info = Amounts.granted.info;
    amount = payment.grantedAmount;
  }
  return { label, info, amount };
}
