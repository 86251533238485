import { css } from "@emotion/react";
import { Fragment, useState } from "react";
import Button from "@hpo/client/components/Button";
import TextField from "@hpo/client/utilities/fields/TextField";
import FieldsForm from "@hpo/client/utilities/fields/FieldsForm";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import MessageException from "@hpo/client/utilities/errors/MessageException";
import ErrorToast from "@hpo/client/utilities/ErrorToast";

type ChatTextInputProps = {
  onSend: (s: string) => unknown;
};

export default function ChatTextInput({ onSend }: ChatTextInputProps) {
  const [newMessage, setNewMessage] = useState<string | null>(null);

  const containerCss = css({
    display: "flex",
    gap: 10,
  });

  const fieldCss = css({
    flexGrow: 1,
  });

  const buttonCss = css({
    alignSelf: "flex-end",
  });

  const [onSubmit, submission] = useSubmitCallback(async () => {
    if (!newMessage) throw new MessageException("Votre message est vide", null);
    await onSend(newMessage);
    setNewMessage(null);
  }, [newMessage, onSend]);

  return (
    <Fragment>
      <ErrorToast error={submission.error} />
      <FieldsForm onSubmit={onSubmit}>
        <div css={containerCss}>
          <div css={fieldCss}>
            <TextField value={newMessage} onChange={setNewMessage} />
          </div>
          <div css={buttonCss}>
            <Button icon={{ name: "send" }} style="discreet" submit />
          </div>
        </div>
      </FieldsForm>
    </Fragment>
  );
}
