import { Infer, array, object, string } from "superstruct";
import { AnalysisSchema } from "./Analysis";

// Attachment

export const AttachmentBeneficiarySchema = object({
  id: string(),
  key: string(),
  files: array(string()),
});
export type AttachmentBeneficiary = Infer<typeof AttachmentBeneficiarySchema>;

export const AttachmentInstructorSchema = object({
  id: string(),
  key: string(),
  files: array(string()),
  analysis: AnalysisSchema,
});
export type AttachmentInstructor = Infer<typeof AttachmentInstructorSchema>;
