import { enums } from "superstruct";

enum MarkerType {
  Goal = "goal",
  Actual = "actual",
}

export const MarkerTypeValues = [MarkerType.Goal, MarkerType.Actual] as const;

export const MarkerTypeLabels: Record<MarkerType, string> = {
  [MarkerType.Goal]: "Objectif",
  [MarkerType.Actual]: "Réalisé",
};

export const MarkerTypeSchema = enums(MarkerTypeValues);

export default MarkerType;
