import { Fragment, useEffect } from "react";
import { useServerSdk } from "../RootNavigator/services/ServerSdk";
import UsersView from "./UsersView";
import OrganizationsView from "./OrganizationsView";
import ProgramsView from "./ProgramsView";
import TagsScreen from "./TagsScreen";
import useData from "@hpo/client/utilities/useData";
import Await from "@hpo/client/utilities/Await";
import ContentLayout from "@hpo/client/components/layout/ContentLayout";
import Tabs from "@hpo/client/utilities/Tabs";

export default function UsersAndBeneficiaryScreen() {
  const server = useServerSdk();
  const [users, usersData] = useData(async () => server.getUsers(), []);
  const [organizations, organizationsData] = useData(
    async () => server.getOrganizations(),
    [],
  );

  const reload = () => {
    usersData.reloadSilent();
    organizationsData.reloadSilent();
  };

  return (
    <Fragment>
      <Await promise={organizations} hideReload>
        {(organizations) => (
          <Await promise={users} hideReload>
            {(users) => (
              <ContentLayout title="Gestion de la plateforme">
                {/* @todo REMOVE */}
                <Temp />
                <Tabs showSingleTab>
                  <Tabs.Tab id="users" title="Utilisateurs">
                    <UsersView
                      users={users}
                      organizations={organizations}
                      onReload={reload}
                    />
                  </Tabs.Tab>
                  <Tabs.Tab id="organizations" title="Bénéficiaires">
                    <OrganizationsView
                      organizations={organizations}
                      onReload={reload}
                    />
                  </Tabs.Tab>
                  <Tabs.Tab id="programs" title="Appels à projet">
                    <ProgramsView />
                  </Tabs.Tab>
                  <Tabs.Tab id="tags" title="Etiquettes d'indicateurs">
                    <TagsScreen />
                  </Tabs.Tab>
                </Tabs>
              </ContentLayout>
            )}
          </Await>
        )}
      </Await>
    </Fragment>
  );
}

function Temp() {
  const server = useServerSdk();

  useEffect(() => {
    async function getWrongAttachments() {
      return (await server.getWrongAttachments()).data;
    }

    getWrongAttachments()
      .then((results) => {
        console.log("Done : ", results);
      })
      .catch((error) => {
        console.error("Error : ", error);
      });
  }, []);

  return <></>;
}
