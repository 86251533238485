import { boolean, Infer, nullable, number, object, string } from "superstruct";
import { sortBy } from "lodash";
import { MarkerTypeSchema } from "../utilities/enums/MarkerType";

// Marker

export const MarkerSchema = object({
  type: MarkerTypeSchema,
  period: string(),
  value: number(),
  createdAt: string(),
});

export type Marker = Infer<typeof MarkerSchema>;

// MarkerPayload

export const MarkerPayloadSchema = object({
  type: MarkerTypeSchema,
  period: string(),
  value: number(),
});

export type MarkerPayload = Infer<typeof MarkerPayloadSchema>;

// MarkerRequirement

export const MarkerRequirementSchema = object({
  indicator: string(),
  type: MarkerTypeSchema,
  value: nullable(number()),
  period: string(),
  editable: boolean(),
});
export type MarkerRequirement = Infer<typeof MarkerRequirementSchema>;

// FullfilledMarker

export const FullfilledMarkerSchema = object({
  indicator: string(),
  type: MarkerTypeSchema,
  value: number(),
  period: string(),
});
export type FullfilledMarker = Infer<typeof FullfilledMarkerSchema>;

export function getMarker(
  markers: Array<Marker>,
  period: string,
): Marker | null {
  const pool = getMarkers(markers, period);
  return pool[0] || null;
}

export function getMarkers(
  markers: Array<Marker>,
  period: string,
): Array<Marker> {
  const filtered = markers.filter((m) => m.period === period);
  const sorted = sortBy(filtered, (m) => new Date(m.createdAt).valueOf() * -1);
  return sorted;
}
