import { useState } from "react";
import { assert } from "superstruct";
import { useServerSdk } from "../../RootNavigator/services/ServerSdk";
import Button from "@hpo/client/components/Button";
import SideBar from "@hpo/client/components/SideBar";
import TextTitleMinor from "@hpo/client/components/text/TextTitleMinor";
import {
  Organization,
  OrganizationCreation,
} from "@hpo/client/models/Organization";
import ErrorToast from "@hpo/client/utilities/ErrorToast";
import Spacer from "@hpo/client/utilities/Spacer";
import MessageException from "@hpo/client/utilities/errors/MessageException";
import TextField from "@hpo/client/utilities/fields/TextField";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import Divider from "@hpo/client/components/Divider";
import Text from "@hpo/client/components/text/Text";
import Toasting from "@hpo/client/components/Toasting";
import { legalIdSchema } from "@hpo/client/utilities/models/LegalId";

type OrganizationSideBarProps = {
  organization: Organization | null;
  visible: boolean;
  onDone: (newUser: string | null) => unknown;
};

/** @todo : /** @todo : voir navigators/MyProjectsNavigator/MyProjectScreen/MyProjectActions/ActionSideBar.tsx */
export default function OrganizationSideBar(props: OrganizationSideBarProps) {
  const { organization, visible, onDone } = props;
  const server = useServerSdk();

  const [businessName, setBusinessName] = useState<string | null>(
    organization ? organization.businessName : null,
  );

  const [type, setType] = useState<string | null>(
    organization ? organization.type : null,
  );

  const [legalRepresentativeName, setLegalRepresentativeName] = useState<
    string | null
  >(organization ? organization.legalRepresentativeName : null);

  const [legalRepresentativePosition, setLegalRepresentativePosition] =
    useState<string | null>(
      organization ? organization.legalRepresentativePosition : null,
    );

  const [legalId, setLegalId] = useState<string | null>(
    organization ? organization.legalId : null,
  );

  const [street, setStreet] = useState<string | null>(
    organization ? organization.address.street : null,
  );

  const [postalCode, setPostalCode] = useState<string | null>(
    organization ? organization.address.postalCode : null,
  );

  const [town, setTown] = useState<string | null>(
    organization ? organization.address.town : null,
  );

  const checkLegalId = (value: string | null) => {
    try {
      assert(legalId, legalIdSchema);
      return null;
    } catch (e) {
      return "Numéro de SIRET invalide";
    }
  };

  const [saveOrganization, savingStatus] = useSubmitCallback(async () => {
    if (
      !businessName ||
      !type ||
      !legalId ||
      !legalRepresentativeName ||
      !legalRepresentativePosition ||
      !street ||
      !postalCode ||
      !town
    ) {
      throw new MessageException("Tous les champs sont obligatoires", null);
    }

    if (checkLegalId(legalId)) {
      throw new MessageException("Le numéro de SIRET est incorrect", null);
    }

    const newOrga: OrganizationCreation = {
      businessName,
      type,
      legalId,
      legalRepresentativeName,
      legalRepresentativePosition,
      address: {
        street,
        postalCode,
        town,
      },
    };

    if (organization) {
      await server.updateOrganization(organization.id, newOrga);
      Toasting.success("Succès", "Le bénéficiaire a bien été modifié.");
      onDone(null);
    } else {
      const id = await server.createOrganization(newOrga);

      Toasting.success("Succès", "Le bénéficiaire a bien été créé.");
      onDone(id);
    }
  }, [
    businessName,
    type,
    legalId,
    legalRepresentativeName,
    legalRepresentativePosition,
    street,
    postalCode,
    town,
    onDone,
  ]);

  return (
    <SideBar
      visible={visible}
      onHide={() => onDone(null)}
      header={
        <TextTitleMinor>
          {organization ? organization.businessName : "Nouveau bénéficiaire"}
        </TextTitleMinor>
      }
    >
      <ErrorToast error={savingStatus.error} />
      <TextField
        required
        id="businessName"
        label="Raison sociale"
        value={businessName}
        onChange={setBusinessName}
      />
      <Spacer />
      <TextField
        required
        id="type"
        label="Statut juridique"
        value={type}
        onChange={setType}
      />
      <Spacer />
      <TextField
        required
        id="legalId"
        label="Numéro de SIRET"
        value={legalId}
        onChange={setLegalId}
        errorMessage={checkLegalId}
      />
      <Spacer />
      <TextField
        required
        id="legalRepresentativeName"
        label="Réprésentant légal"
        value={legalRepresentativeName}
        onChange={setLegalRepresentativeName}
      />
      <Spacer />
      <TextField
        required
        id="legalRepresentativePosition"
        label="Fonction du réprésentant légal"
        value={legalRepresentativePosition}
        onChange={setLegalRepresentativePosition}
      />
      <Spacer />
      <Divider />
      <Spacer />
      <Text style="subtitle">Adresse</Text>
      <Spacer />
      <TextField
        required
        id="street"
        label="Rue"
        value={street}
        onChange={setStreet}
      />
      <Spacer />
      <TextField
        required
        id="postalCode"
        label="Code postal"
        value={postalCode}
        onChange={setPostalCode}
      />
      <Spacer />
      <TextField
        required
        id="town"
        label="Ville"
        value={town}
        onChange={setTown}
      />
      <Spacer scale={2} />
      <Button
        onClick={saveOrganization}
        label="Valider"
        loading={savingStatus.running}
      />
    </SideBar>
  );
}
