import { enums } from "superstruct";

enum AuthorRole {
  Beneficiary = "beneficiary",
  Instructor = "instructor",
}

export const AuthorRoleSchema = enums(Object.values(AuthorRole));

export default AuthorRole;
