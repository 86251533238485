import { Infer, nullable, object, string } from "superstruct";

export const TagSchema = object({
  id: string(),
  label: string(),
  description: nullable(string()),
});
export type Tag = Infer<typeof TagSchema>;

export const TagCreationSchema = object({
  label: string(),
  description: nullable(string()),
});
export type TagCreation = Infer<typeof TagCreationSchema>;
