import { css } from "@emotion/react";
import { rgba } from "polished";

type DividerProps = {
  grow?: boolean;
  color?: string;
  vertical?: boolean;
};

export default function Divider(props: DividerProps) {
  const { color = rgba("black", 0.1), vertical = false } = props;
  const hrCss = css({
    padding: 0,
    margin: 0,
    border: "none",
    background: color,
    [vertical ? "width" : "height"]: 1,
    flexGrow: props.grow ? 1 : 0,
  });
  return <hr css={hrCss} />;
}
