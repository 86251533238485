import { Route, Switch } from "wouter";
import { useAssistance } from "../RootNavigator/services/Assistance";
import MyProjectScreen from "./MyProjectScreen";
import MyProjectsScreen from "./MyProjectsScreen";
import NewProjectScreen from "./NewProjectScreen";
import { myProjectRoute, newProjectRoute } from "@hpo/client/utilities/routes";

export default function MyProjectsNavigator() {
  const assistance = useAssistance();
  assistance.useVisibilityDecision(true);
  assistance.useChatWithInstructor();

  return (
    <Switch>
      <Route path={"/"} component={MyProjectsScreen} />
      <Route path={newProjectRoute.getPath()} component={NewProjectScreen} />
      <Route path={myProjectRoute.getPath()} component={MyProjectScreen} />
    </Switch>
  );
}
