import { trimmed, Struct, coerce, define, string } from "superstruct";
import { PhoneNumberFormat, PhoneNumberUtil } from "google-libphonenumber";
import ProgramKey from "../enums/ProgramKey";
import { ProjectInstructor } from "../../models/Project";
import ProjectStatus from "../enums/ProjectStatus";
import Programs from "../Programs";

export function getProjectLabel(
  project: Pick<ProjectInstructor, "type" | "label">,
): string {
  return project.label || Programs[project.type].label;
}

export function getProjectSublabel(
  project: Pick<ProjectInstructor, "type" | "label">,
): string {
  return project.label;
}

export function canProjectHaveInquiry(
  project: Pick<ProjectInstructor, "status">,
): boolean {
  return project.status === ProjectStatus.UnderInstruction;
}

export function geProjectTypeLabel(projectType: ProgramKey): string {
  return Programs[projectType].label;
}

// Phone

const phoneSchema = define<string>("Phone", (v: unknown): v is string => {
  if (typeof v !== "string") return false;
  const phoneUtil = PhoneNumberUtil.getInstance();
  if (phoneUtil.isValidNumberForRegion(phoneUtil.parse(v, "RE"), "RE"))
    return true;
  if (phoneUtil.isValidNumberForRegion(phoneUtil.parse(v, "FR"), "FR"))
    return true;
  return false;
});

export function toE164<T, S>(struct: Struct<T, S>): Struct<T, S> {
  return coerce(struct, string(), (x) => {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const parsedInRe = phoneUtil.parse(x, "RE");
    const parsedInFr = phoneUtil.parse(x, "FR");
    if (phoneUtil.isValidNumberForRegion(parsedInRe, "RE")) {
      return phoneUtil.format(parsedInRe, PhoneNumberFormat.E164);
    } else if (phoneUtil.isValidNumberForRegion(parsedInFr, "FR")) {
      return phoneUtil.format(parsedInFr, PhoneNumberFormat.E164);
    } else {
      throw new Error("Cannot coerce phone number");
    }
  });
}

export const phoneCoercer = toE164(trimmed(phoneSchema));
