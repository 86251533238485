import { Fragment, useMemo, useState } from "react";

import { MyProjectBaseProps } from "..";
import ProductSideBar from "./ProductSideBar";
import DataTable, { DataTableColumn } from "@hpo/client/components/DataTable";
import { ProductBeneficiary } from "@hpo/client/models/Expense";
import Button from "@hpo/client/components/Button";
import Spacer from "@hpo/client/utilities/Spacer";
import Units from "@hpo/client/utilities/Units";
import PageHeader from "@hpo/client/utilities/PageHeader";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import Dialog from "@hpo/client/components/Dialog";
import { useServerSdk } from "@hpo/client/navigators/RootNavigator/services/ServerSdk";
import Toasting from "@hpo/client/components/Toasting";
import NumberField from "@hpo/client/utilities/fields/NumberField";
import { distpatchExpenses } from "@hpo/client/utilities/helpers/ExpenseHelper";

type MyProjectProductsProps = MyProjectBaseProps;

export default function MyProjectProducts(props: MyProjectProductsProps) {
  const { project, onRefresh } = props;

  const server = useServerSdk();

  const products = useMemo(
    () => distpatchExpenses(project.expenses).product,
    [project.expenses],
  );

  const [open, setOpen] = useState<string | "new" | null>(null);

  const columns: Array<DataTableColumn<ProductBeneficiary>> = [
    {
      field: "label",
      header: "Libellé",
    },
    {
      field: "provider",
      header: "Fournisseur",
    },
    {
      field: "quotationReference",
      header: "N° de devis",
    },
    {
      field: "quotationDate",
      header: "Date du devis",
    },
    {
      field: "quotationDetail",
      header: "Détail du devis",
    },
    {
      field: "quantity",
      header: "Quantité",
    },
    {
      header: "Coût annuel",
      body: (product: ProductBeneficiary) => {
        return Units.euro.display(product.annualCost);
      },
    },
  ];

  const onDone = () => {
    setOpen(null);
    onRefresh();
  };

  const [deleteProduct, deletingStatus] = useSubmitCallback(
    async (product: ProductBeneficiary) => {
      const confirm = await Dialog.confirm({
        message: "Ce devis produit sera supprimé ce manière définitive",
      });

      if (!confirm) return;

      await server.deleteExpense(project.id, product.id);

      Toasting.success("Succès", "Devis produit supprimé.");

      onRefresh();
    },
    [],
  );

  const totalAnnualCosts = useMemo(() => {
    return products.reduce((acc, product) => acc + product.annualCost, 0);
  }, [products]);

  return (
    <Fragment>
      <PageHeader
        title="Récapitulatif des devis de produits"
        titleStyle="subtitle"
        right={
          <Button icon="plus" label="Ajouter" onClick={() => setOpen("new")} />
        }
      />
      <Spacer />
      <DataTable
        columns={columns}
        value={products}
        actions={{
          onEditClick: (product: ProductBeneficiary) => setOpen(product.id),
          onDeleteClick: deleteProduct,
          deleteLoading: deletingStatus.running,
          editDisabled: deletingStatus.running,
        }}
      />
      {products.map((product) => (
        <ProductSideBar
          key={product.id}
          project={project}
          product={product}
          visible={open === product.id}
          onDone={onDone}
        />
      ))}
      <ProductSideBar
        project={project}
        product={null}
        visible={open === "new"}
        onDone={onDone}
      />
      <Spacer />
      <NumberField
        label="Total des coûts annuels"
        value={totalAnnualCosts}
        readonly
        unit="euro"
      />
    </Fragment>
  );
}
