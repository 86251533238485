import Button from "@hpo/client/components/Button";

type ChatBubbleProps = {
  label: string;
  selected: boolean;
  onClick: () => void;
};

export default function ChatBubble(props: ChatBubbleProps) {
  const { label, selected, onClick } = props;
  return (
    <Button
      icon="message"
      label={label}
      onClick={onClick}
      style={selected ? "normal" : "discreet"}
    />
  );
}
