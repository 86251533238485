import { HelpIcon } from "../../RootNavigator/assistance/Help/HelpIcon";
import Table from "./Table";
import { getProjectLabel } from "@hpo/client/utilities/helpers/ProjectHelper";
import { InstructorPaymentDetails } from "@hpo/client/models/InstructorPayment";
import Amounts from "@hpo/client/utilities/Amounts";
import Units from "@hpo/client/utilities/Units";

type PaymentTableProps = {
  payment: InstructorPaymentDetails;
};

export default function PaymentTable(props: PaymentTableProps) {
  const { payment } = props;
  const achivements =
    payment.achievements.length > 1 ? payment.achievements : [];

  return (
    <Table>
      <thead>
        <tr>
          <th></th>
          {achivements.map((a) => (
            <th key={a.id}>{getProjectLabel(a.funding.project)}</th>
          ))}
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            {Amounts.granted.label}{" "}
            <HelpIcon title={Amounts.granted.label}>
              {Amounts.granted.info}
            </HelpIcon>
          </td>
          {achivements.map((a) => (
            <td key={a.id}>{Units.euro.display(a.grantedAmount)}</td>
          ))}
          <td>{Units.euro.display(payment.grantedAmount)}</td>
        </tr>
        <tr>
          <td>
            {Amounts.stock.label}{" "}
            <HelpIcon title={Amounts.stock.label}>
              {Amounts.stock.info}
            </HelpIcon>
          </td>
          {achivements.map((a) => (
            <td key={a.id}>{Units.euro.display(a.stockAmount)}</td>
          ))}
          <td>{Units.euro.display(payment.stockAmount)}</td>
        </tr>
        <tr className="total">
          <th>
            {Amounts.available.label}{" "}
            <HelpIcon title={Amounts.available.label}>
              {Amounts.available.info}
            </HelpIcon>
          </th>
          {achivements.map((a) => (
            <th key={a.id}>{Units.euro.display(a.availableAmount)}</th>
          ))}
          <th>{Units.euro.display(payment.availableAmount)}</th>
        </tr>
        <tr className="divider">
          <td></td>
          {achivements.map((a) => (
            <td key={a.id}></td>
          ))}
          <td></td>
        </tr>
        <tr>
          <td>
            {Amounts.requested.label}{" "}
            <HelpIcon title={Amounts.requested.label}>
              {Amounts.requested.info}
            </HelpIcon>
          </td>
          {achivements.map((a) => (
            <td key={a.id}>{Units.euro.display(a.requestedAmount)}</td>
          ))}
          <td>{Units.euro.display(payment.requestedAmount)}</td>
        </tr>
        <tr>
          <td>
            {Amounts.instructed.label}{" "}
            <HelpIcon title={Amounts.instructed.label}>
              {Amounts.instructed.info}
            </HelpIcon>
          </td>
          {achivements.map((a) => (
            <td key={a.id}>{Units.euro.display(a.instructedAmount)}</td>
          ))}
          <td>{Units.euro.display(payment.instructedAmount)}</td>
        </tr>
        <tr>
          <td>
            {Amounts.validated.label}{" "}
            <HelpIcon title={Amounts.validated.label}>
              {Amounts.validated.info}
            </HelpIcon>
          </td>
          {achivements.map((a) => (
            <td key={a.id}>{Units.euro.display(a.validatedAmount)}</td>
          ))}
          <td>{Units.euro.display(payment.validatedAmount)}</td>
        </tr>
        <tr className="total">
          <th>
            {Amounts.certified.label}{" "}
            <HelpIcon title={Amounts.certified.label}>
              {Amounts.certified.info}
            </HelpIcon>
          </th>
          {achivements.map((a) => (
            <th key={a.id}>{Units.euro.display(a.certifiedAmount)}</th>
          ))}
          <th>{Units.euro.display(payment.certifiedAmount)}</th>
        </tr>
        {payment.stockableAmount ? (
          <tr>
            <td>
              {Amounts.stockable.label}{" "}
              <HelpIcon title={Amounts.stockable.label}>
                {Amounts.stockable.info}
              </HelpIcon>
            </td>
            {achivements.map((a) => (
              <td key={a.id}>{Units.euro.display(a.stockableAmount)}</td>
            ))}
            <td>{Units.euro.display(payment.stockableAmount)}</td>
          </tr>
        ) : null}
        <tr className="divider">
          <td></td>
          {achivements.map((a) => (
            <td key={a.id}></td>
          ))}
          <td></td>
        </tr>
        {payment.maximalAdvance ? (
          <tr>
            <td>
              {Amounts.maximalAdvance.label}{" "}
              <HelpIcon title={Amounts.maximalAdvance.label}>
                {Amounts.maximalAdvance.info}
              </HelpIcon>
            </td>
            {achivements.map((a) => (
              <td key={a.id}>-</td>
            ))}
            <td>{Units.euro.display(payment.maximalAdvance || 0)}</td>
          </tr>
        ) : null}
        <tr className="total">
          <th>
            {Amounts.transfer.label}{" "}
            <HelpIcon title={Amounts.transfer.label}>
              {Amounts.transfer.info}
            </HelpIcon>
          </th>
          {achivements.map((a) => (
            <th key={a.id}>-</th>
          ))}
          <th>{Units.euro.display(payment.transferAmount)}</th>
        </tr>
        {payment.remainingAdvance ? (
          <tr>
            <td>
              {Amounts.remainingAdvance.label}{" "}
              <HelpIcon title={Amounts.remainingAdvance.label}>
                {Amounts.remainingAdvance.info}
              </HelpIcon>
            </td>
            {achivements.map((a) => (
              <td key={a.id}>-</td>
            ))}
            <td>{Units.euro.display(payment.remainingAdvance)}</td>
          </tr>
        ) : null}
      </tbody>
    </Table>
  );
}
