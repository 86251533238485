import { Infer, array, nullable, number, object, string } from "superstruct";
import PaymentFragment, {
  PaymentFragmentLabels,
  PaymentFragmentSchema,
} from "../utilities/enums/PaymentFragment";
import { BeneficiaryPaymentStatusSchema } from "../utilities/enums/BeneficiaryPaymentStatus";
import { ConventionDetailsSchema, ConventionSummarySchema } from "./Convention";
import { BeneficiaryAchievementSchema } from "./Achievement";

// BeneficiaryPaymentSummary

export const BeneficiaryPaymentSummarySchema = object({
  id: string(),
  grantedAmount: number(),
  period: string(),
  fragment: PaymentFragmentSchema,
  status: BeneficiaryPaymentStatusSchema,
});

export type BeneficiaryPaymentSummary = Infer<
  typeof BeneficiaryPaymentSummarySchema
>;

// BeneficiaryPayment

export const BeneficiaryPaymentDetailsSchema = object({
  id: string(),
  period: string(),
  fragment: PaymentFragmentSchema,
  grantedAmount: number(),
  stockAmount: number(),
  availableAmount: number(),
  status: BeneficiaryPaymentStatusSchema,
  maximalAdvance: nullable(number()),
  committeeDate: nullable(string()),
  convention: ConventionDetailsSchema,
  achievements: array(BeneficiaryAchievementSchema),
});

export type BeneficiaryPaymentDetails = Infer<
  typeof BeneficiaryPaymentDetailsSchema
>;

export function getPaymentLabel(p: {
  fragment: PaymentFragment;
  period: string;
}) {
  return `${PaymentFragmentLabels[p.fragment]} de ${p.period}`;
}

// BeneficiaryPaymentsByConvention

export const BeneficiaryPaymentsByConventionSchema = array(
  object({
    convention: ConventionSummarySchema,
    payments: array(BeneficiaryPaymentSummarySchema),
  }),
);

export type BeneficiaryPaymentsByConvention = Infer<
  typeof BeneficiaryPaymentsByConventionSchema
>;
