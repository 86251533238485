import { Infer, nullable, object, string } from "superstruct";
import { isoDateCoercer } from "../utilities/models/Date";

// Commitment

export const CommitmentBeneficiarySchema = object({
  id: string(),
  label: string(),
  acceptedAt: nullable(isoDateCoercer),
});

export type CommitmentBeneficiary = Infer<typeof CommitmentBeneficiarySchema>;

export const CommitmentInstructorSchema = object({
  id: string(),
  label: string(),
  acceptedAt: isoDateCoercer,
});

export type CommitmentInstructor = Infer<typeof CommitmentInstructorSchema>;

// CommitmentCreation

export const CommitmentCreationSchema = object({
  label: string(),
  acceptedAt: nullable(isoDateCoercer),
});

export type CommitmentCreation = Infer<typeof CommitmentCreationSchema>;
