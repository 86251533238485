import { Fragment, useMemo, useState } from "react";
import { useServerSdk } from "../../RootNavigator/services/ServerSdk";
import { MyProjectBaseProps } from ".";
import Intersperse from "@hpo/client/components/Intersperse";
import Spacer from "@hpo/client/utilities/Spacer";
import FilesField from "@hpo/client/utilities/fields/FilesField";
import { getAttachementDescriptor } from "@hpo/client/utilities/project/Attachments";
import { AttachmentBeneficiary } from "@hpo/client/models/Attachment";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import Toasting from "@hpo/client/components/Toasting";
import ErrorToast from "@hpo/client/utilities/ErrorToast";

type MyProjectAttachmentsProps = MyProjectBaseProps;

export default function MyProjectAttachments(props: MyProjectAttachmentsProps) {
  const { project } = props;

  return (
    <Fragment>
      <Intersperse between={() => <Spacer />}>
        {project.attachments.map((attachment) => (
          <AttachmentField
            key={attachment.id}
            attachment={attachment}
            {...props}
          />
        ))}
      </Intersperse>
    </Fragment>
  );
}

type AttachmentFieldProps = MyProjectBaseProps & {
  attachment: AttachmentBeneficiary;
};

function AttachmentField(props: AttachmentFieldProps) {
  const { attachment, project, onRefresh } = props;

  const server = useServerSdk();

  const [files, setFiles] = useState<Array<string> | null>(attachment.files);

  const attachmentDescriptor = useMemo(() => {
    return getAttachementDescriptor(attachment.key);
  }, [attachment.key]);

  const [updateAttachment, savingStatus] = useSubmitCallback(
    async (files: Array<string> | null) => {
      setFiles(files);
      await server.updateAttachment(project.id, attachment.key, files || []);
      Toasting.success("Succès", "Les fichiers ont été enregistrés.");
      onRefresh();
    },
    [attachment.files],
  );

  return (
    <Fragment>
      <ErrorToast error={savingStatus.error} />
      <FilesField
        key={attachment.key}
        value={files}
        label={attachmentDescriptor.label}
        viewerHeight={300}
        onChange={updateAttachment}
        loading={savingStatus.running}
      />
    </Fragment>
  );
}
