import { ErrorInfo, PropsWithChildren, useCallback } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useLogger } from "../logger";
import ErrorView from "./ErrorView";
import Exception from "./errors/Exception";

type CatchErrorProps = PropsWithChildren;

export default function CatchError(props: CatchErrorProps) {
  const logger = useLogger();

  const onError = useCallback(
    (err: unknown, info: ErrorInfo) => {
      logger.error(new RenderError({ cause: err, tags: { ...info } }));
    },
    [logger],
  );

  return (
    <ErrorBoundary
      fallbackRender={(props) => <ErrorView error={props.error} noLog />}
      onError={onError}
    >
      {props.children}
    </ErrorBoundary>
  );
}

class RenderError extends Exception {}
