import { css } from "@emotion/react";
import React from "react";
import T from "../components/text/Text";
import Spinner from "../components/Spinner";
import Theme from "./Theme";
import Spacer from "./Spacer";

type LoadingViewProps = {
  message?: string;
  grow?: boolean;
};

export default function LoadingView(props: LoadingViewProps) {
  const { message, grow } = props;

  const containerCss = css({
    flexGrow: grow ? 1 : 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: Theme.color,
  });

  return (
    <div css={containerCss}>
      <Spinner />
      <Spacer />
      <T center>{message}</T>
    </div>
  );
}
