import { css } from "@emotion/react";
import { ReactNode } from "react";
import T, { TextStyleName } from "../components/text/Text";
import Intersperse from "../components/Intersperse";
import Spacer from "./Spacer";

type PageHeaderProps = {
  title: string;
  titleStyle?: TextStyleName;
  subtitle?: string;
  right?: ReactNode;
  titleOneLine?: boolean;
};

export default function PageHeader(props: PageHeaderProps) {
  const {
    title,
    titleStyle = "title",
    subtitle,
    right,
    titleOneLine = true,
  } = props;
  const containerCss = css({
    display: "flex",
    alignItems: "center",
  });
  return (
    <div css={containerCss}>
      <Intersperse between={() => <Spacer horizontal />}>
        <div css={{ flex: 1, flexShrink: 1, minWidth: 0 }}>
          <T style={titleStyle} oneLine={titleOneLine}>
            {title}
          </T>
          {subtitle ? (
            <T style="body" opacity={0.5} oneLine>
              {subtitle}
            </T>
          ) : null}
        </div>
        {right}
      </Intersperse>
    </div>
  );
}
