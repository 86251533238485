import { cloneDeep } from "lodash";
import { Fragment, useState } from "react";
import { useServerSdk } from "../../RootNavigator/services/ServerSdk";
import { MyProjectBaseProps } from ".";
import SwitchField from "@hpo/client/utilities/fields/SwitchField";
import DataTable, { DataTableColumn } from "@hpo/client/components/DataTable";
import { CommitmentBeneficiary } from "@hpo/client/models/Commitment";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import ErrorToast from "@hpo/client/utilities/ErrorToast";
import Toasting from "@hpo/client/components/Toasting";

type MyProjectCommitmentsProps = MyProjectBaseProps;

export default function MyProjectCommitments(props: MyProjectCommitmentsProps) {
  const { project, onRefresh } = props;

  const server = useServerSdk();

  function mustBeOn(value: boolean) {
    if (value === false) return "Ce champ n'a pas été coché";
    return null;
  }

  const [commitments, setCommitments] = useState<Array<CommitmentBeneficiary>>(
    project.commitments,
  );

  const [saveCommitment, savingStatus] = useSubmitCallback(
    async (commitmentId: string, validated: boolean) => {
      await server.updateCommitment(project.id, commitmentId, validated);
      Toasting.success("Succès", "Engagement mis à jour");
      onRefresh();
    },
    [],
  );

  const setCommitmentsProp = (
    commitment: CommitmentBeneficiary,
    newValue: boolean,
  ) => {
    void saveCommitment(commitment.id, newValue);

    setCommitments((commitments) => {
      const newCommitments = cloneDeep(commitments);

      const index = newCommitments.findIndex((c) => c.id === commitment.id);

      newCommitments[index] = {
        ...newCommitments[index],
        acceptedAt: newValue ? new Date() : null,
      };

      return newCommitments;
    });
  };

  const columns: Array<DataTableColumn<CommitmentBeneficiary>> = [
    {
      field: "label",
      header: "Libellé",
    },
    {
      body: (commitment: CommitmentBeneficiary) => {
        return (
          <SwitchField
            value={!!commitment.acceptedAt}
            onChange={(value) => setCommitmentsProp(commitment, value)}
            errorMessage={mustBeOn}
          />
        );
      },
    },
  ];

  return (
    <Fragment>
      <ErrorToast error={savingStatus.error} />
      <DataTable columns={columns} value={commitments} />
    </Fragment>
  );
}
