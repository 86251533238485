import { css } from "@emotion/react";
import { rgba } from "polished";
import { Fragment, ReactNode, useMemo } from "react";
import { createPortal } from "react-dom";
import Spacer from "../utilities/Spacer";
import HeadlessFieldsContext from "../utilities/fields/components/HeadlessFieldsContext";
import Button from "./Button";

export type SideBarProps = {
  visible: boolean;
  onHide?: () => void;
  children: ReactNode;
  header?: ReactNode | (() => ReactNode);
  modal?: boolean;
  zIndex?: number;
};

export default function SideBar(props: SideBarProps) {
  const node = useMemo(() => {
    let el = document.getElementById("side-bar");
    if (!el) {
      el = document.createElement("div");
      el.id = "side-bar";
      document.body.appendChild(el);
    }
    return el;
  }, []);

  const { visible, children, header, onHide, zIndex = 1000 } = props;

  const modalCss = css({
    position: "fixed",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    background: rgba("white", 0.1),
    opacity: visible ? 1 : 0,
    transition: "all 300ms",
    zIndex: zIndex,
    pointerEvents: visible ? "auto" : "none",
    backdropFilter: `blur(2px)`,
  });

  const containerCss = css({
    position: "fixed",
    top: 0,
    right: visible ? 0 : "-33vw",
    opacity: visible ? 1 : 0,
    transition: "all 300ms",
    bottom: 0,
    zIndex: zIndex,
    width: "33vw",
    background: "white",
    boxShadow: `0px 0px 1vw ${rgba("black", 0.5)}`,
    overflow: "auto",
    color: "black",
  });

  const headerCss = css({
    padding: 20,
    display: "flex",
    justifyContent: "flex-end",
  });
  const contentCss = css({
    padding: 20,
    paddingTop: 0,
  });

  return createPortal(
    <HeadlessFieldsContext.Provider value={false}>
      <Fragment>
        {props.modal ? <div css={modalCss} /> : null}
        <div css={containerCss}>
          {onHide ? (
            <div css={headerCss}>
              <Button
                icon={{ name: "close", rotate: 90 }}
                style="discreet"
                onClick={onHide}
              />
            </div>
          ) : null}
          <div css={contentCss}>
            {header ? (typeof header === "function" ? header() : header) : null}
            <Spacer />
            {children}
          </div>
        </div>
      </Fragment>
    </HeadlessFieldsContext.Provider>,
    node,
  );
}
