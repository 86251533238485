import { useState } from "react";
import { useLocation } from "wouter";
import { useServerSdk } from "../RootNavigator/services/ServerSdk";
import { useWhoAmI } from "../RootNavigator/services/WhoAmI";
import Button from "@hpo/client/components/Button";
import Toasting from "@hpo/client/components/Toasting";
import ContentLayout from "@hpo/client/components/layout/ContentLayout";
import { OrganizationSummary } from "@hpo/client/models/Organization";
import { UserAccess } from "@hpo/client/models/User";
import ErrorToast from "@hpo/client/utilities/ErrorToast";
import Spacer from "@hpo/client/utilities/Spacer";
import MessageException from "@hpo/client/utilities/errors/MessageException";
import DropDownField from "@hpo/client/utilities/fields/DropDownField";
import FieldsForm from "@hpo/client/utilities/fields/FieldsForm";
import TextField from "@hpo/client/utilities/fields/TextField";
import { myProjectRoute } from "@hpo/client/utilities/routes";
import { useSubmitCallback } from "@hpo/client/utilities/useSubmitCallback";
import ErrorView from "@hpo/client/utilities/ErrorView";
import useData from "@hpo/client/utilities/useData";
import { ProgramAvailability } from "@hpo/client/models/Program";

export default function NewProjectScreen() {
  const whoami = useWhoAmI();
  const user = whoami.useUser();

  if (!user.roles.includes("benficiary"))
    return (
      <ErrorView
        error={
          new MessageException(
            "Vous devez être bénéficiaire pour créer un projet",
            null,
          )
        }
      />
    );

  if (!user.organization)
    return (
      <ErrorView
        error={
          new MessageException(
            "Vous devez créer un bénéficiaire pour créer un projet.",
            null,
          )
        }
      />
    );

  return <NewProjectScreenReady user={user} organization={user.organization} />;
}

type NewProjectScreenReadyProps = {
  user: UserAccess;
  organization: OrganizationSummary;
};

function NewProjectScreenReady(props: NewProjectScreenReadyProps) {
  const { user, organization } = props;

  const server = useServerSdk();
  const [, navigate] = useLocation();

  const [label, setLabel] = useState<string | null>(null);
  const [type, setType] = useState<ProgramAvailability | null>(null);

  const [onSubmit, submission] = useSubmitCallback(async () => {
    if (!label)
      throw new MessageException("Veuillez définir un libellé au projet", null);
    if (!type)
      throw new MessageException("Sélectionnez un type de projet", null);
    if (!organization)
      throw new MessageException("Sélectionnez un bénéficiaire", null);

    const project = await server.createMyProject({
      label,
      type: type.key,
      organization: organization.id,
      owner: user.id,
      contactMobilePhoneNumber: null,
      contactName: null,
      contactPhoneNumber: null,
      summary: null,
      keywords: null,
    });

    Toasting.success("Succès", "Projet créé avec succès !");

    navigate(myProjectRoute.getUrl({ projectId: project }), { replace: true });
  }, [label, type, organization]);

  const [acceptedProjectTypes] = useData(
    () => server.getVisibleProjectTypes(),
    [],
  );

  return (
    <ContentLayout title="Nouveau projet">
      <ErrorToast error={submission.error} />
      <FieldsForm onSubmit={onSubmit}>
        <TextField
          value={label}
          label="Libellé du projet"
          onChange={setLabel}
          maxLength={70}
          help="70 caractères maximum"
        />
        <Spacer />
        <DropDownField
          id="type"
          label="Appel à projets"
          value={type}
          options={acceptedProjectTypes}
          renderOption={(type) => ({ label: type.label })}
          keyExtractor={(d) => d.key}
          onChange={setType}
        />
        <Spacer />
        <DropDownField
          id="organization"
          label="Bénéficiaire"
          value={organization}
          renderOption={(organization) => ({
            label: organization.businessName,
          })}
          keyExtractor={(organization) => organization.id}
          options={[organization]}
          // onChange={setOrganization}
          // readonly={!user.roles.includes("instructor")}
          readonly
        />
        <Spacer />
        <TextField
          textArea
          value={user.firstName + " " + user.lastName}
          label="Référent du projet"
          readonly
        />
        <Spacer />
        <Button label="Créer le projet" submit loading={submission.running} />
        <Spacer />
      </FieldsForm>
    </ContentLayout>
  );
}
