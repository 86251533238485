import { enums } from "superstruct";
import Theme from "../Theme";

export enum ProjectStatus {
  Draft = "draft",
  UnderInstruction = "under-instruction",
  Done = "done",
}

export const ProjectStatusSchema = enums(Object.values(ProjectStatus));

export const ProjectStatusLabels: Record<ProjectStatus, string> = {
  [ProjectStatus.Draft]: "Brouillon",
  [ProjectStatus.UnderInstruction]: "En cours d'instruction",
  [ProjectStatus.Done]: "Instrcution terminée",
};

export const ProjectStatusColors: Record<ProjectStatus, string> = {
  [ProjectStatus.Draft]: Theme.error,
  [ProjectStatus.UnderInstruction]: Theme.warning,
  [ProjectStatus.Done]: Theme.color,
};

export default ProjectStatus;
