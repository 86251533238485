import { useParams, useSearch } from "wouter";
import { useBrowserLocation } from "wouter/use-browser-location";
import { useCallback, useMemo } from "react";
import Exception, { ExceptionParams } from "./errors/Exception";
import { MergeParams, Route } from "./routes";

/** @deprecated : Use useRouteParam instead */
export function useParam(name: string) {
  const params = useParams();
  if (!params[name]) throw new MissingParam(name, { cause: null });
  return params[name] as string;
}

export function useRouteParam<
  Params extends Record<string, string> | void = void,
  ParentsParams extends Record<string, string> | void = void,
>(
  route: Route<Params, ParentsParams>,
  name: keyof MergeParams<Params, ParentsParams> & string,
) {
  const params = useParams();
  if (!params[name]) throw new MissingParam(name, { cause: null });
  return params[name] as string;
}

export function useSearchParam(name: string) {
  const string = useSearch();
  const [, navigate] = useBrowserLocation();

  const value = useMemo(() => {
    const searchParams = new URLSearchParams(string);
    return searchParams.get(name) || null;
  }, [string, name]);

  const change = useCallback(
    (v: string | null) => {
      const url = new URL(window.location.toString());
      const searchParams = url.searchParams;
      if (v) searchParams.set(name, v);
      else searchParams.delete(name);
      navigate(url, { replace: true });
    },
    [name, navigate],
  );

  return [value, change] as const;
}

export class MissingParam extends Exception {
  constructor(
    readonly name: string,
    params: ExceptionParams,
  ) {
    super(params);
  }
}
