import {
  Infer,
  array,
  enums,
  nullable,
  number,
  object,
  optional,
  size,
  string,
} from "superstruct";
import { uniq } from "lodash";
import { AnnexTypeValues } from "../utilities/enums/AnnexType";
import Exception from "../utilities/errors/Exception";
import ActionType from "../utilities/enums/ActionType";
import { phoneCoercer } from "../utilities/helpers/ProjectHelper";
import { distpatchExpenses } from "../utilities/helpers/ExpenseHelper";
import { ProjectStatusSchema } from "../utilities/enums/ProjectStatus";
import getIndicatorTypesByProgramKey from "../utilities/getIndicatorTypesByProjectTypeKey";
import { ActionSchema } from "./Action";
import { AnalysisSchema } from "./Analysis";
import {
  AttachmentBeneficiarySchema,
  AttachmentInstructorSchema,
} from "./Attachment";
import {
  CommitmentBeneficiarySchema,
  CommitmentInstructorSchema,
} from "./Commitment";
import {
  ComplianceBeneficiarySchema,
  ComplianceInstructorSchema,
} from "./Compliance";
import {
  DescriptionBeneficiarySchema,
  DescriptionInstructorSchema,
} from "./Description";
import { ExpenseBeneficiarySchema, ExpenseInstructorSchema } from "./Expense";
import { OrganizationSchema } from "./Organization";
import { UserSchema } from "./User";
import { InvestigationSchema, NotationSchema } from "./Investigation";
import { ProposalSchema } from "./Proposal";
import { SignatureSchema } from "./Signature";
import {
  BeneficiaryIndicatorSchema,
  InstructorIndicatorSchema,
} from "./Indicator";
import ProgramKey, {
  ProgramKeySchema,
} from "@hpo/client/utilities/enums/ProgramKey";

// Annex

export const AnnexBeneficiarySchema = object({
  resource: string(),
  type: enums(AnnexTypeValues),
});

export type AnnexBeneficiary = Infer<typeof AnnexBeneficiarySchema>;

export const AnnexSchema = object({
  resource: string(),
  type: enums(AnnexTypeValues),
  analysis: AnalysisSchema,
});

export type Annex = Infer<typeof AnnexSchema>;

// Project
export const ProjectDraftBeneficiarySchema = object({
  id: string(),
  type: ProgramKeySchema,
  label: string(),
  owner: UserSchema,
  organization: OrganizationSchema,
  contactName: nullable(string()),
  contactPhoneNumber: nullable(phoneCoercer),
  contactMobilePhoneNumber: nullable(phoneCoercer),
  periods: array(string()),
  keywords: nullable(size(array(string()), 1, 5)),
  summary: nullable(string()),
  compliances: array(ComplianceBeneficiarySchema),
  commitments: array(CommitmentBeneficiarySchema),
  actions: array(ActionSchema),
  descriptions: array(DescriptionBeneficiarySchema),
  indicators: array(BeneficiaryIndicatorSchema),
  expenses: array(ExpenseBeneficiarySchema),
  attachments: array(AttachmentBeneficiarySchema),
  annexes: array(AnnexBeneficiarySchema),
});

export type ProjectDraftBeneficiary = Infer<
  typeof ProjectDraftBeneficiarySchema
>;

// A venir
// // Project
// export const ProjectBeneficiarySchema = object({
//   id: string(),
//   type: enums(Object.values(ProgramKey)),
//   label: nullable(string()),
//   owner: UserSchema,
//   organization: OrganizationSchema,
//   contactName: string(),
//   contactPhoneNumber: phoneCoercer,
//   contactMobilePhoneNumber: phoneCoercer,
//   keywords: size(array(string()), 1, 5),
//   summary: string(),
//   periods: array(string()),
//   compliances: array(ComplianceInstructorSchema),
//   commitments: array(CommitmentInstructorSchema),
//   actions: array(ActionSchema),
//   descriptions: array(DescriptionInstructorSchema),
//   indicators: array(IndicatorWithMakersAndAnalysisSchema),
//   expenses: array(ExpenseInstructorSchema),
//   proposals: array(ProposalSchema),
//   attachments: array(AttachmentInstructorSchema),
//   annexes: array(AnnexSchema),
// });

// export type ProjectBeneficiary = Infer<typeof ProjectBeneficiarySchema>;

// ProjectInstructor

export const ProjectInstructorSchema = object({
  id: string(),
  type: enums(Object.values(ProgramKey)),
  label: string(),
  owner: UserSchema,
  status: ProjectStatusSchema,
  organization: OrganizationSchema,
  contactName: string(),
  contactPhoneNumber: phoneCoercer,
  contactMobilePhoneNumber: phoneCoercer,
  keywords: size(array(string()), 1, 5),
  summary: string(),
  periods: array(string()),
  compliances: array(ComplianceInstructorSchema),
  commitments: array(CommitmentInstructorSchema),
  actions: array(ActionSchema),
  descriptions: array(DescriptionInstructorSchema),
  indicators: array(InstructorIndicatorSchema),
  expenses: array(ExpenseInstructorSchema),
  proposals: array(ProposalSchema),
  attachments: array(AttachmentInstructorSchema),
  annexes: array(AnnexSchema),
  investigation: nullable(InvestigationSchema),
  notations: array(NotationSchema),
  report: nullable(string()),
  instructorSignature: nullable(SignatureSchema),
});

export type ProjectInstructor = Infer<typeof ProjectInstructorSchema>;

// ProjectSummary

export const ProjectSummarySchema = object({
  id: string(),
  status: ProjectStatusSchema,
  type: ProgramKeySchema,
  organization: OrganizationSchema,
  amount: nullable(number()),
  label: string(),
});

export type ProjectSummary = Infer<typeof ProjectSummarySchema>;

// ProjectReference

export const ProjectReferenceSchema = object({
  id: string(),
  type: ProgramKeySchema,
  label: string(),
  periods: array(string()),
});

export type ProjectReference = Infer<typeof ProjectReferenceSchema>;

// ProjectCreation
export const ProjectCreationSchema = object({
  label: string(),
  type: ProgramKeySchema,
  owner: string(),
  organization: string(),
  contactName: nullable(string()),
  contactPhoneNumber: nullable(phoneCoercer),
  contactMobilePhoneNumber: nullable(phoneCoercer),
  keywords: nullable(size(array(string()), 0, 5)),
  summary: nullable(string()),
});

export type ProjectCreation = Infer<typeof ProjectCreationSchema>;

export const ProjectUpdateSchema = object({
  label: optional(string()),
  contactPhoneNumber: optional(nullable(phoneCoercer)),
  contactMobilePhoneNumber: optional(nullable(phoneCoercer)),
  keywords: optional(size(array(string()), 0, 5)),
  summary: optional(nullable(string())),
});

export type ProjectUpdate = Infer<typeof ProjectUpdateSchema>;

// BusinessRules

export function assertProjectIsValid(project: ProjectDraftBeneficiary) {
  assertMainProjectData(project);
  assertCommitments(project);
  assertCompliances(project);
  assertAttachments(project);
  assertDescriptions(project);
  assertIndicators(project);
  assertExpenses(project);
  assertActions(project, "communication");
  assertActions(project, "transfer");
}

const PRODUCTS_FORBIDDEN =
  "Les dépenses d'achat de fournitures sont interdites";
const SERVICES_FORBIDDEN = "Les dépenses de prestations sont interdites";
const SALARIES_FORBIDDEN = "Les dépenses de personnel sont interdites";

export function assertMainProjectData(project: ProjectDraftBeneficiary) {
  if (project.label === null) {
    throw new InvalidProjectError("Veuillez renseigner un titre au projet");
  }

  if (project.contactPhoneNumber === null) {
    throw new InvalidProjectError(
      "Veuillez renseigner un numéro de téléphone fixe",
    );
  }

  if (project.contactMobilePhoneNumber === null) {
    throw new InvalidProjectError(
      "Veuillez renseigner un numéro de téléphone mobile",
    );
  }

  if (!project.keywords) {
    throw new InvalidProjectError("Veuillez renseigner au moins un mot clé");
  }

  if (project.keywords.length > 5) {
    throw new InvalidProjectError("Veuillez renseigner au maximum 5 mots clés");
  }

  if (project.summary === null) {
    throw new InvalidProjectError("Veuillez renseigner un résumé du projet");
  }
}

export function assertExpenses(project: ProjectDraftBeneficiary) {
  const expenses = project.expenses;

  // At leat one expense
  if (expenses.length === 0) {
    throw new InvalidProjectError("Veuillez renseigner au moins une dépense");
  }

  const dispatched = distpatchExpenses(expenses);

  const productsCount = dispatched.product.length;
  const servicesCount = dispatched.service.length;
  const salariesCount = dispatched.salary.length;

  const haveProducts = project.annexes.find(
    (annex) => annex.type === "a2_product",
  );

  const haveServices = project.annexes.find(
    (annex) => annex.type === "a2_service",
  );

  const haveSalaries = project.annexes.find(
    (annex) => annex.type === "a2_salary",
  );

  if (!haveProducts && productsCount > 0)
    throw new InvalidProjectError(PRODUCTS_FORBIDDEN);

  if (!haveServices && servicesCount > 0)
    throw new InvalidProjectError(SERVICES_FORBIDDEN);

  if (!haveSalaries && salariesCount > 0)
    throw new InvalidProjectError(SALARIES_FORBIDDEN);
}

export function assertIndicators(project: ProjectDraftBeneficiary) {
  const requiredIndicators = getIndicatorTypesByProgramKey(project.type);

  const wantedIndicators = requiredIndicators.sort().join(" ");
  const filledIndicators = uniq(project.indicators.map((i) => i.type))
    .sort()
    .join(" ");

  if (wantedIndicators !== filledIndicators) {
    throw new InvalidProjectError(
      "Veuillez renseigner au moins un indicateur par type",
    );
  }

  project.indicators.forEach((indicator) => {
    if (indicator.goals.length === 0) {
      throw new InvalidProjectError(
        "Veuillez renseigner au moins un indicateur par type",
      );
    }
  });
}

export function assertActions(
  project: ProjectDraftBeneficiary,
  type: ActionType,
) {
  const actions = project.actions.filter((action) => action.type === type);

  const mustHaveCommunicationAction = !!project.annexes.find(
    (annex) => annex.type === "a4",
  );

  const mustHaveTransferAction = !!project.annexes.find(
    (annex) => annex.type === "a5",
  );

  if (
    type === "communication" &&
    mustHaveCommunicationAction &&
    actions.length === 0
  )
    throw new InvalidProjectError(
      "Veuillez renseigner au moins une action de communication",
    );

  if (
    type === "communication" &&
    !mustHaveCommunicationAction &&
    actions.length > 0
  )
    throw new InvalidProjectError(
      "Le projet ne doit pas contenir d'actions de communication",
    );

  if (type === "transfer" && mustHaveTransferAction && actions.length === 0) {
    throw new InvalidProjectError(
      "Veuillez renseigner au moins une action de transfert",
    );
  }

  if (type === "transfer" && !mustHaveTransferAction && actions.length > 0) {
    throw new InvalidProjectError(
      "Le projet ne doit pas contenir d'actions de transfert",
    );
  }
}

export function assertAttachments(project: ProjectDraftBeneficiary) {
  project.attachments.forEach((attachment) => {
    if (attachment.files.length === 0)
      throw new InvalidProjectError(
        "Veuillez saisir tous les fichiers demandés",
      );
  });
}

export function assertCommitments(project: ProjectDraftBeneficiary) {
  project.commitments.forEach((commitment) => {
    if (commitment.acceptedAt === null)
      throw new InvalidProjectError("Veuillez accepter tous les engagements");
  });
}

export function assertCompliances(project: ProjectDraftBeneficiary) {
  project.compliances.forEach((compliance) => {
    if (compliance.acceptedAt === null)
      throw new InvalidProjectError(
        "Veuillez accepter toutes les éligibilités",
      );
  });
}

export function assertDescriptions(project: ProjectDraftBeneficiary) {
  project.descriptions.forEach((description) => {
    if (description.value === null)
      throw new InvalidProjectError(
        "Veuillez remplir toutes les descriptions.",
      );
  });
}

export class InvalidProjectError extends Exception {
  constructor(message: string) {
    super({ message, tags: {}, cause: null });
  }
}
